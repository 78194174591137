import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { AlertColor, Button, Snackbar, TextField } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { NestedValue, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../graphql/mutations/auth/auth.mutations";
import Loader from "../loader/loader.component";
import { useDispatch } from "react-redux";
import { isHomeHeader } from "../../redux/actions";
import Alert from "../alert/alert.component";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");
  const [severity, setSeverity] = useState<AlertColor>("error");
  const navigate = useNavigate();

  const openAlert = (error: any) => {
    setError(error);
    setAlertOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<{
    digits: NestedValue<any>;
    email: NestedValue<any>;
    password: NestedValue<any>;
    rePassword: NestedValue<any>;
  }>({
    defaultValues: {
      digits: "",
      email: "",
      password: "",
      rePassword: "",
    },
  });

  const [resetPassword, { loading, error: resetPasswordError }] = useMutation(
    RESET_PASSWORD,
    {
      variables: {
        digits: getValues("digits"),
        email: getValues("email"),
        password: getValues("password"),
      },
      onCompleted: () => {
        setSeverity("success");
        openAlert("Password Successfully Updated!");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      },
    }
  );

  React.useEffect(() => {
    setSeverity("error");
    if (resetPasswordError?.message) {
      openAlert(resetPasswordError?.message);
    }
  }, [resetPasswordError]);

  React.useEffect(() => {
    register("email", {
      validate: (value) => value.length || "Required",
    });
    register("digits", {
      validate: (value) => value.length || "Required",
    });
    register("password", {
      validate: (value) => value.length || "Required",
    });
    register("rePassword", {
      validate: (value) => value.length || "Required",
    });
    register("rePassword", {
      validate: (value) =>
        value === getValues("password") || "Passwords does not match",
    });
  }, [register, getValues]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await resetPassword();
    } catch (err) {}
  });

  return (
    <>
      <Box
        sx={{
          height: "100%",
          padding: "25px 0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          alignContent: "center",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            maxHeight: 650,
            maxWidth: 340,
            width: "100%",
            padding: 5,
            boxShadow: "0 2px 25px -9px #333;",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <form
            onSubmit={onSubmit}
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                alignItems: "center",
                marginBottom: 25,
              }}
            >
              <div>
                <LockIcon />
              </div>
              <label
                style={{ fontWeight: 400, textAlign: "center", fontSize: 24 }}
              >
                Reset your password
              </label>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "CerebriSans-Light",
                  color: "#c1c1c1",
                  paddingTop: 10,
                }}
              >
                A confirmation code has been sent to your email or mobile phone. Please
                enter it below to change your password.
              </p>
            </div>
            <div style={{ flex: 1, width: "100%" }}>
              <TextField
                {...register("email")}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                error={!!errors?.email}
                helperText={!!errors?.email ? errors?.email?.message : ""}
                style={{ marginBottom: 15, width: "100%" }}
              />
              <TextField
                {...register("digits")}
                id="outlined-basic"
                label="Verification Code"
                variant="outlined"
                error={!!errors?.digits}
                helperText={!!errors?.digits ? errors?.digits?.message : ""}
                style={{ marginBottom: 15, width: "100%" }}
              />
              <TextField
                {...register("password")}
                id="outlined-basic"
                label="Password"
                type={"password"}
                variant="outlined"
                error={!!errors?.password}
                helperText={!!errors?.password ? errors?.password?.message : ""}
                style={{ marginBottom: 15, width: "100%" }}
              />
              <TextField
                {...register("rePassword")}
                id="outlined-basic"
                label="Repeat Password"
                variant="outlined"
                type={"password"}
                error={!!errors?.rePassword}
                helperText={
                  !!errors?.rePassword ? errors?.rePassword?.message : ""
                }
                style={{ marginBottom: 15, width: "100%" }}
              />
            </div>
            {loading ? (
              <Loader />
            ) : (
              <Button
                variant="contained"
                style={{ height: 36, background: "#28dab6", marginTop: 10 }}
                type={"submit"}
              >
                Change
              </Button>
            )}
          </form>
        </Card>
      </Box>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity={severity} sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResetPassword;
