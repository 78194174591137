/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const NOTIFY_DOC_UPLOADED = gql`
  mutation notifyDocumentUploaded(
    $documentUUID: String!
    $transactionUUID: String!
  ) {
    notifyDocumentUploaded(
      transactionUUID: $transactionUUID
      documentUUID: $documentUUID
    ) {
      uuid
      name
      status
      documentType {
        uuid
        type
      }
    }
  }
`;
