import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CONTACT_US } from "../../graphql/mutations/user/contact.mutation";
import HomeSectionDescription from "../home-section-description/home-section-description.component";
import { formContent } from "./contact.helper";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import { AlertColor, Snackbar } from "@mui/material";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import "./contact.scss";
import Alert from "../alert/alert.component";
import Loader from "../loader/loader.component";

const aboutDesc = {
  title: "Contact Us",
  desc: "For instant response you can use our Live Chat feature. You can also send us a message through the form, or call one of our customer service representatives.",
};

const INITIAL_DATA = {
  name: "",
  email: "",
  phone: "",
  message: "",
}

const Contact = () => {
  const [alertOpen, setAlertOpen] = React.useState({
    msg: '',
    isOpen: false,
    color: 'success'
  });
  const [captchaToken, setCaptchaToken] = useState(null);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [formToSubmit, setFormToSubmit] = useState<{ [key: string]: string }>(INITIAL_DATA);

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen({
      msg: alertOpen.msg,
      isOpen: false,
      color: alertOpen.color
    });
  };

  const verifyRecaptchaCallback = React.useCallback((token) => {
    setCaptchaToken(token)
  }, []);

  const [contactUs, { loading: contactUsLoader }] = useMutation(CONTACT_US, {
    variables: {
      recaptchaTokenResponse: captchaToken,
      name: formToSubmit.name,
      email: formToSubmit.email,
      phone: "+1" + formToSubmit.phone,
      message: formToSubmit.message,
    },
    onCompleted: (data) => {
      setAlertOpen({
        msg: 'Submitted successfully',
        isOpen: true,
        color: 'success'
      });
      setFormToSubmit(INITIAL_DATA)
      setRefreshReCaptcha(r => !r);
    },
  });

  const onSubmitForm = async () => {
    if (!isValidForm()) return;

    try {
      await contactUs();
    } catch (err: any) {
      setAlertOpen({
        msg: err.message,
        isOpen: true,
        color: 'error'
      });
      setRefreshReCaptcha(r => !r);
    }
  };

  const isValidForm = () => {
    return (
      formToSubmit.name.trim() !== "" &&
      formToSubmit.email.trim() !== "" &&
      formToSubmit.phone.trim() !== "" &&
      formToSubmit.message.trim() !== "" &&
      captchaToken !== null
    );
  };

  return (
    <>
      <div className="container">
        <HomeSectionDescription
          title={aboutDesc.title}
          description={aboutDesc.desc}
        />
        <div className="contact">
          <GoogleReCaptchaProvider reCaptchaKey={`${process.env.REACT_APP_RECAPTCHA}`}>
            <form>
              <h4>Send us a message</h4>
              {formContent.map((fc: any, i: number) => {
                return (
                  <div className="col-md-12 pad esignature-contact-name" key={i}>
                    <label className="text-center" style={{ marginTop: 15 }}>{fc.label}</label>
                    {fc.type !== 'textarea' ? <input
                      type={fc.type}
                      className="form-control"
                      name={fc.name}
                      value={formToSubmit[fc.value]}
                      placeholder={fc.placeholder}
                      onChange={(e: any) => {
                        setFormToSubmit({
                          ...formToSubmit,
                          [fc.name]: e.target.value,
                        });
                      }}
                    /> : <textarea
                      className="form-control"
                      name={fc.name}
                      value={formToSubmit[fc.value]}
                      placeholder={fc.placeholder}
                      rows={6}
                      cols={80}
                      style={{ resize: 'none' }}
                      onChange={(e: any) => {
                        setFormToSubmit({
                          ...formToSubmit,
                          [fc.name]: e.target.value,
                        });
                      }}
                    />}
                  </div>
                );
              })}
              <div className="captcha">
                <GoogleReCaptcha
                  onVerify={verifyRecaptchaCallback}
                  refreshReCaptcha={refreshReCaptcha}
                />
              </div>

              <div className="col-md-12 pad">
                <button
                  type="button"
                  className="btn btn-send esignature-contact-submit"
                  onClick={onSubmitForm}
                  disabled={!isValidForm()}
                >
                  {contactUsLoader ? <Loader size={30} /> : "Send"}
                </button>
              </div>
            </form>
          </GoogleReCaptchaProvider>
          <div className="contact-box">
            <div className="col-md-11 box">
              <h4>Contact information</h4>
              <div className="number" style={{ display: "none" }}>
                <a href="tel:1-844-782-6763">1-844-782-6763</a>
              </div>
              <div className="mail">
                <a href="mailto:info@esignatureguarantee.com">
                  info@esignatureguarantee.com
                </a>
              </div>
              <h4 className="address">Mailing address</h4>
              <div className="info">
                eSignature Guarantee
                <br />
                18 Lafayette Place
                <br />
                Woodmere, NY 11598
              </div>
              <div className="social">
                <ul>
                  <a href="https://www.facebook.com/people/ESignature-Guarantee/100013027321538">
                    <FacebookIcon color="action" />
                  </a>
                  <a href="https://twitter.com/eSignatureGroup">
                    <TwitterIcon color="action" />
                  </a>
                  <a href="https://www.linkedin.com/in/esignatureguarantee/">
                    <LinkedInIcon color="action" />
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={alertOpen.isOpen}
        autoHideDuration={3000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity={alertOpen.color as AlertColor} sx={{ width: "100%" }}>
          {alertOpen.msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Contact;
