export const certificateRegularValidation = (
  transaction: any,
  agentUUID: any
): boolean => {
  return getMedallionsLength(transaction) > 0 && !!agentUUID;
};

export const getMedallionsLength = (transaction: any): number => {
  if (!transaction || !transaction?.certificates) return 0;

  return transaction?.certificates?.reduce((acc: number, c: any) => {
    return acc + c.medallionsCount;
  }, 0);
};

export const extractMedallionsLength = (transaction: any) => {
  if (!transaction || !transaction?.certificates) return 0;

  return transaction?.certificates?.reduce((acc: any, certificate: any) => {
    acc += certificate?.medallionsCount;
    return acc;
  }, 0);
};

export interface TransferAgent { name: string; uuid: string }
