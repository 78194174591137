
import { gql } from "@apollo/client";

export const SET_ALLOW_RECEIVE_SMS = gql`
mutation setAllowReceiveSMS {
	setReceiveSmsMessages(allowReceiveSms: true) {
		uuid
		email
		allowReceiveSms
	}
}
`