import React from "react";
import { useDispatch } from "react-redux";
import { isHomeHeader } from "../../redux/actions";
import HomeSectionDescription from "../home-section-description/home-section-description.component";

import "./terms.scss";

const privacyDesc = {
  title: "Privacy Policy",
  desc: "",
};
const Privacy = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container">
        <div className="privacy">
          <HomeSectionDescription
            title={privacyDesc.title}
            description={privacyDesc.desc}
          ></HomeSectionDescription>
          <h4 className="first">eSignature Group Privacy Statement</h4>
          <p>
            At eSignature Group we place the highest importance on respecting
            and protecting the privacy of our users. Our most important asset is
            our relationship with you. We want you to feel comfortable and
            confident when using our Web site. Therefore, we would like to share
            with you the following principles that govern our information
            practices and other privacy aspects of our Web site. Throughout this
            policy, we refer to information that personally identifies you as
          </p>

          <h4 className="pad">We tell you how we use your information</h4>
          <p>
            When we ask you for information, we will tell you, --or it will be
            clear --what we need to know to fulfill your request. Because of the
            financial nature of our business, our Web site is not designed to
            appeal to children under the age of 18. We do not knowingly request
            or receive any information from children. We do not sell or rent
            your personal information to anyone.
            <br />
            <br />
            We may use your contact information to tell you about other products
            we think might interest you. We may need to communicate with you
            regarding the usability of our Web site or critical messages
            relating to our service or software.
          </p>

          <h4 className="pad">You can contact us</h4>
          <p>
            If you have privacy-related questions that are not addressed here,
            please contact the Privacy Team or write us at: Privacy Team,
            eSignature Group, LLC, 18 Lafayette Place, Woodmere, New York 11598.
          </p>

          <h4 className="pad">We protect your information</h4>
          <p>
            We work to protect the confidentiality and security of your personal
            information from loss, misuse or unauthorized alteration by using
            industry-recognized security safeguards such as firewalls, coupled
            with carefully developed security procedures to protect your
            information from loss, misuse or unauthorized alteration. Our
            employees are trained and required to safeguard your information
            and, using physical, electronic and procedural safeguards, we
            restrict access to personal information to those employees and
            agents for business purposes only. We use both internal and external
            resources to review our security procedures.
          </p>

          <h4 className="pad">
            Cookies, Web beacons and other Web technologies
          </h4>
          <p>
            Like many Web sites, we use technology, such as cookies, that allow
            us to make your visit to our Web site easier by recognizing you when
            you return and help to provide you with a customized experience.
            Cookies allow us to track overall site usage and determine areas
            users prefer. Most browsers accept and maintain cookies by default.
            Check the "Help" menu of your browser to learn how to change your
            cookie preference.
            <br />
            When we track activity on our Web site, we collect information such
            as your IP address, browser type and version, and pages you view. We
            also keep track of how you got to our site and any links you click
            on to leave our site. Once you leave our site, we do not track you.
            We use your Web site activity to assist us in offering you a
            personalized Web experience, assist you with technical support and
            to tailor our offerings to you.
            <br />
            <br />
            We may access and set cookies using Web beacons, also known as
            single-pixel GIFs which are invisible graphical images. These Web
            beacons tell us useful information regarding our site such as which
            pages users access. When we send you e-mails, we may include a
            single-pixel GIF to determine the number of people who open our
            e-mails. When you click on a link in an e-mail, we record this
            individual response to allow us to customize our offerings to you.
          </p>

          <h4 className="pad">Third Parties</h4>
          <p>
            Sometimes, we enter into contracts with third parties who assist us
            in servicing you. The contracts outline the appropriate use and
            handling of your information and prohibit third parties from using
            any of your personal information for purposes unrelated to the
            product or service for which they’ve been contracted. Vendors are
            required to maintain the confidentiality of the information we
            provide to them. We may disclose or report personal information in
            limited circumstances where we believe in good faith that disclosure
            is required under the law. For example, we may be required to
            disclose personal information to cooperate with regulators or law
            enforcement authorities, to comply with a legal process such as a
            court order, subpoena, search warrant, or a law enforcement request.
          </p>

          <h4 className="pad">eSignature Group Database Access</h4>
          <p className="last">
            Any content you provide to eSignature Group will not be publicly
            available unless you voluntarily choose to give permission to other
            users, or to the general public for that information.
            <br />
            <br />
            Your information is password protected. We recommend that you do not
            divulge your password to anyone. Remember to sign out of your
            eSignature Group account and close your browser window when you have
            finished your work. This is to prevent others from accessing your
            account information if you share a computer with someone else, or
            are using a computer in a public place, like a library or Internet
            café.
          </p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
