import React from "react";
import { LinearProgress } from "@mui/material";

const FilesLoader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        opacity: 1,
      }}
    >
      <div
        style={{
          background: "gray",
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          opacity: "0.5",
        }}
      ></div>
      <LinearProgress />
    </div>
  );
};

export default FilesLoader;
