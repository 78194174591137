import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Box, Snackbar, Typography } from "@mui/material";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GENERATE_UPDATE_PHONE_CODE } from "../../graphql/mutations/verification/generateUpdatePhoneCode.mutation";
import { UPDAE_PHONE } from "../../graphql/mutations/verification/update-phone.mutation";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import { getStates } from "../../helpers/account-details";
import { validatePhoneNumber } from "../../helpers/validate-us-number";
import { isHomeHeader } from "../../redux/actions";
import Alert from "../alert/alert.component";
import EmailMarketing from "../email-marketing/email-marketing.component";
import FormControlError from "../form-control-error/form-control-error.component";
import Loader from "../loader/loader.component";
import Modal from "../modal/modal.component";
import { generalErrorMessage } from "../resitration-steps/user-info/user-info.helper";
import UpdateAccountDetails from "../update-account-details/update-account-details.component";

import "./account-details.scss";

const AccountDetails = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openModal, setOpenModal] = React.useState(false);
  const [openInfoModal, setInfoOpenModal] = React.useState(false);
  const [phoneUpdateState, setPhoneUpdateState] = useState(0);
  const [newPhone, setNewPhone] = useState<any>("");
  const [codeInput, setCodeInput] = useState<any>("");

  const handleOpenModal = async () => {
    setPhoneUpdateState(0);
    setOpenModal(true);
  };

  const onClickUpdate = () => {
    if (phoneUpdateState > 0) {
      if (codeInput) {
        updatePhoneFunc();
      }
      return;
    } else {
      if (validatePhoneNumber(newPhone)) {
        generatePhoneCodeFunc();
        return;
      }
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenInfoModal = () => setInfoOpenModal(true);
  const handleCloseInfoModal = () => setInfoOpenModal(false);

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");
  const navigate = useNavigate();

  const openAlert = (error: any) => {
    if (!error) return;
    setError(error);
    setOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [userData, setUserData] = React.useState<any>(null);
  const { loading: userLoader, error: userError } = useQuery(GET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => setUserData(data),
  });

  const [getUserFunc] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => setUserData(data),
  });

  const onUpdateUser = async () => {
    try {
      await getUserFunc();
    } catch (err: any) {
      openAlert(err?.message);
    }
  };

  React.useEffect(() => {
    if (userError?.message) {
      openAlert(userError?.message);
    }
    if (
      userError?.message ===
      "Access denied! You need to be authorized to perform this action!"
    ) {
      navigate("/login", {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userError]);

  // const [updateSuccess, setUpdateSuccess] = React.useState<boolean>(false);

  // const [newDetails, setNewDetails] = useState({
  //   phone: "",
  //   code: "",
  // });

  const isValidPhoneNumber = (value: string) => {
    const regex = new RegExp(/(\+\d{1,2}\s)?\(?\d{3}\)?\d{3}\d{4}$/);
    return regex.test(value);
  };

  const onPhoneChange = (data: any) => {
    if (!isValidPhoneNumber(data)) return;
    setNewPhone(data);
  };

  // const onUpdateCodeInput = (e: any) => {
  //   setNewDetails({ ...newDetails, phone: e.target.value });
  // };

  const [userDetails, setUserDetails] = useState<any>({
    firstName: "",
    lastName: userData?.getUser?.lastName,
    cellPhone: `${userData?.getUser?.mobile?.countryCode}${userData?.getUser?.mobile?.localNumber}`,
    email: userData?.getUser?.email,
    addressLine1: userData?.getUser?.home?.addressLine1,
    addressLine2: userData?.getUser?.home?.addressLine2,
    city: userData?.getUser?.home?.city,
    state: userData?.getUser?.home?.state,
    zip: userData?.getUser?.home?.zip,
    country: userData?.getUser?.home?.country || "United States",
  });

  React.useEffect(() => {
    if (!userData?.getUser) return;
    setUserDetails({
      firstName: userData?.getUser?.firstName,
      lastName: userData?.getUser?.lastName,
      cellPhone: `${userData?.getUser?.mobile?.countryCode}${userData?.getUser?.mobile?.localNumber}`,
      email: userData?.getUser?.email,
      addressLine1: userData?.getUser?.home?.addressLine1,
      addressLine2: userData?.getUser?.home?.addressLine2,
      city: userData?.getUser?.home?.city,
      state: userData?.getUser?.home?.state,
      zip: userData?.getUser?.home?.zip,
      country: userData?.getUser?.home?.country || "United States",
    });
  }, [userData]);

  const [
    generatePhoneCodeFunc,
    { loading: generatePhoneCodeLoader, error: generatePhoneCodeError },
  ] = useMutation(GENERATE_UPDATE_PHONE_CODE, {
    variables: {
      phoneNumber: "+" + newPhone,
    },
    onCompleted: () => setPhoneUpdateState(1),
  });

  const [
    updatePhoneFunc,
    { loading: updatePhoneLoader, error: updatePhoneError },
  ] = useMutation(UPDAE_PHONE, {
    variables: {
      phoneNumber: "+" + newPhone,
      digits: codeInput,
    },
    onCompleted: () => {
      setOpenModal(false);
      window.location.reload();
    },
  });

  React.useEffect(() => {
    updatePhoneError?.message
      ? openAlert(updatePhoneError?.message)
      : openAlert(generatePhoneCodeError?.message);
  }, [updatePhoneError, generatePhoneCodeError]);

  return (
    <>
      {userLoader ? (
        <Loader />
      ) : (
        <div className=" account-content details">
          <h1 className="sub-title">Account details</h1>

          <div className="form-block">
            <form>
              <div className="form-row pad10">
                <div className="form-group col-md-6 m10">
                  <label>Legal First name</label>
                  <input
                    value={userDetails.firstName}
                    type="text"
                    className="form-control"
                    readOnly
                  />
                </div>

                <div className="form-group col-md-6 m10">
                  <label>Legal Last name</label>
                  <input
                    value={userDetails.lastName}
                    type="text"
                    className="form-control"
                    readOnly
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 m10">
                  <label>Mobile phone number</label>
                  <input
                    value={userDetails.cellPhone}
                    type="number"
                    className="form-control"
                    autoComplete="false"
                    readOnly
                  />
                </div>

                <div className="form-group col-md-6 m10">
                  <label>
                    Other phone number{" "}
                    <span className="optional">(optional)</span>
                  </label>
                  <input type="number" className="form-control" readOnly />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-12 m10">
                  <label>Email address</label>
                  <input
                    value={userDetails.email}
                    type="email"
                    readOnly
                    autoComplete="false"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="action-button">
                  <button
                    className="btn btn-update"
                    type="button"
                    onClick={() => handleOpenModal()}
                  >
                    Update Phone
                  </button>
                </div>
              </div>

              <div className="form-row">
                <div className="line-center"></div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 m10">
                  <label>Address line 1</label>
                  <input
                    value={userDetails.addressLine1}
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    placeholder="Address Line 1"
                    readOnly
                  />
                </div>

                <div className="form-group col-md-6 m10">
                  <label>
                    Address line 2 <span className="optional">(optional)</span>
                  </label>
                  <input
                    value={userDetails.addressLine2}
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    readOnly
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 m10">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control"
                    value={userDetails.city}
                    autoComplete="false"
                    placeholder="City"
                    minLength={2}
                    readOnly
                  />
                </div>

                <div className="form-group col-md-6 m10">
                  <label>State </label>
                  <select
                    value={userDetails.state}
                    disabled
                    className="form-control"
                  >
                    <option value="">- Select state -</option>
                    {getStates().map((state: string, i: number) => (
                      <option key={i} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 m10">
                  <label>Zip code</label>
                  <input
                    value={userDetails.zip}
                    type="text"
                    className="form-control"
                    minLength={2}
                    readOnly
                  />
                </div>
                <div className="form-group col-md-6 m10">
                  <label>Country </label>
                  <input
                    value={userDetails.country}
                    type="text"
                    className="form-control"
                    readOnly
                  />
                </div>
              </div>
              <div className="action-button">
                <button
                  className="btn btn-update"
                  type="button"
                  onClick={() => handleOpenInfoModal()}
                >
                  Update information
                </button>
              </div>
              <div className="form-row">
                <div className="line-center"></div>
                <EmailMarketing isEmailMarketingAllowed={userData?.getUser?.allowReceiveMarketingEmails} />
              </div>
            </form>
          </div>
        </div>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{ padding: 25 }}>
          {phoneUpdateState > 0 ? (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <span style={{ display: "block" }}>
                You just received an update code message to your phone.
              </span>
              <span style={{ display: "block" }}>
                Please enter the code with your new phone number
              </span>
            </Typography>
          ) : (
            ""
          )}
          <Typography id="modal-modal-description" sx={{ mt: 4 }}>
            <label className="account-details__label">New phone number</label>
            <PhoneInput
              isValid={(value) => {
                return validatePhoneNumber(value)
              }}
              value={newPhone}
              onChange={onPhoneChange}
              country={"us"}
              disabled={phoneUpdateState > 0}
              inputClass={`${!validatePhoneNumber(newPhone) ? 'error' : ''}`}
              onlyCountries={['us']}
            />
            {!validatePhoneNumber(newPhone) && <FormControlError errorMessage={generalErrorMessage('Phone ')} />}

            {phoneUpdateState > 0 ? (
              <div style={{ marginTop: 24 }}>
                <label className="account-details__label">
                  Verfification Code
                </label>
                <input
                  value={codeInput}
                  onChange={(e) => setCodeInput(e?.target?.value)}
                  type="text"
                  className="account-details__input"
                  placeholder="Verfification Code"
                />
              </div>
            ) : (
              ""
            )}

            {generatePhoneCodeLoader || updatePhoneLoader ? (
              <Loader size={20} />
            ) : (
              <button
                className="btn account-details__update-btn"
                type="button"
                disabled={
                  (!validatePhoneNumber(newPhone) && phoneUpdateState === 0) ||
                  (codeInput === "" && phoneUpdateState > 0)
                }
                onClick={() => onClickUpdate()}
              >
                {phoneUpdateState > 0 ? "Update" : "Send Code"}
              </button>
            )}
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={openInfoModal}
        onClose={handleCloseInfoModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UpdateAccountDetails
          oldUserDetails={userDetails}
          openAlert={openAlert}
          handleCloseInfoModal={handleCloseInfoModal}
          onUpdateUser={onUpdateUser}
        />
      </Modal>
    </>
  );
};

export default AccountDetails;
