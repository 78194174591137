import { useMutation } from "@apollo/client";
import { Snackbar } from "@mui/material";
import React from "react";
import { NestedValue, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";

import { LOGIN } from "../../graphql/mutations/auth/auth.mutations";
import { isHomeHeader } from "../../redux/actions";
import Alert from "../alert/alert.component";
import Loader from "../loader/loader.component";

import "./login.scss";
import { GOOGLE_LOGIN } from "../../graphql/mutations/auth/google-login.mutation";

const Login = () => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");
  const [googleRes, setGoogleRes] = React.useState({
    id_token: "",
    access_token: "",
  });

  const clientId = process.env.REACT_APP_GOOGLE_LOGIN as string;

  React.useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  const openAlert = (error: any) => {
    setError(error);
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<{
    email: NestedValue<any>;
    password: NestedValue<any>;
  }>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [login, { loading, error: loginErr }] = useMutation(LOGIN, {
    variables: {
      email: getValues("email"),
      password: getValues("password"),
    },
    onCompleted: (data) => {
      if (data?.login?.status === "LOCKED") {
        openAlert("User is Locked");
        navigate("/locked-user");
        return;
      }
      if (data?.login?.status === "ACTIVE") {
        navigate("/accounts/me", { replace: true });
        return;
      }
      if (data?.login?.status !== "ACTIVE") {
        navigate("/register/user-details");
        return;
      }
    },
  });

  const [googleLogin, { loading: googleLoginLoader }] = useMutation(
    GOOGLE_LOGIN,
    {
      variables: {
        access_token: googleRes.access_token,
        id_token: googleRes.id_token,
      },
      onCompleted: () => {
        navigate("/accounts/me", { replace: true });
        return;
      },
    }
  );

  React.useEffect(() => {
    if (loginErr?.message) {
      openAlert(loginErr?.message);
    }
  }, [loginErr]);

  const navigate = useNavigate();

  React.useEffect(() => {
    register("email", {
      validate: (value) => value.length || "Email is required.",
    });
    register("password", {
      validate: (value) => value.length || "Please enter password.",
    });
  }, [register]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await login();
    } catch (err) { }
  });

  React.useEffect(() => {
    if (googleRes.access_token === "" || googleRes.id_token === "") return;
    googleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleRes]);

  const onSuccess = (res: any) => {
    const { tokenId: id_token, accessToken: access_token } = res;
    setGoogleRes({ id_token, access_token });
  };
  const onFailure = (err: any) => {
    console.log(err);
  };

  return (
    <section className="login">
      <div className="container">
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col form">
            <form className="col" onSubmit={onSubmit}>
              <div className="form-group title">
                <span className="fa fa-lock"></span>
                <label className="text-center call-txt">Log In</label>
              </div>
              <div className="form-group">
                <div className="col-md-12 pad">
                  <input
                    {...register("email")}
                    type="email"
                    className={`form-control ${"error" ?? errors?.email}`}
                    placeholder="Email"
                    autoComplete="off"
                    minLength={3}
                  />
                  {errors?.email && (
                    <span className="input-error">
                      <div className="fa-icon fa fa-exclamation-circle"></div>
                      {errors?.email && <p>{errors?.email.message}</p>}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-12 pad">
                  <input
                    {...register("password")}
                    type="password"
                    className={`form-control ${"error" ?? errors?.email}`}
                    placeholder="Password"
                    autoComplete="off"
                    minLength={3}
                  />
                  {errors?.password && (
                    <span className="input-error">
                      <div className="fa-icon fa fa-exclamation-circle"></div>
                      {errors?.password && <p>{errors?.password.message}</p>}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-12 pad">
                  <button type={"submit"} className="btn btn-login">
                    {loading ? <Loader size={30} /> : "Login"}
                  </button>
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-12 pad or-line">
                  <h2>
                    <span>OR</span>
                  </h2>
                </div>
              </div>
              <div className="form-group">
                <div className="col-md-12 pad google-login">
                  {googleLoginLoader ? (
                    <Loader />
                  ) : (
                    <GoogleLogin
                      clientId={clientId}
                      buttonText="Sign in with Google"
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      cookiePolicy={"single_host_origin"}
                    />
                  )}
                  <button
                    className="forgot-link text-center"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot Password?
                  </button>
                </div>
              </div>
            </form>

            <div className="row">
              <div className="col-md-12 text-center already">
                Don't have an account?
                <br />
                <a href="join">
                  <span>Create one</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </section>
  );
};

export default Login;
