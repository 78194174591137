import { gql } from "@apollo/client";

export const ANSWER_SURVEY = gql`
  mutation answerSurvey(
    $about: String!
    $comments: String!
    $rate: Float!
    $reasons: String!
  ) {
    answerSurvey(
      input: {
        rate: $rate
        reasons: $reasons
        about: $about
        comments: $comments
      }
    ) {
      uuid
      rate
      reasons
      about
      comments
    }
  }
`;
