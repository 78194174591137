import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

import "./navbar.scss";

const Navbar = () => {
  const user = useSelector<RootState, RootState["user"]["isUser"]>((state) => {
    return state.user.isUser;
  });

  return (
    <div id="headerback">
      <div className="container-fluid" style={{ padding: "0px" }}>
        <nav className="navbar navbar-default">
          <div className="container">
            <div className="navbar-header">
              <div className="navbar-brand navbar-link">
                <a href="/home" about="navbar-logo">
                  {!user?.info ||
                    (user.info && !user?.info.tsUserId && (
                      <img
                        className="navbar-brand navbar-link"
                        src="/assets/images/headerback_logo.png"
                        alt="headerback_logo"
                      />
                    ))}
                  {user?.info && user?.info.tsUserId && (
                    <img
                      className="navbar-brand navbar-link"
                      width="150"
                      src="../assets/images/tspartners_logo.gif"
                      alt="tspartners_logo"
                    />
                  )}
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
