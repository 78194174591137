/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const ADD_USER_INFO = gql`
  mutation AddUserInfo(
    $firstName: String!
    $lastName: String!
    $addressLine1: String!
    $addressLine2: String
    $city: String!
    $state: String!
    $type: AddressType!
    $zip: String!
  ) {
    addUserInfo(firstName: $firstName, lastName: $lastName) {
      firstName
      lastName
      email
      isEmailVerified
      status
      type
      paymentMethod
    }
    addAddress(
      input: {
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        state: $state
        zip: $zip
        type: $type
      }
      isDefault: true
    ) {
      addressLine1
      city
      state
      zip
      country
      type
    }
  }
`;

export const ADD_PHONE = gql`
  mutation addPhone($phoneNumber: String!, $phoneType: PhoneType!) {
    addPhone(phoneNumber: $phoneNumber, type: $phoneType) {
      localNumber
      type
      countryCode
    }
  }
`;

export const ADD_IDENTITY_DATA = gql`
  mutation identity($ssn: String!, $dayOfBirth: DateTime!) {
    addIdentityData(dayOfBirth: $dayOfBirth, ssn: $ssn) {
      user {
        uuid
        uuid
        firstName
        lastName
        dayOfBirth
        status
        mobile {
          uuid
          localNumber
          countryCode
          isVerified
        }
        home {
          uuid
          addressLine1
          addressLine2
          city
          state
          zip
          type
        }
      }
      emailVerificationCode {
        uuid
        media
        expireOn
      }
      phoneVerificationCode {
        uuid
        media
        expireOn
      }		
    }
  }
`;
