import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

type AboutTemplateType = {
  template: { img?: string; name?: string; linkedin?: string; desc?: string };
};

const AboutTemplate: React.FC<AboutTemplateType> = ({ template }) => {
  return (
    <div style={{ display: "flex", padding: 25 }}>
      <img
        src={template?.img}
        alt={template?.name}
        style={{
          width: 200,
          height: 200,
          marginRight: 25,
          borderRadius: "50%",
        }}
      />

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <h4>{template?.name}</h4>
          <a href={template?.linkedin} target="_blank" rel="noreferrer">
            <LinkedInIcon />
          </a>
        </div>
        <p>{template?.desc}</p>
      </div>
    </div>
  );
};

export default AboutTemplate;
