import React from "react";

import "./home-section-description.scss";

type HomeSectionDescriptionType = {
  title: string;
  description: string;
};

const HomeSectionDescription: React.FC<HomeSectionDescriptionType> = ({
  title,
  description,
  children,
}) => {
  return (
    <div
      style={{
        paddingTop: 50,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <h1 className="text-center">{title}</h1>
      <div className="line"></div>
      <p style={{ maxWidth: 650, padding: 35 }} className="text-center">
        {description}
      </p>
      {children}
    </div>
  );
};

export default HomeSectionDescription;
