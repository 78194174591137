import { Action } from "../actions";

export interface OtherState {
  isHomeHeader: boolean;
}

const initialState = {
  isHomeHeader: true,
};

export const OtherReducer = (
  state: OtherState = initialState,
  action: Action
) => {
  switch (action.type) {
    case "IS_HOME_HEADER": {
      return { ...state, isHomeHeader: action.payload };
    }
    default:
      return state;
  }
};
