import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { TransactionStatus } from "../../../graphql/enums/transaction.enums";
import { ADD_DOCUMENT } from "../../../graphql/mutations/documents/add-document.mutation";
import Loader from "../../loader/loader.component";

const buttonStyle = {
  width: 350,
};

export interface ObjRef {
  id: string;
  isSignAndCreate: boolean;
  file?: File;
  isSignAndCreateBtnActive?: boolean;
}

type DocumentsFooterTableButtonsType = {
  transactionUUID: string;
  openAlert: (error: any) => void;
  setDocumentUUID: (file: ObjRef) => void;
  setIsFileUploading: (isLoader: boolean) => void;
  updateSignedDocUUID: (documentUUID: ObjRef) => void;
  transaction: any
};

const DocumentsFooterTableButtons: React.FC<
  DocumentsFooterTableButtonsType
> = ({
  transactionUUID,
  openAlert,
  setDocumentUUID,
  setIsFileUploading,
  updateSignedDocUUID,
  transaction
}) => {
    const [file, setFile] = useState<any>(null);
    const [isSignAndCreate, setIsSignAndCreate] = useState<boolean>(false);

    const [
      addOtherDocsFunc,
      { loading: addOtherDocsLoader, error: addOtherDocsError },
    ] = useMutation(ADD_DOCUMENT, {
      variables: {
        transactionUUID: transactionUUID,
        name: file?.name,
        type: isSignAndCreate ? "OTHERSIGNEDDOCUMENT" : "OTHERDOCUMENT",
      },
    });

    React.useEffect(() => {
      if (addOtherDocsError?.message) {
        openAlert(addOtherDocsError?.message);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addOtherDocsError]);

    React.useEffect(() => {
      if (file === null || !file || !file?.name) return;

      let isCancelled = false;

      addOtherDocsFunc()
        .then((d: any) => {
          if (!isCancelled && d?.data?.addDocument?.uuid) {
            let newObjRef: ObjRef = {
              id: d?.data?.addDocument?.uuid,
              isSignAndCreate: false,
              file: file,
            };

            if (!isSignAndCreate) {
              newObjRef = {
                ...newObjRef,
              };
              setDocumentUUID(newObjRef);
            } else {
              newObjRef = {
                ...newObjRef,
                isSignAndCreate: true,
              };
              updateSignedDocUUID(newObjRef);
            }
          } else {
            openAlert("Something went wring, please try again!");
          }
        })
        .catch((err: any) => {
          setIsFileUploading(false);
          openAlert(err?.message);
        });

      return () => {
        isCancelled = true;
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    const onFileChange = (event: any) => {
      const fileSize = event.target.files[0].size / 1024;
      if (fileSize / 1000 <= 5) {
        setFile(event.target.files[0]);
      } else {
        openAlert("It is not possible to upload files larger than 5MB");
      }
      event.target.value = null;
    };

    const onUploadFile = async (d: string) => {
      document.getElementById(d)?.click();
    };

    const onAddSupportingDoc = () => {
      setIsSignAndCreate(false);
      onUploadFile("file_input_id");
    };

    const onAddAdditionalForm = () => {
      setIsSignAndCreate(true);
      onUploadFile("file_input_id");
    };

    return (
      <tr style={{ display: "flex", paddingTop: 25, width: 485 }}>
        <input
          type="file"
          accept="application/pdf"
          id={"file_input_id"}
          style={{ display: "none" }}
          onChange={(e) => onFileChange(e)}
        />
        {addOtherDocsLoader ? (
          <Loader size={20} />
        ) : (
          <>
            {transaction?.status !== TransactionStatus.COMPLETED && <>
              <button
                style={{ ...buttonStyle, marginRight: 25 }}
                onClick={onAddSupportingDoc}
              >
                Add Supporting Document
              </button>
              <button style={buttonStyle} onClick={onAddAdditionalForm}>
                Add Additional Form To Sign
              </button>
            </>
            }
          </>
        )}
      </tr>
    );
  };

export default DocumentsFooterTableButtons;
