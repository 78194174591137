/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const GET_DOC_UPLOAD_URL = gql`
  mutation generateDocumentUploadURL(
    $documentUUID: String!
    $transactionUUID: String!
    $documentFileType: DocumentFileType!
  ) {
    generateDocumentUploadURL(
      transactionUUID: $transactionUUID
      documentUUID: $documentUUID
      documentFileType: $documentFileType
    )
  }
`;
