import React from "react";
import { useDispatch } from "react-redux";
import { isHomeHeader } from "../../redux/actions";
import HomeSectionDescription from "../home-section-description/home-section-description.component";

import "./terms.scss";

const termsDesc = {
  title: "Terms of Use",
  desc: "",
};
const Terms = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container">
        <div className="about">
          <HomeSectionDescription
            title={termsDesc.title}
            description={termsDesc.desc}
          ></HomeSectionDescription>

          <p className="first">
            Please review the following terms of use (“TOU”) concerning your use
            of{" "}
            <a href="http://www.eSignatureGuarantee.com" target="_blank">
              eSignatureGuarantee.com
            </a>{" "}
            and the services offered and being provided herein (the “Site”). By
            sub mitting your personal information, answering the questions asked
            and clicking on the “Complete Transaction” button on the Site you
            agree to follow and be bound by the following TOU.
          </p>

          <h4 className="pad">
            If you do not agree with any of these terms and conditions, do not
            use the Site.
          </h4>
          <p>
            By utilizing this Site, you understand and agree that information
            about you and your transaction will be shared with other companies
            for the purpose of processing your transaction, including credit
            card processing, identity verification vendors and information
            technology service providers. Further, by using our services, you
            understand and agree that we and/or our service providers may
            communicate with you regarding the services being provided. You may
            also receive periodic e-mail messages from us.
          </p>

          <h4 className="pad">1. THESE TERMS AND CONDITIONS MAY CHANGE</h4>
          <p>
            eSignature Group, LLC (the “Company”) reserves the right to update
            or modify these TOU at any time without prior notice. Your use of
            the Site following any such change constitutes your agreement to
            follow and be bound by the TOU as changed. For this reason, we
            encourage you to review these TOU whenever you use this Site.
          </p>

          <h4 className="pad">2. PRIVACY POLICY</h4>
          <p>
            The Company takes the privacy of our customers very seriously. We
            promise responsible handling of all sensitive and personal customer
            information. We encourage you to review our complete Privacy Policy
            here to be informed of how we collect, use and protect your
            information.
          </p>

          <h4 className="pad">3. COPYRIGHT &amp; TRADEMARK RIGHTS</h4>
          <p>
            eSignatureGuarantee, the eSignatureGuarantee logo, and the Site are
            all property of the Company. Other trademarks, product names and
            company names or logos used on this site are the property of their
            respective owners. All images, graphics, text and other content used
            in connection with the Site are protected by trademark, copyright
            and other proprietary laws and treaty provisions. You may not
            download (except where invited), reproduce, modify, publish,
            distribute, transmit, transfer, sell or modify any portion of this
            Site without our express written authorization.
          </p>

          <h4 className="pad">4. OUR SERVICE</h4>
          <p>
            The Company provides services as an Internet-based service provider
            that allows authenticated visitors from anywhere in the United
            States of America to submit a stock power or stock certificate to
            obtain a medallion stamp guarantee (the “Service”). The Company, as
            a member of the Stock Transfer Agents Medallion Program, will be
            providing the applicable medallion stamp guarantee. Notwithstanding
            anything contained herein to the contrary the final determinations
            with respect to the provision of any medallion stamp guarantee shall
            be made in the sole and absolute discretion of the Company. If the
            decision is made by the Company, after the submission of all
            information and documentation not to provide a medallion stamp
            guarantee you will receive a refund of applicable fees paid. The
            Site includes instructions for accessing the Service and completing
            a transaction, which may include one or more of the following steps:
            (i) your provision of requested biographical information; (ii) you
            create a unique password; (iii) you respond to a series of questions
            that allows us to confirm your identity; (iv) send by Federal
            Express original executed stock power or stock certificate to that
            you wish to be guaranteed along with all other documentation
            instructed; and (v) you pay the required fee.
            <br />
            In the event that your identity cannot be confirmed, or you are
            unable or unwilling to complete the process to properly submit your
            document you may still be required to pay the full fee; the only
            time a refund will be provided is if the Company determines, in
            their sole discretion, not to proceed with the medallion process.
            <br />
            If you commence but do not complete a transaction on the Site, the
            Company may contact you in an effort to assist you with the
            completion of the transaction.
            <br />
            If at any time you have questions about the Site or the Service, you
            can reach us via e-mail to{" "}
            <a href="mailto:info@esignatureguarantee.com">
              info@esignatureguarnatee.com
            </a>
            .<br />
            THE COMPANY DOES NOT PROVIDE LEGAL SERVICES OR ADVICE, AND DOES NOT
            AND WILL NOT GIVE LEGAL ADVICE OR ACCEPT FEES FOR LEGAL ADVICE.
            <br />
            Although the Company attempts to provide accurate information on the
            Site, it assumes no responsibility for the accuracy of the
            information. The Company may change the Services or prices mentioned
            at any time without notice. The Company may periodically make
            changes to the Site.
          </p>

          <h4 className="pad">5. USER INFORMATION</h4>
          <p>
            In order to access and use certain content, features, or
            functionality of the Site to obtain the Service being provided, we
            require that you provide your name and certain additional
            information, which may include, without limitation, your email
            address, legal name, date of birth, country of residence, zip code,
            your physical address, telephone number(s), social security number,
            applicable securities and payment data and information. You
            represent and warrant that all information you submit is truthful
            and accurate. We reserve the right to deny access, use and other
            privileges to any user of the Service if we believe there is a
            question about the identity of the person trying to access the
            Service. The Company shall not be responsible or liable for any loss
            or damage arising from your failure to comply with this section.
          </p>

          <h4 className="pad">6. USER CONDUCT</h4>

          <h4 className="pad">7. INDEMNIFICATION</h4>
          <p>
            You agree to indemnify, defend and hold the Company and its
            successors and assigns, directors, officers, employees,
            representatives, agents, licensors and service providers harmless
            from any and all claims, liabilities, damages, losses, costs and
            expenses (including reasonable attorneys’ fees), arising in any way
            out of or in connection with (a) your use of the Services or (b)
            your breach or violation this Agreement. The Company reserves the
            right to assume the exclusive defense and control of any matter
            subject to indemnification by you and all negotiations for its
            settlement or compromise, and you agree to fully cooperate with us
            upon our request.
          </p>

          <h4 className="pad">8. DISCLAIMER AND LIMITATION OF LIABILITY</h4>
          <p>
            ALL INFORMATION ON THIS SITE IS PROVIDED “AS IS” WITH ALL FAULTS
            WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. THE COMPANY
            DOES NOT MAKE ANY GUARANTY OR ASSURANCE THE SERVICE WILL BE
            AVAILABLE FOR USE, OR THAT ANY PRODUCTS, FEATURES, FUNCTIONS,
            SERVICES OR OPERATIONS WILL BE AVAILABLE OR PERFORM AS DESCRIBED.
            THE COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
            WITHOUT LIMITATION, THOSE OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE AND NONINFRINGEMENT.
            <br />
            Without limiting the foregoing, the Company is not responsible or
            liable for any malicious code, delays, inaccuracies, errors, or
            omissions arising out of your use of the Site or Service. You
            understand, acknowledge and agree that you are assuming the entire
            risk as to the quality, accuracy, performance, timeliness, adequacy,
            completeness, correctness, authenticity, security and validity of
            any and all features and functions of the Service.
            <br />
            IN NO EVENT SHALL THE COMPANY’S AGGREGATE LIABILITY UNDER THIS
            AGREEMENT EXCEED FIFTY ($50) DOLLARS, OR THE AMOUNT ACTUALLY PAID BY
            YOU FOR THE EVENT GIVING RISE TO SUCH CLAIM, WHICHEVER IS GREATER.
            YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMISSIBLE BY
            LAW, THE COMPANY AND ITS SUCCESSORS AND ASSIGNS, OFFICERS,
            DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, LICENSORS AND SERVICE
            PROVIDERS, ADVERTISERS AND SUPPLIERS, SHALL NOT BE LIABLE FOR ANY
            LOSS OR DAMAGE, OF ANY KIND, DIRECT OR INDIRECT, IN CONNECTION WITH
            OR ARISING FROM USE OF THE SITE OR THE SERVICE, OR FROM THIS
            AGREEMENT, INCLUDING, WITHOUT LIMITATION, COMPENSATORY,
            CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL OR PUNITIVE DAMAGES.
            <br />
            Some jurisdictions do not allow for the exclusion of certain
            warranties or certain limitations on damages and remedies,
            accordingly some of the exclusions and limitations described in
            these TOU may not apply to you.
          </p>

          <h4 className="pad">9. GOVERNING LAW; MISCELLANEOUS</h4>
          <p className="last">
            These TOU contain the entire understanding and agreement between you
            and the Company concerning the Service and supersedes any and all
            prior or inconsistent understandings relating to the Service and
            your use thereof. If any provision of these TOU is held to be
            illegal, invalid or unenforceable, this shall not affect any other
            provisions and these TOU shall be deemed amended to the extent
            necessary to make it legal, valid and enforceable. Any provision
            that must survive in order to allow us to enforce its meaning shall
            survive the termination of these TOU; however, no action arising out
            of these TOU or your use of the Service, regardless of form or the
            basis of the claim, may be brought by you more than one year after
            the cause of action has arisen (or if multiple causes, from the date
            the first such cause arose). The failure of the Company to exercise
            or enforce any right or provision of these TOU will not operate as a
            waiver of such right or provision.
            <br />
            These TOU and your use of the Service is governed by, construed and
            enforced in accordance with the internal substantive laws of the
            State of New York (notwithstanding the state’s conflict of laws
            provisions) applicable to contracts made, executed and wholly
            performed in New York, and, for the purposes of any and all legal or
            equitable actions, you specifically agree and submit to the
            exclusive jurisdiction and venue of the State and Federal Courts
            situated in Nassau County, New York and agree you shall not object
            to such jurisdiction or venue on the grounds of lack of personal
            jurisdiction, forum non conveniens or otherwise. IN ANY ACTION OR
            PROCEEDING COMMENCED TO ENFORCE ANY RIGHT OR OBLIGATION OF THE
            PARTIES UNDER THIS AGREEMENT, YOUR USE OF THE SERVICES OR WITH
            RESPECT TO THE SUBJECT MATTER HEREOF, YOU HEREBY WAIVE ANY RIGHT YOU
            MAY NOW HAVE OR HEREAFTER POSSESS TO A TRIAL BY JURY.
          </p>
        </div>
      </div>
    </>
  );
};

export default Terms;
