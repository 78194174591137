import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const testData = require("./test-data.json");

const CarouselComponent = () => {
  return (
    <Carousel showArrows={false} showThumbs={false} showStatus={false}>
      {testData.map((d: any, i: number) => (
        <div key={i}>
          <div className="row">
            <div className="col-xs-6 col-md-1">
              <div className="row" style={{ marginBottom: 15 }}>
                <div className="col-xs-12 col-md-12">
                  <img
                    src={d.image}
                    width="150"
                    height="150"
                    style={{ borderRadius: 75, width: 150, height: 150 }}
                    alt={"customer_img"}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-6 col-md-11">
              <p>“{d.comment}”</p>
            </div>
          </div>
          <div className="row">
            <div style={{ paddingLeft: 20 }}>
              <a
                href={d.linkedin}
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/images/linkedin.png"
                  style={{ display: "inline-block", width: 25, height: 25 }}
                  alt={"linkdein"}
                />
              </a>
              -
              <span>
                {d.author}, {d.title_or_location}
              </span>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
