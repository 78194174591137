/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const VERIFY_IDOLOGY_SEND_DATA = gql`
  mutation {
    verifyIdentity(action: SEND_IDENTITY_DATA) {
      firstName
      lastName
      email
      isEmailVerified
      status
      type
      paymentMethod
      idologyResponse {
        answerType
        questions {
          order
          prompt
          type
          answers
        }
        status
      }
    }
  }
`;
