import React from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import { TransactionStatus } from "../../graphql/enums/transaction.enums";
import { Transaction } from "../../graphql/interfaces/transaction.interfaces";
import { useDispatch } from "react-redux";
import {
  readyForSecondStepCheckout,
  readyForSThirdStepCheckout,
  setTransactionMode,
  setTransactionsTypes,
} from "../../redux/actions";
import { sumMedallionsTotalLength } from "../checkout/checkout.helpers";
import { normelizeStatus } from "../post-transaction-details/post-transaction-details.helper";
import { viewDetailsValidStatuses } from "./all-transactions.helper";

type AllTransactionType = {
  items: Transaction[] | undefined;
};

const AllTransactions: React.FC<AllTransactionType> = ({ items }) => {
  React.useEffect(() => {
    dispatch(setTransactionMode(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cancel = (barcode: string) => {
    return "$http.put('/api/users/me/transactions/' + barcode + '/cancel')";
  };

  const cancelTransaction = (barcode: string, idx: number) => {
    cancel(barcode);
  };

  const onResumeTransaction = async (transaction: any) => {
    dispatch(setTransactionMode(transaction?.uuid));
    dispatch(
      setTransactionsTypes({
        stockholderType: transaction?.stockholderType,
        securityType: transaction?.securityType,
      })
    );
    dispatch(readyForSecondStepCheckout(true));
    if (
      transaction?.transferAgent?.uuid &&
      transaction?.certificates &&
      transaction?.certificates?.length > 0
    ) {
      dispatch(readyForSThirdStepCheckout(true));
      navigate("/transactions/create/checkout");
      return;
    }
    navigate("/transactions/create/certificate");
  };

  return (
    <div className="transactions-section col-md-12">
      <table
        id="transactions-table"
        cellPadding="0"
        cellSpacing="0"
        style={{ border: "0" }}
      >
        <thead>
          <tr>
            <th style={{ width: 150 }}>date</th>
            <th style={{ width: 150 }}>id</th>
            <th style={{ width: 130 }}>Medallions</th>
            <th style={{ width: 120 }}>Subtotal</th>
            <th style={{ width: 120 }}>Status</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((transaction: Transaction, index: number) => {
            return (
              <tr
                className={`complete: ${transaction.status === TransactionStatus.COMPLETED ||
                  transaction.status === TransactionStatus.SUSPICIOUS ||
                  transaction.status === TransactionStatus.CANCELLED
                  }`}
                key={index}
              >
                <td>
                  {moment(transaction.createdAt).format("MM/DD/YYYY")}
                </td>
                <td>{transaction.code}</td>
                <td>{sumMedallionsTotalLength(transaction)}</td>
                <td>${sumMedallionsTotalLength(transaction) * 149}</td>
                <td>{normelizeStatus(transaction.status)}</td>
                <td>
                  {transaction.status === TransactionStatus.CREATING ||
                    transaction.status === TransactionStatus.VERIFIED ? (
                    <button
                      className="btn btn-view-details"
                      type={"button"}
                      onClick={() => {
                        onResumeTransaction(transaction);
                      }}
                    >
                      Resume
                    </button>
                  ) : (
                    ""
                  )}
                  {viewDetailsValidStatuses.includes(transaction.status) && (
                    <Link
                      className="btn btn-view-details"
                      to={`/details/${transaction?.uuid}`}
                    >
                      View Details
                    </Link>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AllTransactions;
