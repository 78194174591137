/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($password: String!, $email: String!) {
    login(email: $email, password: $password) {
      email
      isEmailVerified
      status
      type
      paymentMethod
      roles
    }
  }
`;

export const REGISTER = gql`
  mutation Register($password: String!, $email: String!) {
    register(email: $email, password: $password) {
      firstName
      lastName
      email
      isEmailVerified
      status
      type
      paymentMethod
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $password: String!
    $email: String!
    $digits: String!
  ) {
    resetPassword(digits: $digits, email: $email, password: $password)
  }
`;

export const GENERATE_RESET_PASSCODE = gql`
  mutation generateResetPasswordCode($email: String!) {
    generateResetPasswordCode(email: $email) {
      uuid
      createdAt
    }
  }
`;
