/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const REMOVE_CERTIFICATE = gql`
  mutation removeCertificate(
    $transactionUUID: String!
    $certificateUUID: String!
  ) {
    removeCertificate(
      transactionUUID: $transactionUUID
      certificateUUID: $certificateUUID
    ) {
      uuid
      symbol
      price
      shares
      marketValueTotal
    }
  }
`;
