import { combineReducers } from "redux";
import { OtherReducer, OtherState } from "./other";
import { UserReducer, UserState } from "./user";
import { CheckoutReducer, CheckoutState } from "./checkout";
import { TransactionReducer, TransactionState } from "./transaction";
import { registerReducer, RegisterState } from "./register";

export const rootReducer = combineReducers({
  register: registerReducer,
  user: UserReducer,
  other: OtherReducer,
  checkout: CheckoutReducer,
  transaction: TransactionReducer,
});

export interface RootState {
  user: UserState;
  other: OtherState;
  register: RegisterState;
  checkout: CheckoutState;
  transaction: TransactionState;
}
