import { gql } from "@apollo/client";

export const GOOGLE_LOGIN = gql`
  mutation googleLogin($id_token: String!, $access_token: String!) {
    googleLogin(access_token: $access_token, id_token: $id_token) {
      email
      isEmailVerified
      status
      type
      paymentMethod
      roles
    }
  }
`;
