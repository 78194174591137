import React from "react";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import { TableButtonsEnum } from "./table-buttons.enum";
import "./table-buttons.component.scss";

type TableButtonsType = {
  onButtonClicked: (action: TableButtonsEnum) => void;
  page: number;
  hasMore: boolean | undefined;
};
const TableButtons: React.FC<TableButtonsType> = ({
  onButtonClicked,
  page,
  hasMore,
}) => {
  return (
    <div className="table-buttons ">
      <ArrowBackIosOutlinedIcon
        color={page <= 1 ? "disabled" : "inherit"}
        sx={{ cursor: page > 1 ? "pointer" : "default" }}
        onClick={() => onButtonClicked(TableButtonsEnum.PREVIOUS)}
      />
      <ArrowForwardIosOutlinedIcon
        color={hasMore ? "inherit" : "disabled"}
        sx={{ cursor: hasMore ? "pointer" : "default" }}
        onClick={() => onButtonClicked(TableButtonsEnum.NEXT)}
      />
    </div>
  );
};

export default TableButtons;
