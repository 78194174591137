import { useMutation } from '@apollo/client';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { EDIT_EMAIL_MARKETING } from '../../graphql/queries/email-marketing.query';
import Alert from '../alert/alert.component';

type EmailMarketingType = {
    isEmailMarketingAllowed: boolean
}
const EmailMarketing: React.FC<EmailMarketingType> = ({ isEmailMarketingAllowed }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState<string | undefined>("");

    useEffect(() => {
        if (isEmailMarketingAllowed === undefined) return;
        setIsChecked(isEmailMarketingAllowed);
    }, [isEmailMarketingAllowed]);

    const [editMarketingEmails, { loading, error: editMarketingEmailsError }] = useMutation(EDIT_EMAIL_MARKETING);

    useEffect(() => {
        openAlert(editMarketingEmailsError?.message)
    }, [editMarketingEmailsError])

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
        executeMutation(event.target.checked);
    };

    const executeMutation = async (allowReceiveMarketingEmails: boolean) => {
        try {
            await editMarketingEmails({
                variables: {
                    allowReceiveMarketingEmails
                }
            })
        } catch (err: any) {
            openAlert(err?.message);
        }
    }

    const openAlert = (error: any) => {
        if (!error) return;
        setError(error);
        setOpen(true);
    };

    const closeAlert = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    return <>
        <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    value="end"
                    control={<Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        disabled={loading}
                    />}
                    label="Receive Marketing Emails"
                    labelPlacement="end"
                />
            </FormGroup>
        </FormControl>
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={closeAlert}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
                {error}
            </Alert>
        </Snackbar>
    </>
};

export default EmailMarketing;