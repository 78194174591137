import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import HomeFooter from "../home-footer/home-footer.component";

const Footer = () => {
  const isHomeHeader = useSelector<
    RootState,
    RootState["other"]["isHomeHeader"]
  >((state) => {
    return state.other.isHomeHeader;
  });
  const footer = isHomeHeader ? (
    <HomeFooter />
  ) : (
    <div>
      <div id="footerback">
        <div className="container">
          {/* <div className="row"> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="pull-left footer-txt">
              © Signature Guarantee Group. All rights reserved.
              <span className="footer_back_links">
                <a rel="canonical" href="/privacy">
                  Privacy policy
                </a>{" "}
                |{" "}
                <a rel="canonical" href="/terms">
                  Terms of use
                </a>
              </span>
            </div>
            <div className="pull-right footer-link">
              <a rel="canonical" href="/glossary">
                Glossary of terms
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return <>{footer}</>;
};

export default Footer;
