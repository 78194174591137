import { gql } from "@apollo/client";

export const CONTACT_US = gql`
  mutation contactUs(
    $name: String!
    $email: String!
    $phone: String!
    $message: String!
    $recaptchaTokenResponse: String!
  ) {
    contactUs(
      recaptchaTokenResponse: $recaptchaTokenResponse
      input: { name: $name, email: $email, phone: $phone, message: $message }
    )
  }
`;
