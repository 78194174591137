import React from "react";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import {
  getShippingMethodName,
  sumTransactionTotalCost,
} from "../checkout/checkout.helpers";
import { GET_TRANSACTION } from "../../graphql/queries/transaction/get-transaction.query";
import { RootState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import MedallionStampAmount from "./medallion-stamp-amount.component";
import { useLocation } from "react-router-dom";

const containerStyle = {
  minWidth: 400,
  backgroundColor: "#f5f5f5",
  padding: "20px 30px",
  marginTop: 20,
  lineHeight: 1.52857143,
  height: "fit-content",
};

const titleStyle = {
  color: "#0c234c",
  fontSize: 18,
  fontFamily: "CerebriSans-SemiBold",
  paddingBottom: 15,
  paddingTop: 15,
};

const lineStyle = {
  backgroundColor: "#ddd",
  height: 1,
  width: "100%",
  marginTop: 5,
  marginBottom: 15,
};

const CheckoutSideBox = () => {
  const transactionUUID = useSelector<
    RootState,
    RootState["transaction"]["transactionUUID"]
  >((state) => {
    return state.transaction.transactionUUID;
  });
  let location = useLocation();

  const { data: userData } = useQuery(GET_USER);

  const { data: transactionData } = useQuery(GET_TRANSACTION, {
    variables: {
      transactionUUID: location.pathname?.includes('/completed/') ? location.pathname?.split('/completed/')[1] : transactionUUID,
    },
    fetchPolicy: "no-cache",
  });

  const address = userData?.getUser?.home;

  return (
    <div className="side-box" style={containerStyle}>
      <div>
        <h4 style={titleStyle}>Order Summary</h4>
        <span>{`${userData?.getUser?.firstName} ${userData?.getUser?.lastName}`}</span>
        <span
          style={{ marginBottom: 15, display: "block" }}
        >{`${address?.addressLine1}, ${address?.state}, ${address?.zip}`}</span>

        <div style={lineStyle}></div>
      </div>
      <div style={{ marginBottom: 5 }}>
        <MedallionStampAmount />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: 30,
          marginBottom: 5,
        }}
      >
        <div>Shipping cost</div>
        <div>
          <span>
            {getShippingMethodName(
              transactionData?.getTransaction?.deliveryMethod
            )}
          </span>{" "}
          $
          {(
            transactionData?.getTransaction?.charge?.totalShippingCostInCents /
            100
          ).toFixed(2)}
        </div>
      </div>

      {transactionData?.getTransaction?.discounts?.length
        ? transactionData?.getTransaction?.discounts?.map((discount: any) => (
          <div className="checkout__item-box">
            <span>
              Promo code
              <span className="checkout__code-label">{discount?.code}</span>
            </span>
          </div>
        ))
        : ""}
      {transactionData?.getTransaction?.discounts?.length
        ? <div>
          <hr />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Total Discount</span>
            <span>${(
              transactionData?.getTransaction?.charge
                ?.totalDiscountsInCents / 100
            ).toFixed(2)}</span>
          </div>
        </div>
        : ""}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: 30,
          marginBottom: 5,
        }}
      >
        <span>Return shipping</span>
        <span>Free</span>
      </div>
      <div style={lineStyle}></div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: 30,
        }}
      >
        <span>Order total</span>
        <span>
          $
          {sumTransactionTotalCost(
            transactionData,
            transactionData?.getTransaction?.charge?.totalShippingCostInCents /
            100
          )}
        </span>
      </div>
    </div>
  );
};

export default CheckoutSideBox;
