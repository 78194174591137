import React from "react";

const MedallionSignature = () => {
  return (
    <div className="col-md-12 pad content">
      <h1 className="text-center txt">
        Getting a medallion signature guarantee after the death of a parent or
        spouse
      </h1>
      <div className="line"></div>
      <p>
        Very often&nbsp;our eSignatureGuarantee office will get&nbsp;calls from
        individuals who may have just lost a parent or from someone whose spouse
        just passed away and they are looking for direction and guidance on how
        to address the ownership of stock after that person who owned the stock
        has passed away. Did they have a will?
      </p>
      <p>
        When a person passes away, the transfer of stock ownership will depend
        on the provisions made by the deceased before their passing. If a
        married person who held stocks jointly with a spouse dies, then the
        surviving spouse typically becomes the sole owner of those stocks.
        However, the process is different if the decedent held stocks in his or
        her own name.
      </p>
      <p>
        If a person who holds stocks passes away without having a will to name a
        beneficiary, then usually a probate process is initiated which is the
        formal legal process for addressing a deceased person’s
        estate.&nbsp;&nbsp; The court will then decide who gets the shares in
        question and then directs the executor of the estate to transfer
        ownership accordingly.&nbsp;&nbsp; The executor will then compile
        selected documents to be sent to the transfer agent that handles the
        particular issuer of those securities. Typically the documents required
        by the transfer agent will include a (1) Letters Testamentary or if the
        deceased did not have a Will, but had a Trust, they would need the trust
        instrument portion that appoints the administrator/trustee to the
        decedent’s property; (2) Certified Copy of the Death Certificate; (3)
        Copy of Executor’s driver’s license and (4)&nbsp;a copy of the transfer
        instruction forms.
      </p>
      <p>
        The transfer agent will then coordinate and complete the transfer of
        ownership.
      </p>
      <p style={{ marginBottom: 50 }}>
        <strong>
          So where does the medallion guarantee requirement come into play?
        </strong>
        Since the current rightful shareholder is deceased, the transfer agent
        would need a medallion guarantee to verify the signature of the Executor
        or Trustee. At a time when the last thing you want to be doing is
        additional paperwork or calling banks to inquire of their services, we
        would suggest that you invite the Executor or Trustee
        to&nbsp;www.eSignatureGuarantee.com to obtain a medallion signature
        guarantee. Wishing you only happy occasions so that you should never
        need a medallion signature guarantee under such circumstances. . .
      </p>
    </div>
  );
};

export default MedallionSignature;
