import { FormControlLabel, Radio, RadioGroup, Snackbar } from "@mui/material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { RootState } from "../../redux/reducers";
import { GET_FEDEX_SERVICES } from "../../graphql/queries/shipping/get-fedex-services.query";
import { LIST_ADDRESSES } from "../../graphql/queries/addresses/list-addresses.query";
import { GET_FEDEX_RATE } from "../../graphql/queries/shipping/get-fedex-rate.query";
import Loader from "../loader/loader.component";
import {
  getDefaultShippingMapper,
  deliveryMethods,
  isFedexServiceSelected,
  selfShippingOptions,
  sumTransactionTotalCost,
  shippingMapper,
} from "./checkout.helpers";
import { GET_TRANSACTION } from "../../graphql/queries/transaction/get-transaction.query";
import AddAddress from "../add-address/add-address.component";
import { ADD_DISCOUNT_CODE } from "../../graphql/mutations/transaction/add-discount-code.mutation";
import { CREATE_SHIPPING } from "../../graphql/mutations/shipping/create-shipping.mutation";
import { useNavigate } from "react-router-dom";
import { isHomeHeader } from "../../redux/actions";
import Alert from "../alert/alert.component";
import { ADD_NOTE } from "../../graphql/mutations/transaction/add-note.mutation";

import "./checkout.scss";
import Notes from "../notes/notes.component";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import { EDIT_DELIVERY_METHOD } from "../../graphql/mutations/shipping/edit-delivery-method.mutation";
import MedallionStampAmount from "../checkout-side-box/medallion-stamp-amount.component";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 560,
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const titleStyle = {
  color: "#0c234c",
  fontSize: 18,
  fontFamily: "CerebriSans-SemiBold",
  paddingBottom: 15,
  paddingTop: 15,
};

const Checkout = () => {
  const [shippingCost, setShippingCost] = useState<number>(0);
  const [promoCode, setPromoCode] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [selfShippingSelected, setSelfShippingSelected] = useState(false);
  const [methodDelivery, setMethodDelivery] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const [error, setError] = React.useState<string | undefined>("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const openAlert = (error: any, success = false) => {
    setError(error);
    setIsSuccess(success);
    setAlertOpen(true);
  };
  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();


  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transactionUUID = useSelector<
    RootState,
    RootState["transaction"]["transactionUUID"]
  >((state) => {
    return state.transaction.transactionUUID;
  }) as string;

  // TODO - extract to shared util
  const guardPage = () => {
    if (!transactionUUID) {
      navigate("/home", { replace: true });
      return false;
    }
    return true;
  };

  const [fetchTransaction, {
    loading: transactionLoader,
    data: transactionData,
    error: getTransactionError,
  }] = useLazyQuery(GET_TRANSACTION, {
    variables: {
      transactionUUID: transactionUUID,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const deliveryMethod = data?.getTransaction?.deliveryMethod;
      if (deliveryMethod) {
        setMethodDelivery(deliveryMethod);
        if (deliveryMethod === deliveryMethods[1]) {
          setSelfShippingSelected(true)
        }
      } else {
        setMethodDelivery("OWN");
      }
    },
  });

  useEffect(() => {
    fetchTransaction();
  }, [])

  const { data: userData } = useQuery(GET_USER);
  const [shippingAddress, setShippingAddress] = useState<string>(
    transactionData?.getTransaction?.shippings[0]?.from?.uuid ||
    userData?.getUser?.home?.uuid
  );

  const [shippingMethod, setShippingMethod] = useState<string>("");

  React.useEffect(() => {
    if (shippingMethod === selfShippingOptions[1]) {
      setSelfShippingSelected(true)
    }
  }, [shippingMethod])

  const {
    loading: getFedexServiceLoading,
    data: getFedexServiceData,
    error: getFedexServiceError,
  } = useQuery(GET_FEDEX_SERVICES, {
    onCompleted: (data: any) => {
      setShippingMethod(
        getDefaultShippingMapper(
          transactionData?.getTransaction?.deliveryMethod,
          data?.getFedexServices
        )
      );
    },
  });

  React.useEffect(() => {
    if (!methodDelivery || methodDelivery === undefined) return;
    editDeliveryMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodDelivery]);

  // make sure to redirect the user in case no active transaction
  React.useEffect(() => {
    guardPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionUUID, navigate]);

  const [
    getShippingRate,
    { loading: getFedexRateLoading, error: getFedexRateError },
  ] = useLazyQuery(GET_FEDEX_RATE);

  const {
    loading: listAddressesLoading,
    data: listAddressesData,
    error: listAddressesError,
  } = useQuery(LIST_ADDRESSES);

  const [addPromoCodeFunc, { loading: promoLoader, error: promoError }] =
    useMutation(ADD_DISCOUNT_CODE, {
      variables: {
        transactionUUID: transactionUUID,
        discountCode: promoCode,
      },
      refetchQueries: [
        {
          query: GET_TRANSACTION,
          variables: { transactionUUID: transactionUUID },
        },
      ],
    });

  const [
    editDeliveryMethodFunc,
    { loading: editDeliveryMethodLoader, error: editDeliveryMethodError },
  ] = useMutation(EDIT_DELIVERY_METHOD, {
    variables: {
      transactionUUID: transactionUUID,
      deliveryMethod: methodDelivery,
    },
    refetchQueries: [
      {
        query: GET_TRANSACTION,
        variables: { transactionUUID: transactionUUID },
      },
    ],
    onCompleted: (data: any) => {
      if (data?.editTransaction?.deliveryMethod) {
        setShippingMethod(data?.editTransaction?.deliveryMethod);
      }
    },
  });

  const editDeliveryMethod = async () => {
    try {
      editDeliveryMethodFunc();
    } catch (err) { }
  };

  const [
    createShippingFunc,
    { loading: createShippingLoader, error: createShippingError },
  ] = useMutation(CREATE_SHIPPING, {
    variables: !isFedexServiceSelected(shippingMethod)
      ? {
        transactionUUID: transactionUUID,
        addressUUID: shippingAddress,
        fedexServiceUUID: getFedexServiceData?.getFedexServices?.find(
          (s: any) => s.fedexEnumCode === shippingMethod
        )?.uuid,
        shippingProvider: "FEDEX",
      }
      : {
        transactionUUID: transactionUUID,
        addressUUID: shippingAddress,
        shippingProvider: "OWN",
      },
  });

  const [addNote, { loading: addNoteLoader, error: addNoteError }] =
    useMutation(ADD_NOTE, {
      variables: {
        transactionUUID: transactionUUID,
        content: note,
      },
    });

  React.useEffect(() => {
    if (promoError?.message) {
      openAlert(promoError?.message);
      return;
    }
  }, [
    promoError,
  ]);

  React.useEffect(() => {
    if (createShippingError?.message) {
      openAlert(createShippingError?.message);
      return;
    }
  }, [
    createShippingError,
  ]);

  React.useEffect(() => {
    if (getFedexServiceError?.message) {
      openAlert(getFedexServiceError?.message);
      return;
    }
  }, [
    getFedexServiceError,
  ]);

  React.useEffect(() => {
    if (listAddressesError?.message) {
      openAlert(listAddressesError?.message);
      return;
    }
  }, [
    listAddressesError,
  ]);

  React.useEffect(() => {
    if (getFedexRateError?.message) {
      setLoader(false);
      setShippingCost(0);
      openAlert(getFedexRateError?.message);
      return;
    }
  }, [
    getFedexRateError,
  ]);

  React.useEffect(() => {
    if (getTransactionError?.message) {
      openAlert(getTransactionError?.message);
      return;
    }
  }, [
    getTransactionError,
  ]);

  React.useEffect(() => {
    if (addNoteError?.message) {
      openAlert(addNoteError?.message);
      return;
    }
  }, [
    addNoteError,
  ]);

  React.useEffect(() => {
    if (editDeliveryMethodError?.message) {
      openAlert(editDeliveryMethodError?.message);
      return;
    }
  }, [editDeliveryMethodError])

  const onSelectShippingAddress = async (addressUUID: string) => {
    // set shipping address
    setShippingAddress(addressUUID);
  };

  const onAddPromoCode = useCallback(async () => {
    if (!promoCode.trim()) return;
    try {
      await addPromoCodeFunc();
      await fetchTransaction();
      openAlert("Added Successfully", true);
    } catch (err) {
    }
  }, [addPromoCodeFunc, fetchTransaction, promoCode]);

  const onCreateShipping = async () => {
    if (!transactionUUID || !shippingAddress) return;
    try {
      if (note && shippingMethod !== "ELECTRONIC") {
        await addNote();
      }
      if (shippingMethod !== "ELECTRONIC") {
        await createShippingFunc();
      }
      navigate("/payment");
    } catch (err) { }
  };

  const manageAddressChoice = async () => {
    if (loader) return;
    if (!shippingAddress || !shippingMethod) return;

    if (isFedexServiceSelected(shippingMethod)) {
      return;
    }

    const fedexServiceUUID = getFedexServiceData?.getFedexServices?.find(
      (s: any) => s.fedexEnumCode === shippingMethod
    );

    if (!fedexServiceUUID) return;

    setLoader(true);
    const res = await getShippingRate({
      variables: {
        addressUUID: shippingAddress,
        fedexServiceUUID: fedexServiceUUID?.uuid,
      },
      fetchPolicy: "no-cache",
    });
    setShippingCost(res?.data?.getFedexRate?.totalNetFedExCharge);
    setLoader(false);
  };

  React.useEffect(() => {
    const fetchData = async () => await manageAddressChoice();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingMethod, shippingAddress]);

  const manageSelfShippingSelected = (e: any) => {
    if (e.target.name === selfShippingOptions[1]) {
      setSelfShippingSelected(true)
    } else {
      setSelfShippingSelected(false);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        marginBottom: 45,
      }}
    >
      <div className="pull-left box-left checkout">
        <h1 className="sub-title">Checkout: Shipping information</h1>
        <strong>YOUR RETURN SHIPPING IS FREE</strong>
        <h4>Supporting documents: shipping options</h4>
        <div>
          <div className="form-row radio-row shiping_method_select">
            <div className="form-group col-md-12">
              <label>
                Please select your preferred shipping option for sending your
                documents to us
              </label>
              {getFedexServiceLoading ? <Loader /> : ""}
              <RadioGroup
                defaultValue={shippingMethod}
                value={shippingMethod}
                onChange={(e) => {
                  if (loader) return;
                  setMethodDelivery(e.target.value)
                  manageSelfShippingSelected(e);
                  setShippingMethod(e.target.value);
                  setShippingCost(0);
                }}
                style={{ fontSize: 14 }}
              >
                {getFedexServiceData?.getFedexServices?.length &&
                  getFedexServiceData?.getFedexServices?.map(
                    (v: any, i: number) => {
                      let label = v && v?.name;
                      let value = v && v?.fedexEnumCode;
                      return (
                        <FormControlLabel
                          defaultValue={shippingMethod}
                          disabled={loader || editDeliveryMethodLoader || transactionLoader}
                          value={value}
                          control={<Radio size="small" />}
                          label={label}
                          key={i}
                        />
                      );
                    }
                  )}
                {getFedexServiceData?.getFedexServices?.length &&
                  selfShippingOptions?.map((v: string, i: number) => (
                    <FormControlLabel
                      disabled={loader || editDeliveryMethodLoader || transactionLoader}
                      value={deliveryMethods[i]}
                      control={<Radio size="small" />}
                      label={v}
                      key={v}
                      defaultValue={shippingMethod}
                      name={selfShippingOptions[i]}
                    />
                  ))}
              </RadioGroup>
            </div>
            <div className="line-center"></div>
          </div>

          <div className="shipping-address">
            <h4>Return address (return shipping is free of charge)</h4>
            {
              <RadioGroup
                style={{ fontSize: 14 }}
                value={shippingAddress}
                onChange={(e) => {
                  onSelectShippingAddress(e.target.value);
                }}
              >
                {listAddressesLoading ? <Loader /> : ""}
                {listAddressesData?.listAddresses?.length &&
                  listAddressesData?.listAddresses?.map((v: any, i: any) => {
                    let label = `${v?.addressLine1}, ${v?.city}, ${v?.state}, ${v?.zip}`;
                    let value = v?.uuid;
                    return (
                      <FormControlLabel
                        disabled={loader}
                        value={value}
                        control={<Radio size="small" />}
                        label={label}
                        key={i}
                      />
                    );
                  })}
              </RadioGroup>
            }

            <div className="different-address">
              <span className="add-address" onClick={handleOpen}>
                +
              </span>
              Ship to different address
            </div>
            {!shippingAddress && (
              <span className="input-error">
                <div className="fa-icon fa fa-exclamation-circle"></div>Return
                shipping address is required.
              </span>
            )}
            <div className="line-center"></div>
          </div>
          <div className="promo-code">
            <h4>Have a promo code? Enter it here.</h4>
            <div className="form-group promo-code-form">
              <input
                type="text"
                className="form-control"
                placeholder="Add promo code"
                id="promo_code"
                value={promoCode}
                onChange={(e) => {
                  setPromoCode(e.target.value);
                }}
              />

              <button
                className="btn btn-apply"
                onClick={onAddPromoCode}
                disabled={promoLoader || promoCode.trim() === ""}
              >
                {promoLoader ? <Loader size={20} /> : "Apply"}
              </button>
            </div>
            <div className="line-center"></div>
          </div>
          <div>
            <Notes notes={transactionData?.getTransaction?.notes} />
          </div>
          <div className="additional-notes">
            <h4>Additional notes / instructions (optional)</h4>
            <textarea
              className="form-control additional-notes-box"
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            ></textarea>
            <div className="form-group col-md-12 continue-to-payment">
              <button
                className="btn btn-continue"
                disabled={
                  !transactionUUID ||
                  !shippingAddress ||
                  !methodDelivery ||
                  loader ||
                  editDeliveryMethodLoader
                }
                type="button"
                onClick={() => {
                  onCreateShipping();
                }}
              >
                {addNoteLoader || createShippingLoader ? (
                  <Loader size={30} />
                ) : (
                  "Continue"
                )}
              </button>
            </div>
            <div className="code-note">
              You will receive a new notification code on your mobile phone,
              which you need to enter check out
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="pull-right box-right" style={{ height: "fit-content" }}>
          <div>
            <h4 style={titleStyle}>Order Summary</h4>
            <div style={{ marginBottom: 5 }}>
              <MedallionStampAmount />
            </div>
            <div className="checkout__item-box">
              <span>Shipping cost</span>
              <span
                style={{
                  display: "block",
                  float: "right",
                  maxWidth: 200,
                  textAlign: "right",
                }}
              >
                {getFedexRateLoading ||
                  loader ||
                  editDeliveryMethodLoader ||
                  transactionLoader ? (
                  <Loader size={15} />
                ) : (
                  <>
                    <b style={{ fontSize: 12 }}>{methodDelivery ? shippingMapper[methodDelivery] : ''}</b>
                    ${shippingCost?.toFixed(2)}
                  </>
                )}
              </span>
            </div>
            {transactionData?.getTransaction?.discounts?.length
              ? transactionData?.getTransaction?.discounts?.map(
                (discount: any, i: number) => (
                  <div className="checkout__item-box" key={i}>
                    <span>
                      Promo code
                      <span className="checkout__code-label">
                        {discount?.code}
                      </span>
                    </span>
                  </div>
                )
              )
              : ""}
            {transactionData?.getTransaction?.discounts?.length
              ? <div>
                <hr />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>Total Discount</span>
                  <span>${(
                    transactionData?.getTransaction?.charge
                      ?.totalDiscountsInCents / 100
                  ).toFixed(2)}</span>
                </div>
              </div>
              : ""}
            <div className="checkout__item-box">
              <span>Return shipping</span>
              <span className="item-price">FREE</span>
            </div>
            <div className="line-center"></div>
            <div className="checkout__item-box">
              <span>Order total</span>
              <span
                className="item-price"
                style={{ display: "flex", alignItems: "center" }}
              >
                {transactionLoader ? (
                  <Loader size={15} />
                ) : (
                  <span>
                    ${sumTransactionTotalCost(transactionData, shippingCost)}
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
        {selfShippingSelected &&
          <div style={{ marginTop: 50 }} className="box-right">
            I will be uploading my documents after completing checkout for this transaction.
          </div>}
      </div>
      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add new shipping address
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <AddAddress handleClose={handleClose} />
            </Typography>
          </Box>
        </Modal>
      </>
      <Snackbar
        open={alertOpen}
        autoHideDuration={2000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity={isSuccess ? "success" : "error"} sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Checkout;
