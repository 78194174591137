import React, { useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

import { GET_TRANSACTION } from "../../graphql/queries/transaction/get-transaction.query";
import DocumentManager from "./documents-manger/documents-manager.component";
import Loader from "../loader/loader.component";
import {
  transactionStockholder,
} from "../../constants/transaction.constants";
import {
  sumMedallionsTotalLength,
  sumTransactionTotalCost,
} from "../checkout/checkout.helpers";
import { useDispatch } from "react-redux";
import { isHomeHeader } from "../../redux/actions";
import { Box, Modal, Snackbar, Typography } from "@mui/material";
import Alert from "../alert/alert.component";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import { GE_COVER_LETTER_URL } from "../../graphql/queries/documents/get-cover-letter.query";
import { GET_FEDEX_LABEL } from "../../graphql/queries/shipping/get-defex-label.query";

const titleStyle = {
  fontSize: 15,
  color: "#555",
  fontFamily: "CerebriSans-SemiBold",
  marginTop: 20,
  marginBottom: 10,
};

export const thStyle = {
  color: "#999",
  fontFamily: "CerebriSans-SemiBold",
  paddingTop: 10,
  paddingBottom: 13,
  padding: 15,
  fontSize: 14,
  fontWeight: 400,
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 560,
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PostTransactionDetails = () => {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");
  const [printCoverLoader, setPrintCoverLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploadingDocsInProgress, setIsUploadingDocsInProgress] = useState(false);

  const handleClose = () => setIsModalOpen(false);

  const openAlert = (error: any) => {
    setError(error);
    setAlertOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    loading: transactionLoader,
    data: transactionData,
    error: transactionError,
  } = useQuery(GET_TRANSACTION, {
    variables: {
      transactionUUID: id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const isPendingDocs = data?.getTransaction?.documents?.some((doc: any) => doc?.status === "PENDING");
      const isUploadedDocs = data?.getTransaction?.documents?.some((doc: any) => doc?.status === "UPLOADED");
      setIsModalOpen(isPendingDocs);
      setIsUploadingDocsInProgress(isPendingDocs && isUploadedDocs);
    }
  });

  console.log('transactionData ', transactionData)

  useQuery(GET_USER, {
    fetchPolicy: "no-cache",
  });

  const breakLine = (
    <div
      style={{
        backgroundColor: "#ddd",
        height: 2,
        width: "100%",
        marginTop: 30,
      }}
    ></div>
  );

  React.useEffect(() => {
    if (transactionError?.message) {
      openAlert(transactionError?.message);
      navigate("/home", {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionError]);

  const onPrintCoverLetter = async () => {
    setPrintCoverLoader(true);
    await getCovverLetterFunc();
  };

  const onPrintFedexLabel = async () => {
    await getFedexLabel();
  };

  const [
    getCovverLetterFunc,
    { loading: getCoverLetterUrlLoader, error: gclError },
  ] = useLazyQuery(GE_COVER_LETTER_URL, {
    variables: {
      transactionUUID: id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      setPrintCoverLoader(false);
      if (data?.getCoverLetterUrl) {
        window.open(data?.getCoverLetterUrl, "_blank");
      }
    },
  });

  const [getFedexLabel, { loading: getFedexLabelLoader, error: gflError }] =
    useLazyQuery(GET_FEDEX_LABEL, {
      variables: {
        transactionUUID: id,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        if (data?.getFedexLabelUrl) {
          window.open(data?.getFedexLabelUrl, '_blank');
        }
      },
    });

  return (
    <>
      {transactionLoader ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader size={50} />
        </div>
      ) : (
        <div className="container">
          <div style={{ margin: "50px 0px" }}>
            <span style={{ display: "flex", color: "#bbb" }}>
              <ArrowBackIcon />
              <span
                style={{ fontFamily: "CerebriSans-Book", cursor: "pointer" }}
                onClick={() => {
                  navigate("/accounts/me");
                }}
              >
                Back to account overview
              </span>
            </span>

            {breakLine}

            <div
              style={{
                marginTop: 20,
                marginBottom: 10,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <h1
                style={{
                  fontFamily: "CerebriSans-Bold",
                  fontSize: 28,
                }}
              >
                Transaction: {transactionData?.getTransaction?.code}
              </h1>
              <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
                {transactionData?.getTransaction?.shippings[0]?.provider !==
                  "OWN" && (
                    <button
                      className={"btn btn-print"}
                      onClick={onPrintFedexLabel}
                    >
                      {getFedexLabelLoader ? <Loader size={20} /> : 'Print FedEx Label'}
                    </button>
                  )}
                <button
                  className={"btn btn-print"}
                  onClick={() => window.print()}
                >
                  Printable receipt
                </button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h3 style={titleStyle}>Transaction date</h3>
                <div style={{ fontFamily: "CerebriSans-Book" }}>
                  {moment(transactionData?.getTransaction?.createdAt).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </div>
                <h3 style={titleStyle}>Status</h3>
                <div style={{ fontFamily: "CerebriSans-Book" }}>
                  {transactionData?.getTransaction?.status}
                </div>
              </div>
              <div>
                <h3 style={titleStyle}>Ownership type</h3>
                <div style={{ fontFamily: "CerebriSans-Book" }}>
                  {
                    transactionStockholder[
                    transactionData?.getTransaction?.stockholderType
                    ]
                  }
                </div>
                <h3 style={titleStyle}>Security type</h3>
                <div style={{ fontFamily: "CerebriSans-Book" }}>
                  {transactionData?.getTransaction?.securityType}
                </div>
              </div>
              <div>
                <h3 style={titleStyle}>Return shipping address</h3>
                <div style={{ fontFamily: "CerebriSans-Book" }}>
                  <span style={{ display: "block" }}>
                    {
                      transactionData?.getTransaction?.shippings[0]?.from
                        ?.addressLine1
                    }
                  </span>
                  <span style={{ display: "block" }}>
                    {transactionData?.getTransaction?.shippings[0]?.from?.zip}
                  </span>
                  <span style={{ display: "block" }}>
                    {transactionData?.getTransaction?.shippings[0]?.from?.state}
                  </span>
                </div>
              </div>
              <div>
                <h3 style={titleStyle}>Transaction summary</h3>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <span>eSignature medallion stamp</span>
                  <span>{sumMedallionsTotalLength(transactionData)}x 149$</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    Shipping:{" "}
                    {transactionData?.getTransaction?.shippings[0]?.provider ===
                      "FEDEX"
                      ? "Fedex"
                      : "Own shipping"}
                  </span>
                  <span>
                    $
                    {transactionData?.getTransaction?.charge
                      ?.totalShippingCostInCents / 100}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    ...titleStyle,
                  }}
                >
                  <span>Order total</span>
                  <span>
                    $
                    {sumTransactionTotalCost(
                      transactionData,
                      transactionData?.getTransaction?.charge
                        ?.totalShippingCostInCents / 100
                    )}
                  </span>
                </div>
              </div>
            </div>

            {breakLine}

            <div style={{ marginTop: 50 }}>
              <h1
                style={{
                  fontFamily: "CerebriSans-Bold",
                  fontSize: 28,
                }}
              >
                Medallion information
              </h1>
              <div>
                <table
                  style={{ width: "100%" }}
                  cellPadding={0}
                  cellSpacing={0}
                >
                  <thead>
                    <tr style={{ borderBottom: "solid 1px #ddd", padding: 15 }}>
                      <th style={thStyle}>Stock Symbol</th>
                      <th style={thStyle}>Price Per Share</th>
                      <th style={thStyle}>No. of Shares</th>
                      <th style={thStyle}>Market Value</th>
                      <th style={thStyle}>Approved</th>
                      <th style={thStyle}>Pending</th>
                      <th style={thStyle}>Denied</th>
                      <th style={thStyle}>Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionData?.getTransaction?.certificates?.map(
                      (c: any, i: number) =>
                        <tr style={{ borderBottom: "solid 1px #ddd" }} key={i}>
                          <td style={{ padding: 15 }}>{c.symbol}</td>
                          <td style={{ padding: 15 }}>${c.price}</td>
                          <td style={{ padding: 15 }}>{c.shares}</td>
                          <td style={{ padding: 15 }}>
                            ${c.marketValueTotal}
                          </td>
                          <td style={{ padding: 15 }}>
                            {c?.approvedMedallionsCount}
                          </td>
                          <td style={{ padding: 15 }}>
                            {c?.pendingMedallionsCount}
                          </td>
                          <td style={{ padding: 15 }}>
                            {c?.deniedMedallionsCount}
                          </td>
                          <td style={{ padding: 15 }}>
                            ${c?.medallionsCount * 149}
                          </td>
                        </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{ marginTop: 50 }}>
              {transactionLoader ? (
                <Loader size={30} />
              ) : (
                <DocumentManager transactionData={transactionData} />
              )}
            </div>

            <Snackbar
              open={alertOpen}
              autoHideDuration={6000}
              onClose={closeAlert}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={closeAlert}
                severity="error"
                sx={{ width: "100%" }}
              >
                {error}
              </Alert>
            </Snackbar>
            <Modal
              open={isModalOpen}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div style={{ display: "flex", flexDirection: "column", gap: 25, alignItems: "center" }}>
                    <CheckCircleOutlinedIcon style={{ fontSize: 80, color: "green" }} />
                    <span style={{
                      fontFamily: "CerebriSans-Bold",
                      fontSize: 28,
                    }}>Transaction Completed!</span>
                    <span style={{
                      fontFamily: "CerebriSans-Bold",
                      fontSize: 18,
                    }}>Please {isUploadingDocsInProgress ? 'continue' : 'begin'} uploading your documents.</span>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default PostTransactionDetails;
