/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const MARK_AS_SUSPICIOUS = gql`
  mutation markAsSuspicious(
    $transactionUUID: String!
  ) {
    markAsSuspicious(
      transactionUUID: $transactionUUID
    ) {
      uuid
    }
  }
`;