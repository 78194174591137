import { Snackbar, Tooltip } from "@mui/material";
import React, { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import "./certificate-ira.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";

import { RootState } from "../../redux/reducers";
import { ADD_IRA_CEURITIES } from "../../graphql/mutations/transaction/add-ira-securities.mutation";
import { GET_TRANSACTION } from "../../graphql/queries/transaction/get-transaction.query";
import Alert from "../alert/alert.component";
import Loader from "../loader/loader.component";

const errorsStyle = { color: "#d43f00", fontSize: 12 };

const CertificateIra = () => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");
  const [navigateToNextPage, setNavigateToNextPage] = useState("");

  const openAlert = (error: any) => {
    setError(error);
    setOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const transaction = useSelector<RootState, RootState["transaction"]>(
    (state) => {
      return state.transaction;
    }
  );

  const navigate = useNavigate();

  const [securietiesList, setSecuritiesList] = useState("");
  const [accountValue, setAccountValue] = useState(0);

  const onTextareaChanged = (e: any) => {
    setSecuritiesList(e.target.value);
  };

  const onInputChanged = (e: any) => {
    setAccountValue(e.target.value);
  };

  const [
    getTransactionFunc,
    { loading: transactionLoader, data: transactionData },
  ] = useLazyQuery(GET_TRANSACTION, {
    variables: {
      transactionUUID: transaction?.transactionUUID,
    },
  });

  React.useEffect(() => {
    getTransactionFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const certificate = transactionData?.getTransaction?.certificates[0];
    if (certificate) {
      setSecuritiesList(certificate?.symbol);
      setAccountValue(certificate?.marketValueTotal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionData]);

  const redirectTo = () => {
    if (navigateToNextPage === "yes") {
      navigate("/transactions/create/checkout", { replace: true });
    } else {
      navigate("/accounts/me", {
        replace: true,
      });
    }
  };

  const [
    addIRACecuritiesFunc,
    { loading: addIRACecuritiesLoader, error: addIRACecuritiesError },
  ] = useMutation(ADD_IRA_CEURITIES, {
    variables: {
      transactionUUID: transaction?.transactionUUID,
      securities: securietiesList,
      accountValue: +accountValue,
    },
    // onCompleted: () => redirectTo(),
    refetchQueries: [
      {
        query: GET_TRANSACTION,
        variables: { transactionUUID: transaction?.transactionUUID },
      },
    ],
  });

  React.useEffect(() => {
    if (addIRACecuritiesError?.message) {
      openAlert(addIRACecuritiesError?.message);
    }
  }, [addIRACecuritiesError]);

  // TODO - extract to shared util
  const guardPage = () => {
    if (!transaction?.transactionUUID) {
      navigate("/transactions/create/start", { replace: true });
      return false;
    }
    return true;
  };

  // make sure to redirect the user in case no active transaction
  React.useEffect(() => {
    guardPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction?.transactionUUID, navigate]);

  const onContinue = async (value: string) => {
    if (securietiesList.trim() === "" || accountValue <= 0) {
      return;
    }
    try {
      await addIRACecuritiesFunc();
      setNavigateToNextPage(value);
    } catch (err) {}
  };

  React.useEffect(() => {
    if (navigateToNextPage === "") return;
    redirectTo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateToNextPage]);

  return (
    <>
      {transactionLoader ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div className="pull-left box-left securities">
              <div className="securities_section pull-left">
                <h1 className="sub-title">Medallion details</h1>
                <p>
                  List the securities that you would like medallions for in the
                  top field, and the value of those accounts in the Account
                  value field
                </p>
                <div className="line-center"></div>
              </div>

              <div className="pull-left">
                <form>
                  <div className="col-md-12 securities-form form-row">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <span className="securities-label">
                        List the securities that require medallion stamps:
                      </span>
                      <div>
                        <Tooltip
                          title="Example: Privately held stock in League of Legends team,
                  401K, US Treasury Bonds"
                          placement="top"
                          className="securities-info"
                        >
                          <InfoOutlinedIcon />
                        </Tooltip>
                      </div>
                    </div>

                    <div className="form-group securities-list-text">
                      <textarea
                        value={securietiesList}
                        onChange={onTextareaChanged}
                        className={`form-control  securities-list ${
                          securietiesList.trim() === "" ? "error" : ""
                        }`}
                      ></textarea>
                      {securietiesList.trim() === "" && (
                        <span className="input-error">
                          <div className="fa-icon fa fa-exclamation-circle"></div>
                          <p style={errorsStyle}>Field is required!</p>
                        </span>
                      )}
                    </div>
                    <div className="form-group account-value-input">
                      <label>Account value</label>
                      <input
                        value={accountValue}
                        onChange={onInputChanged}
                        type="number"
                        className={`form-control ${
                          accountValue <= 0 ? "error" : ""
                        }`}
                        min={0}
                      />
                      {accountValue <= 0 && (
                        <span className="input-error">
                          <div className="fa-icon fa fa-exclamation-circle"></div>
                          <p style={errorsStyle}>Field is required!</p>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="action-buttons col-md-12">
                    {addIRACecuritiesLoader ? (
                      <Loader size={30} />
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-save"
                          onClick={() => onContinue("no")}
                        >
                          Save and go to overview
                        </button>
                        <button
                          className="btn btn-continue"
                          type="button"
                          onClick={() => onContinue("yes")}
                        >
                          Continue
                        </button>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div
              className="pull-right box-right securities"
              style={{ maxHeight: 500 }}
            >
              <h4>Account value info</h4>
              <p className="bold">
                It's important that you tell us the value of your securities, so
                we can ensure that you receive the right number of medallion
                stamps. Transfer agents require medallion stamps for every
                $500,000 of value in your securities.
              </p>
              <p className="info">
                Need more info?{" "}
                <a rel="canonical" href="/glossary">
                  <u>View our glossary of terms</u>
                </a>{" "}
                »
              </p>
            </div>
          </div>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={closeAlert}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
              {error}
            </Alert>
          </Snackbar>
        </React.Fragment>
      )}
    </>
  );
};

export default CertificateIra;
