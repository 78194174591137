/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const ADD_CERTIFICATE = gql`
  mutation addCertificate(
    $transactionUUID: String!
    $price: Float!
    $shares: Int!
    $symbol: String!
  ) {
    addCertificate(
      transactionUUID: $transactionUUID
      input: { symbol: $symbol, price: $price, shares: $shares }
    ) {
      uuid
      symbol
      price
      shares
      marketValueTotal
      medallionsCount
    }
  }
`;
