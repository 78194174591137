import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Loader from "../loader/loader.component";
import { ADD_ADDRESS_TO_LIST } from "../../graphql/mutations/shipping/add-address.mutation";
import { LIST_ADDRESSES } from "../../graphql/queries/addresses/list-addresses.query";
import { Snackbar } from "@mui/material";
import Alert from "../alert/alert.component";

const labelStyle = {
  fontWeight: 400,
  color: "#6d6d6d",
  fontSize: 14,
  marginBottom: 5,
};

const inputStyle = {
  height: 40,
  borderRadius: 0,
  width: 200,
  padding: "6px 12px",
  border: "1px solid #ccc",
};

const containerStyle = {
  marginTop: 10,
  marginBottom: 15,
};

export const btnStyle = {
  background: "#28dab6",
  color: "#fff",
  borderRadius: 2,
  transition: ".3s ease-in-out",
  padding: "0 25px",
  marginTop: 15,
  width: "100%",
  fontFamily: "CerebriSans-SemiBold",
  fontSize: 17,
  height: 45,
  cursor: "pointer",
};

type AddAddressComponentType = {
  handleClose: () => void;
};

const AddAddress: React.FC<AddAddressComponentType> = ({ handleClose }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");

  const openAlert = (error: any) => {
    setError(error);
    setOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [formValues, setFormValues] = useState({
    addressLine1: "",
    city: "",
    state: "",
    zip: "",
  });

  const onInputChanged = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const [addAddressFunc, { loading, error: addAddressError }] = useMutation(
    ADD_ADDRESS_TO_LIST,
    {
      variables: {
        addressLine1: formValues.addressLine1,
        city: formValues.city,
        state: formValues.state,
        zip: formValues.zip,
      },
      refetchQueries: [
        {
          query: LIST_ADDRESSES,
        },
      ],
      onCompleted: () => handleClose(),
    }
  );

  React.useEffect(() => {
    if (addAddressError?.message) {
      openAlert(addAddressError?.message);
    }
  }, [addAddressError]);

  const onSubmit = async () => {
    if (
      formValues.addressLine1.trim() === "" ||
      formValues.city.trim() === "" ||
      formValues.state.trim() === "" ||
      formValues.zip.trim() === ""
    )
      return;
    try {
      addAddressFunc();
    } catch (err) {}
  };

  return (
    <>
      <form
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            ...containerStyle,
          }}
        >
          <label style={labelStyle}>Address line 1</label>
          <input
            style={inputStyle}
            name="addressLine1"
            value={formValues.addressLine1}
            onChange={(e) => onInputChanged(e)}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            ...containerStyle,
          }}
        >
          <label style={labelStyle}>City</label>
          <input
            style={inputStyle}
            name="city"
            value={formValues.city}
            onChange={(e) => onInputChanged(e)}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            ...containerStyle,
          }}
        >
          <label style={labelStyle}>State</label>
          <input
            style={inputStyle}
            name="state"
            value={formValues.state}
            onChange={(e) => onInputChanged(e)}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            ...containerStyle,
          }}
        >
          <label style={labelStyle}>Zip code</label>
          <input
            name="zip"
            value={formValues.zip}
            onChange={(e) => onInputChanged(e)}
            style={inputStyle}
          />
        </div>

        {loading ? (
          <div style={btnStyle}>
            <Loader />
          </div>
        ) : (
          <button style={btnStyle} onClick={onSubmit}>
            Continue
          </button>
        )}
      </form>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddAddress;
