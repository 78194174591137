import { useQuery } from "@apollo/client";
import React from "react";
import { useSelector } from "react-redux";
import { GET_TRANSACTION } from "../../graphql/queries/transaction/get-transaction.query";
import { RootState } from "../../redux/reducers";
import { sumMedallionsTotalLength } from "../checkout/checkout.helpers";
import Loader from "../loader/loader.component";

const MedallionStampAmount = () => {
  const transactionUUID = useSelector<
    RootState,
    RootState["transaction"]["transactionUUID"]
  >((state: RootState) => {
    return state.transaction.transactionUUID;
  });

  const { loading: transactionLoader, data: transactionData } = useQuery(
    GET_TRANSACTION,
    {
      variables: {
        transactionUUID: transactionUUID,
      },
      fetchPolicy: "no-cache",
    }
  );

  return (
    <div
      style={{ display: "flex", justifyContent: "space-between", height: 30 }}
    >
      <span>eSignature medallion stamp</span>
      <span>
        {transactionLoader ? (
          <div style={{ flex: 1 }}>
            <Loader size={15} />
          </div>
        ) : (
          <span style={{ flex: 1 }}>
            {sumMedallionsTotalLength(transactionData)}x $149
          </span>
        )}
      </span>
    </div>
  );
};

export default MedallionStampAmount;
