import React from "react";

import "./glossary.scss";

const Glossary = () => {
  return (
    <div className="container" style={{ marginTop: 50 }}>
      <div className="col-md-8 glossary">
        <h1
          style={{
            fontFamily: "CerebriSans-Bold",
            fontSize: 28,
            color: "#0c234c",
          }}
        >
          Glossary of Terms
        </h1>
        <div className="item glossary__first">
          <div className="term">Account statement</div>
          <div className="info">
            A summary of account holdings from your bank, brokerage firm, fund
            administrator or transfer agent (within the last 6 months). This
            shows the account value and formal name on the account.
          </div>
        </div>
        <div className="line-center"></div>
        <div className="item">
          <div className="term">Digital signature</div>
          <div className="info">
            An electronic signature that encrypts documents with digital codes,
            making it difficult to duplicate.
          </div>
        </div>
        <div className="line-center"></div>
        <div className="item">
          <div className="term">
            <a rel="canonical" href="/glossary">
              Estate administration
            </a>
          </div>
          <div className="info">
            Management of an estate for someone who died without leaving a legal
            will. This is typically done under court supervision.
          </div>
        </div>
        <div className="line-center"></div>
        <div className="item">
          <div className="term">Medallion signature</div>
          <div className="info">
            A special guarantee that the signature is genuine, where the
            institution that provided the medallion signature accepts liability
            for any forgery. Used for the transfer of securities.
          </div>
        </div>
        <div className="line-center"></div>
        <div className="item">
          <div className="term">Non-public</div>
          <div className="info">
            Share price is not listed on a public exchange.
          </div>
        </div>
        <div className="line-center"></div>
        <div className="item">
          <div className="term">Price per share</div>
          <div className="info">The value of a single share of stock.</div>
        </div>
        <div className="line-center"></div>
        <div className="item">
          <div className="term">Probate</div>
          <div className="info">
            The process of determining the validity of a will.
          </div>
        </div>
        <div className="line-center"></div>
        <div className="item">
          <div className="term">Publicly traded</div>
          <div className="info">
            A stock that is traded on a public stock exchange, which has a
            listed share price, and can be purchased by anyone.
          </div>
        </div>
        <div className="line-center"></div>
        <div className="item">
          <div className="term">
            <a rel="canonical" href="/glossary">
              Small estate affidavit
            </a>
          </div>
          <div className="info">
            Accepted only in certain states and is used when people die who have
            a small estate and no will, it fast-tracks the resolution of the
            estate through probate court.
          </div>
        </div>
        <div className="line-center"></div>
        <div className="item">
          <div className="term">
            Statement of
            <br /> ownership
          </div>
          <div className="info">
            A certificate that states the owner of securities. This is
            frequently a physical stock certificate, but can be digital as well.
          </div>
        </div>
        <div className="line-center"></div>
        <div className="item">
          <div className="term">
            <a rel="canonical" href="/glossary">
              Stock power
            </a>
          </div>
          <div className="info">
            A form that transfers ownership of stocks to a new owner. It
            requires a signature guarantee.
          </div>
        </div>
        <div className="line-center"></div>
        <div className="item">
          class=
          <div className="term">Ticker symbol</div>
          <div className="info">
            A unique abbreviation used to identify public companies.
          </div>
        </div>
        <div className="line-center"></div>
        <div className="item">
          <div className="term">
            <a rel="canonical" href="/glossary">
              Transfer agent
            </a>
          </div>
          <div className="info">
            Manages ownership of company stock, by adding new shareholder names
            and removing former shareholder names from the master shareholder
            listing.
          </div>
        </div>
        <div className="line-center"></div>
        <div className="item last">
          <div className="term">Transfer form</div>
          <div className="info">
            Similar to a stock power, it states who is transferring shares to
            whom. It requires a medallion stamp.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Glossary;
