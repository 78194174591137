import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeSectionDescription from "../home-section-description/home-section-description.component";
import { contentData, faqDesc, sideBoxContent } from "./faq.helper";

export default function FAQ() {
  const [current, setCurrent] = React.useState(0);
  return (
    <div className="container">
      <div className="about">
        <HomeSectionDescription
          title={faqDesc.title}
          description={faqDesc.desc}
        ></HomeSectionDescription>

        <div style={{ display: "flex" }}>
          <div className="col-md-4">
            <div
              className="col-md-11"
              style={{
                height: "auto",
                backgroundColor: "#f5f5f5",
                padding: "10px 30px 15px",
                lineHeight: 27,
              }}
            >
              {sideBoxContent.map((text: string, i: number) => (
                <h4
                  key={i}
                  style={{
                    cursor: "pointer",
                    color: current === i ? "#959595" : "#353535",
                    fontFamily: "CerebriSans-SemiBold",
                    fontSize: 15,
                    paddingTop: 10,
                  }}
                  onClick={() => setCurrent(i)}
                >
                  {text}
                </h4>
              ))}
            </div>
            <div
              className="col-md-11"
              style={{
                fontSize: 15,
                color: "#a8a8a8",
                fontFamily: "CerebriSans-Light",
                marginTop: 20,
              }}
            >
              If you've come across some unfamiliar financial terms, get up to
              speed with our{" "}
              <a rel="canonical" href="/glossary">
                Glossary of Terms
              </a>{" "}
              page
            </div>
          </div>
          <div className="col-md-8 faq-content">
            <div>
              <div
                style={{
                  fontSize: 25,
                  fontFamily: "CerebriSans-SemiBold",
                  color: "#262626",
                  borderBottom: "2px solid #cbcbcb",
                  paddingBottom: 15,
                  marginBottom: 25,
                }}
              >
                {sideBoxContent[current]}
              </div>
            </div>
            {contentData[current].map((elemnts: any, i: number) => (
              <div key={i} style={{ margin: "15px 0px" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#28dab6" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontFamily: "CerebriSans-Book",
                      }}
                    >
                      {elemnts.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      style={{
                        color: "#747474",
                        fontFamily: "CerebriSans-Book",
                      }}
                    >
                      {elemnts.desc}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
