import React from 'react';

const errorsStyle = { color: "#d43f00", fontSize: 12 };

type FormControlErrorType = {
    errorMessage: string
}

const FormControlError: React.FC<FormControlErrorType> = ({ errorMessage }) => {
    return <span className="input-error">
        <p style={errorsStyle}>{errorMessage}</p>
    </span>
};

export default FormControlError;