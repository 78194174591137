/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const GET_TRANSACTION = gql`
  query getTransaction($transactionUUID: String!) {
    getTransaction(transactionUUID: $transactionUUID) {
      uuid
      code
      stockholderType
      format
      securityType
      createdAt
      status
      deliveryMethod
      certificates {
        uuid
        symbol
        price
        shares
        marketValueTotal
        medallionsCount
        pendingMedallionsCount
        approvedMedallionsCount
        deniedMedallionsCount
      }
      notes {
        uuid
        content
        createdAt
        author {
          firstName
          lastName
        }
      }
      transferAgent {
        uuid
        name
      }
      shippings {
        costInCents
        uuid
        from {
          addressLine1
          addressLine2
          state
          zip
          uuid
        }
        to {
          addressLine1
          addressLine2
          state
          zip
        }
        type
        provider
        createdAt
      }
      discounts {
        code
        valueType
        value
      }
      charge {
        status
        type
        provider
        totalDiscountsInCents
        totalMedallionCostInCents
        totalShippingCostInCents
      }
      documents {
        uuid
        name
        status
        documentType {
          type
          signRequired
        }
      }
    }
  }
`;
