import { gql } from "@apollo/client";

export const SKIP_SURVEY = gql`
  mutation {
    skipSurvey {
      email
      isSurveyAnswered
    }
  }
`;
