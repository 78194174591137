import { useQuery } from "@apollo/client";
import React from "react";
import { useDispatch } from "react-redux";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import { isHomeHeader } from "../../redux/actions";
import CarouselComponent from "../carusel/carusel.component";
import HomeSectionDescription from "../home-section-description/home-section-description.component";
import Loader from "../loader/loader.component";

import "./home-pricing.scss";

const aboutDesc = {
  title: "Pricing and services",
  desc: "We offer flat rate pricing, which includes all related services, giving you cost certainty when you get your medallion stamps",
};
const HomePricing = () => {
  const { data: userData, loading: userLoader } = useQuery(GET_USER, {});

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container">
        <div className="pricing">
          <HomeSectionDescription
            title={aboutDesc.title}
            description={aboutDesc.desc}
          />
          <div
            className="col-md-12 types"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="col-md-4 col-sm-4 text-center pricing-item">
              <div className="image">
                <img
                  alt="group icon for esignature guarantee team"
                  src="assets/images/Team-of-experts.png"
                  className="img-responsive center-block"
                />
              </div>
              <div className="content">
                <h4>Team of experts</h4>
                Our compliance team will review your online application and
                verify your identity.
              </div>
            </div>
            <div className="col-md-4 col-sm-4 text-center pricing-item">
              <div className="image">
                <img
                  alt="lock logo for secure esignature"
                  src="assets/images/Secure-technology.png"
                  className="img-responsive center-block"
                />
              </div>
              <div className="content">
                <h4>Secure technology</h4>
                We use the most current verification process to ensure your
                information is secure.
              </div>
            </div>
            <div className="col-md-4 col-sm-4 text-center pricing-item">
              <div className="image">
                <img
                  alt="digital signature icon at esignature guarantee"
                  src="assets/images/Digital-signature.png"
                  className="img-responsive center-block"
                />
              </div>
              <div className="content">
                <h4>Digital signature</h4>
                We have partnered with HelloSign to allow you to securely sign
                your stock power digitally.
              </div>
            </div>
          </div>
          <div
            className="col-md-12 types"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="col-md-4 col-sm-4 text-center pricing-item">
              <div className="image">
                <img
                  alt="return shipping from esignature guarantee"
                  src="assets/images/Return-shipping.png"
                  className="img-responsive center-block"
                />
              </div>
              <div className="content">
                <h4>Return shipping</h4>
                Shipping the stamped certificates back to your residence or
                office is always included.
              </div>
            </div>
            <div className="col-md-4 col-sm-4 text-center pricing-item">
              <div className="image">
                <img
                  alt="online signature transaction icon"
                  src="assets/images/Transaction-Types.png"
                  className="img-responsive center-block"
                />
              </div>
              <div className="content">
                <h4>Transaction Types</h4>
                We can assist you with transactions that have stock held in
                certificate form or in book entry form.
              </div>
            </div>
            <div className="col-md-4 col-sm-4 text-center pricing-item">
              <div className="image">
                <img
                  alt="trusted medallion signature guarantee provider icon"
                  src="assets/images/Our-guarantee.png"
                  className="img-responsive center-block"
                />
              </div>
              <div className="content">
                <h4>Our guarantee</h4>
                Our medallion stamp is the same you would receive from other
                financial institutions or banks.
              </div>
            </div>
          </div>
          <div className="col-md-12 text-center price">
            <h2>$149 per medallion stamp</h2>
          </div>
        </div>
      </div>
      <section className="questions">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center txt">
                Additional Questions? Visit our FAQs page. Ready to get started?
              </h2>
            </div>
            <div className="col-md-12 pad" style={{ display: "flex" }}>
              <div className="col-md-4 col-sm-4"></div>
              <div className="col-md-4 col-sm-4 text-center">
                {userLoader ? (
                  <Loader size={15} />
                ) : (
                  <a
                    href={userData ? "/accounts/me" : "/login"}
                    className="btn btn-get"
                  >
                    Get a medallion stamp »
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="customers">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center txt">What our customers are saying</h2>
            </div>
            <div className="col-md-12 quote">
              <CarouselComponent />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePricing;
