import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { MARK_AS_SUSPICIOUS } from '../../graphql/mutations/transaction/mark-as-suspicious.mutation';
import { Snackbar } from "@mui/material";
import Alert from "../alert/alert.component";
import Loader from '../loader/loader.component';
import { LOCK_USER } from '../../graphql/mutations/user/lock-user.mutation';

const Suspicious = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [transactionUUID, setTransactionUUID] = useState<string>('');
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>("");
    const [isSuccess, setIsSuccess] = useState(false);

    const navigateBackHome = () => {
        navigate("/home");
    }

    const openAlert = (error: any, success = false) => {
        setIsSuccess(success)
        setError(error);
        setOpen(true);
    };

    const closeAlert = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        setTransactionUUID(location.pathname?.split('/suspicious/')[1])
    }, [location]);

    useEffect(() => {
        if (!transactionUUID.trim()) return;
        try {
            markAsSuspicious()
        } catch (err) { }
    }, [transactionUUID]);

    const [markAsSuspicious, { loading: markAsSuspiciousLoader, error: markAsSuspiciousError }] =
        useMutation(MARK_AS_SUSPICIOUS, {
            variables: { transactionUUID },
            onCompleted: () => {
                openAlert('The transaction marked as suspicious!', true);
            }
        });

    useEffect(() => {
        if (markAsSuspiciousError?.message) {
            openAlert(markAsSuspiciousError?.message);
        }
    }, [markAsSuspiciousError]);

    return <>
        <div className='container'>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "70vh" }}>
                {markAsSuspiciousLoader ? <Loader /> : <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <div className="panel panel-default">
                        <div className="panel-body text-center">
                            <div className="center-block">
                                <p>You have signified that there is a problem with your account.</p>
                                <p>Please feel free to contact us at info@esignatureguarantee.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="center-block text-center">
                        <p className="text-muted" style={{ cursor: "pointer" }} onClick={navigateBackHome}>
                            <small><em>Go back home</em></small>
                        </p>
                    </div>
                </div>}
            </div>
        </div>
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={closeAlert}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <Alert onClose={closeAlert} severity={isSuccess ? "success" : "error"} sx={{ width: "100%" }}>
                {error}
            </Alert>
        </Snackbar>
    </>
};

export default Suspicious;