/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const ADD_IRA_CEURITIES = gql`
  mutation addIRASecurities(
    $accountValue: Float!
    $securities: String!
    $transactionUUID: String!
  ) {
    addIRASecurities(
      transactionUUID: $transactionUUID
      securities: $securities
      accountValue: $accountValue
    ) {
      uuid
      symbol
      price
      shares
      marketValueTotal
      medallionsCount
    }
  }
`;
