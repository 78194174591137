/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const EDIT_TRANSACTION = gql`
  mutation editTransaction(
    $securityType: SecurityType
    $stockholderType: StockholderType
    $format: FormatType
    $transferAgentUUID: String
    $transactionUUID: String!
  ) {
    editTransaction(
      transactionUUID: $transactionUUID
      transferAgentUUID: $transferAgentUUID
      format: $format
      stockholderType: $stockholderType
      securityType: $securityType
    ) {
      uuid
      code
      stockholderType
      format
      securityType
      createdAt
      status
      certificates {
        uuid
        symbol
        medallionsCount
      }
      notes {
        uuid
        content
        createdAt
      }
      transferAgent {
        uuid
        name
      }
      shippings {
        costInCents
        uuid
        from {
          addressLine1
          addressLine2
          state
          zip
        }
        to {
          addressLine1
          addressLine2
          state
          zip
        }
        type
        provider
        createdAt
      }
      discounts {
        code
        valueType
        value
      }
      charge {
        status
        type
        provider
        totalDiscountsInCents
        totalMedallionCostInCents
        totalShippingCostInCents
      }
      documents {
        uuid
        name
        status
        documentType {
          type
          signRequired
        }
      }
    }
  }
`;
