import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import { ADD_IDENTITY_DATA } from "../../../graphql/mutations/user/user.mutations";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { isHomeHeader, setCurrentRegisterState, setExpirationCodes } from "../../../redux/actions";
import Loader from "../../loader/loader.component";
import { GET_USER } from "../../../graphql/queries/user/get-user.query";
import moment from "moment";

const errorsStyle = { color: "#d43f00", fontSize: 12 };

const PersonalDetails = () => {
  const navigate = useNavigate();
  const registerState = useSelector<
    RootState,
    RootState["register"]["current"]
  >((state) => {
    return state.register.current;
  });
  const [bDate, setBDate] = useState("");
  const [bDateValid, setBDateValid] = useState(false);
  const [ssn, setSsn] = useState("");

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const parsedDate = moment(bDate, 'YYYY-MM-DD', true);
    setBDateValid(parsedDate?.isValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bDate]);

  const {
    loading: userLoading,
    data: userData,
    error: userError,
  } = useQuery(GET_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getUser?.status === "LOCKED") {
        localStorage.removeItem("token");
        navigate("/locked-user")
        return;
      }
      if (data?.getUser?.dayOfBirth) {
        setBDate(data?.getUser?.dayOfBirth);
      }
    }
  });

  React.useEffect(() => {
    let newData = ssn;
    if (ssn.length === 3 && typeof +ssn[2] === "number") {
      newData = ssn + "-";
      setSsn(newData);
    }
    if (ssn.length === 6 && typeof +ssn[5] === "number") {
      newData = ssn + "-";
      setSsn(newData);
    }
  }, [ssn]);

  React.useEffect(() => {
    if (registerState < 2) {
      navigate("/register/user-details", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerState]);

  const [addIdentityData, { loading: addIdentityDataLoader }] = useMutation(
    ADD_IDENTITY_DATA,
    {
      variables: {
        dayOfBirth: bDate,
        ssn: ssn.split("-").join(""),
      },
      onCompleted: (data: any) => {
        const codesExpirationDate = data?.addIdentityData?.emailVerificationCode?.expireOn || data?.addIdentityData?.phoneVerificationCode?.expireOn
        if (codesExpirationDate) {
          dispatch(setExpirationCodes(codesExpirationDate));
        }
      }
    }
  );

  const submitForm = async () => {
    if (ssn === "" || bDate === "" || !bDateValid) return;
    try {
      await addIdentityData();
      dispatch(setCurrentRegisterState(3));
      navigate("/register/confirmation", { replace: true });
    } catch (err) { }
  };

  return (
    <React.Fragment>
      <div className="pull-left">
        <h1 className="sub-title">Personal details</h1>
        <p>
          Enter your birth date and social security number below. This
          information is required for us to confirm your identity.
        </p>
      </div>
      <div className="form-block pull-left">
        <form
          name="personalForm"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="line-center"></div>
          <div className="form-row">
            <div className="form-group col-md-12 m45">
              <label>Date of birth</label>
              <input
                value={bDate}
                onChange={(e: any) => setBDate(e?.target?.value)}
                type="date"
                name="dateOfBirth"
                className={`form-control ${bDate === "" ? "error" : ""}`}
              />
              {bDate === "" || !bDateValid ? (
                <span className="input-error">
                  <p style={errorsStyle}>Please enter date of birth.</p>
                </span>
              ) : ''}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12 m10">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
              >
                <label>Social Security number </label>
                <Tooltip
                  title="Your social security number will never be stored anywhere in our system. It is deleted as soon as we have confirmed your identity."
                  placement="left-end"
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              </div>
              <input
                value={ssn}
                onKeyDown={(e: any) => {
                  if (e.key === "Backspace" && ssn[ssn.length - 1] === "-") {
                    const newSsn = ssn.slice(0, ssn.length - 2);
                    setSsn(newSsn);
                  }
                }}
                onChange={(e: any) => {
                  setSsn(e?.target?.value);
                }}
                type="text"
                className={`form-control ${ssn === "" ? "error" : ""}`}
                autoComplete="off"
                placeholder="000-00-0000"
                maxLength={11}
              />
              {ssn === "" && (
                <span className="input-error">
                  <p style={errorsStyle}>
                    Please enter social security number.
                  </p>
                </span>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12 pad-thin">
              <button
                name="user-personal"
                className="btn btn-continue"
                type="button"
                onClick={submitForm}
              >
                {addIdentityDataLoader ? (
                  <Loader size={30} />
                ) : (
                  <>
                    Continue <img src="/assets/images/ssl-icon.png" alt="ssl" />
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default PersonalDetails;
