import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AlertColor, Snackbar } from "@mui/material";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GENERATE_EMAIL_VERIFICATION_CODE,
  GENERATE_PHONE_VERIFICATION_CODE,
  VERIFY_OTP,
} from "../../../graphql/mutations/verification/verification.mutations";
import { GET_USER } from "../../../graphql/queries/user/get-user.query";
import { isHomeHeader, setCurrentRegisterState, setExpirationCodes } from "../../../redux/actions";
import { RootState } from "../../../redux/reducers";
import Alert from "../../alert/alert.component";
import Loader from "../../loader/loader.component";

import "./confirmation.scss";

const errorsStyle = { color: "#d43f00", fontSize: 12, marginTop: 10 };

const Confirmation = () => {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState<AlertColor>("error");
  const [confirmationDetails, setConfirmationDetails] = React.useState({
    digits: "",
    phonedDigits: "",
  });
  const [error, setError] = React.useState<string | undefined>("");
  const [userData, setUserData] = React.useState<any>("");

  const openAlert = (error: any, severity: AlertColor) => {
    setError(error);
    setSeverity(severity);
    setAlertOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };
  const registerState = useSelector<
    RootState,
    RootState["register"]["current"]
  >((state) => {
    return state.register.current;
  });

  const codesExpiration = useSelector<
    RootState,
    RootState["register"]["codesExpiration"]
  >((state) => {
    return state.register.codesExpiration;
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (registerState < 3) {
      navigate("/register/user-details");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerState]);

  const { loading: userLoading, error: userError } = useQuery(GET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      setUserData(data);
      if (data?.getUser?.status === "LOCKED") {
        localStorage.removeItem("token");
        navigate("/locked-user")
        return;
      }
      if (data?.getUser?.mobile?.isVerified && data?.getUser?.isEmailVerified && data?.getUser?.status === "VERIFYING") {
        dispatch(setCurrentRegisterState(4));
        navigate("/register/verify-identity");
        return;
      }
    },
  });

  const [fetchUserData] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      if (data?.getUser?.status === "LOCKED") {
        localStorage.removeItem("token");
        navigate("/locked-user")
        return;
      }
      setUserData(data)
    },
  });

  const generateCodes = async (data: any) => {
    if (!data?.getUser?.mobile?.isVerified) {
      await generatePhoneVerificationCode();
    }
    if (!data?.getUser?.isEmailVerified) {
      await generateEmailVerificationCode();
    }
  };

  const [
    verifyOtp,
    { loading: verifyOtpLoader, error: verifyOtpError },
  ] = useMutation(VERIFY_OTP, {
    variables: {
      phoneDigits: confirmationDetails.phonedDigits,
      emailDigits: confirmationDetails.digits
    },
    refetchQueries: [
      {
        query: GET_USER,
      },
    ],
  });


  const [generateEmailVerificationCode, { loading: generateEmailCodesLoader, error: generateEmailError }] =
    useMutation(GENERATE_EMAIL_VERIFICATION_CODE, {
      onCompleted: (data: any) => {
        const codesExpirationDate = data?.generateVerificationCodeForEmail?.expireOn;
        if (codesExpirationDate) {
          dispatch(setExpirationCodes(codesExpirationDate));
        }
      }
    });

  const [generatePhoneVerificationCode, { loading: generatePhoneCodesLoader, error: generatePhoneError }] =
    useMutation(GENERATE_PHONE_VERIFICATION_CODE, {
      onCompleted: (data: any) => {
        const codesExpirationDate = data?.generateVerificationCodeForMobile?.expireOn;
        if (codesExpirationDate) {
          dispatch(setExpirationCodes(codesExpirationDate));
        }
      }
    });

  const onSubmit = async () => {
    if (!userData?.getUser?.isEmailVerified && confirmationDetails.digits === "") return;
    if (!userData?.getUser?.mobile?.isVerified && confirmationDetails.phonedDigits === "") return;

    try {
      const res = await verifyOtp();
      const isEmailVerified = res?.data?.verifyOTC?.isEmailVerified
      const isMobileVerified = res?.data?.verifyOTC?.mobile?.isVerified;

      setUserData({
        getUser: {
          ...userData?.getUser, isEmailVerified, mobile: {
            ...userData?.getUser?.mobile,
            isVerified: isMobileVerified
          }
        }
      })

      if (res?.data && !isMobileVerified) {
        openAlert('Please make sure you have typed the right verification codes!', 'error');
        return;
      }
      if (res?.data && !isEmailVerified) {
        openAlert('Please make sure you have typed the right verification codes!', 'error');
        return;
      }
      dispatch(setCurrentRegisterState(4));
      dispatch(setExpirationCodes(''));
      navigate("/register/verify-identity");
    } catch (err) { }
  };

  React.useEffect(() => {
    setError("");
    if (verifyOtpError?.message) {
      openAlert(verifyOtpError?.message, 'error');
      try {
        fetchUserData();
      } catch (err: any) {
        openAlert(err?.message, 'error');
      }
    }
    if (userError?.message) {
      openAlert(userError?.message, 'error');
    }
    if (generatePhoneError?.message) {
      openAlert(generatePhoneError?.message, 'error');
    }
    if (generateEmailError?.message) {
      openAlert(generateEmailError?.message, 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyOtpError, userError, generateEmailError, generatePhoneError]);

  if (userLoading) {
    return <Loader />;
  }

  if (userError) {
    openAlert(userError, 'error');
    navigate("/login", { replace: true });
  }

  return (
    <React.Fragment>
      <div className="pull-left">
        <h1 className="sub-title">Confirmation codes</h1>
        <p style={{ textAlign: "center" }}>
          You should have received a confirmation code to both the email address
          you used to create an account and the mobile phone number you entered
          under User Info. Enter each code in the correct fields below. This is
          part of our dual verification process.
        </p>
      </div>
      <div className="form-block pull-left thinform">
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!userData?.getUser?.mobile?.isVerified && (
            <div className="form-group">
              <label>Phone verification code</label>
              <input
                value={confirmationDetails?.phonedDigits}
                onChange={(e: any) =>
                  setConfirmationDetails({
                    ...confirmationDetails,
                    phonedDigits: e.target.value,
                  })
                }
                type="text"
                className={`form-control  ${confirmationDetails?.phonedDigits === "" ? "error" : ""
                  }`}
                name="mobile"
                uppercase-codes
                autoComplete="off"
                placeholder="Mobile Verification Code"
              />
              {confirmationDetails?.phonedDigits !== "" && (
                <span className="input-info">
                  Sent to + {userData?.getUser?.mobile?.countryCode} -
                  {userData?.getUser?.mobile?.localNumber}
                </span>
              )}
              {confirmationDetails?.phonedDigits === "" && (
                <p style={errorsStyle}>
                  Please enter phone verification code.
                </p>
              )}
            </div>
          )}
          {!userData?.getUser?.isEmailVerified && (
            <div className="form-group" style={{ marginTop: confirmationDetails?.phonedDigits === "" ? 0 : 25 }}>
              <label>Email verification code</label>

              <input
                value={confirmationDetails?.digits}
                onChange={(e: any) =>
                  setConfirmationDetails({
                    ...confirmationDetails,
                    digits: e.target.value,
                  })
                }
                type="text"
                className={`form-control  ${confirmationDetails?.digits === "" ? "error" : ""
                  }`}
                name="email"
                uppercase-codes
                autoComplete="off"
                placeholder="Email Verfication Code"
              />
              {confirmationDetails?.digits !== "" && (
                <span className="input-info">
                  Sent to {userData?.getUser?.email}
                </span>
              )}
              {confirmationDetails?.digits === "" && (
                <p style={errorsStyle}>
                  Please enter email verification code.
                </p>
              )}
            </div>
          )}
          <div
            className="form-group col-md-12 pad-thin"
            style={{
              textAlign: "center",
              maxWidth: 400,
              color: "#999999",
              fontSize: 14,
            }}
          >
            <button
              name="user-verify"
              className="btn btn-continue"
              type="button"
              onClick={() => onSubmit()}
            >
              {verifyOtpLoader ? <Loader size={30} /> : "Continue"}
            </button>
            <div style={{ marginTop: 18 }}>
              You will receive an SMS on your mobile phone, and an email in
              your inbox. Each will include a different code, which you need
              to enter on this page in the correct input-box. This is part of
              our identity verification process.
            </div>

            {codesExpiration && <div style={{ marginTop: 18, marginBottom: 18, fontWeight: 600 }}>
              The codes are valid to use until <b>{moment(codesExpiration).format("DD/MM/YYYY HH:mm")}</b>
            </div>}

            {generateEmailCodesLoader || generatePhoneCodesLoader ? <Loader size={20} /> : <button
              className="confirmation__generate-btn"
              type="button"
              onClick={async () => {
                try {
                  await generateCodes(userData);
                  openAlert('Generated Successfully', 'success');
                } catch (err) { }
              }}
            >
              Click here to regenerate codes
            </button>}
          </div>
        </form>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity={severity} sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default Confirmation;
