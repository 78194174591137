import React from "react";
import { useDispatch } from "react-redux";
import { isHomeHeader } from "../../redux/actions";
import HomeSectionDescription from "../home-section-description/home-section-description.component";
import StepOne from "../step-1/step-1.component";

import "./how-it-works.scss";

const aboutDesc = {
  title: "How to Get a Medallion Signature Guarantee Online",
  desc: "Looking for a medallion signature guarantee stamp? In the sections below, we'll take you through the entire online process.",
};
const HomeHowItWorks = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container">
        <div className="about">
          <HomeSectionDescription
            title={aboutDesc.title}
            description={aboutDesc.desc}
          >
            <p>AVAILABLE FOR US SHAREHOLDERS ONLY</p>
          </HomeSectionDescription>
        </div>
      </div>
      <StepOne />
      <section className="how-steps-step2">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 pad">
              <div className="title">
                <span className="sub">Step 2</span>
                <h2>Provide securities details</h2>
                <p>
                  At this stage, you’ll tell us about the securities that
                  require stamps, provide us with supporting documentation,
                  choose a shipping method and pay for the service. On every
                  screen you’ll find helpful information to make it easy to get
                  through the process.
                </p>
              </div>
            </div>
          </div>
          <div className="row step2">
            <div className="col-md-12 pad" style={{ display: "flex" }}>
              <div className="col-md-6 ">
                <div className="left-p1">
                  <h3>Ownership Type</h3>
                  <p>
                    After you’ve verified your identity, you’ll be asked for
                    information about the securities that require medallion
                    stamps. Begin by telling the ownership type, as well as the
                    type of securities that you own.
                  </p>
                </div>
              </div>
              <div className="right-img col-md-6">
                <img
                  alt="medallion signature guarantee ownership type"
                  src="assets/images/step2_1.png"
                />
              </div>
            </div>

            <div className="col-md-12 pad" style={{ display: "flex" }}>
              <div className="right-img col-md-6">
                <img
                  alt="medallion signature guarantee details"
                  src="assets/images/step2_2.png"
                />
              </div>
              <div className="col-md-6">
                <div className="right-p">
                  <h3>Medallion Details</h3>
                  <p>
                    Next, you’ll be asked about the details of the specific
                    securities that require stamps. Our medallion stamp is only
                    valid on stocks up to $500,000.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="how-steps-step2">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 pad">
              <div className="title">
                <span className="sub">Step 3</span>
                <h2>Receive medallion stamp</h2>
              </div>
            </div>
          </div>
          <div className="row step2">
            <div className="col-md-12 pad" style={{ display: "flex" }}>
              <div className="col-md-6">
                <div className="left-p1">
                  <h3>Checkout</h3>
                  <p>
                    After you’ve finished entering your transaction information,
                    you’ll be taken to our checkout page. If you have a
                    different return address, you can enter it in here.
                    <br />
                    <br />
                    If you decided to courier your documents to us, you’ll be
                    asked to choose your shipping method. eSignature Guarantee
                    pays for return shipping in most situations, but if you
                    choose to send us your supporting documentation you are
                    responsible for the shipping costs. We do, however, allow
                    you to use our FedEx account, so you can easily print a
                    shipping label through our system.
                  </p>
                </div>
              </div>
              <div className="right-img col-md-6">
                <img
                  alt="medallion signature guarantee shipping"
                  src="assets/images/step3_1.png"
                />
              </div>
            </div>

            <div className="col-md-12 pad" style={{ display: "flex" }}>
              <div className="right-img col-md-6">
                <img
                  alt="medallion signature guarantee cost"
                  src="assets/images/step3_2.png"
                />
              </div>
              <div className="col-md-6">
                <div className="right-p">
                  <h3>Confirmation code</h3>
                  <p>
                    Next, you will a receive confirmation code to your email.
                    This will be the last step prior to entering your
                    credit card payment info.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 pad" style={{ display: "flex" }}>
              <div className="col-md-6">
                <div className="left-p2">
                  <h3>Transactions complete!</h3>
                  <p>
                    eSignature Guarantee will stamp and ship your securities
                    certificates once we’ve received all the supporting
                    information.
                  </p>
                </div>
              </div>
              <div className="right-img col-md-6">
                <img
                  alt="medallion signature guarantee online certification"
                  src="assets/images/step3_3.png"
                />
              </div>
            </div>

            <div className="col-md-12 pad" style={{ display: "flex" }}>
              <div className="right-img col-md-6">
                <img
                  alt="medallion signature guarantee support documents"
                  src="assets/images/step2_3.png"
                />
              </div>
              <div className="col-md-6">
                <div className="left-p2">
                  <h3>Support Documents</h3>
                  <p>
                    Now that you have completed your transaction, you’ll be
                    asked for supporting documents, such as stock power
                    agreements or statement of ownership. You have the option of
                    uploading these documents, shipping them to our offices, or
                    uploading some while shipping others. <br />
                    <br />
                    If you choose to upload the documents, you also have the
                    ability to use our digital signature tools.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="get-ready">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center txt">
                Ready to get your medallion stamp?
              </h2>
            </div>
            <div className="col-md-12 pad" style={{ display: "flex" }}>
              <div className="col-md-4 col-sm-4"></div>
              <div className="col-md-4 col-sm-4 text-center">
                <a href="/transactions/create" className="btn btn-get">
                  Get started »
                </a>
              </div>
            </div>
            <div className="col-md-12 dditional text-center">
              Additional Questions? Visit our{" "}
              <a rel="canonical" href="/faq">
                FAQs page
              </a>
              .
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeHowItWorks;
