import React, { useState } from "react";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { useSelector } from "react-redux";

import Loader from "../loader/loader.component";
import { REMOVE_CERTIFICATE } from "../../graphql/mutations/transaction/remove-certificate.mutation";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TRANSACTION } from "../../graphql/queries/transaction/get-transaction.query";
import { RootState } from "../../redux/reducers";
import { ADD_CERTIFICATE } from "../../graphql/mutations/transaction/add-certificate.mutation";
import { getMedallionsLength } from "../certificate-regular/certificate-regular.helpers";

const certificaAddStyle = {
  minHeight: 160,
  height: "fit-content",
  backgroundColor: "#f5f5f5",
  padding: 14,
  border: "3px solid #ddd",
  marginTop: 38,
};

const tableThStyle = {
  color: "#999",
  fontFamily: "CerebriSans-SemiBold",
  paddingTop: 10,
  paddingBottom: 13,
  fontSize: 12,
  fontWeight: 400,
};

const tableTdStyle = {
  color: "#555",
  fontSize: 12,
  padding: "10px 0px",
};

const errorsStyle = { color: "#d43f00", fontSize: 12 };

type AddNewCerComponentType = { openAlert: (error: any) => void };

const AddNewCertificate: React.FC<AddNewCerComponentType> = ({ openAlert }) => {
  const transactionUUID = useSelector<
    RootState,
    RootState["transaction"]["transactionUUID"]
  >((state) => {
    return state.transaction.transactionUUID;
  });

  const [processLoader, setProcessLoader] = useState(false);
  const [errors, setErrors] = useState(false);
  const [certificateUUIDToRemove, setCertificateUUIDToRemove] =
    useState<string>("");
  const [certificates, setCertificates] = useState<any>([]);
  const [certificate, setCertificate] = useState<{
    price: number;
    shares: number;
    symbol: string;
  }>({
    price: 0,
    shares: 0,
    symbol: "",
  });

  const [removeCertificateFunc, { error: removeCertificateErr }] = useMutation(
    REMOVE_CERTIFICATE,
    {
      variables: {
        transactionUUID: transactionUUID,
        certificateUUID: certificateUUIDToRemove,
      },
      refetchQueries: [
        {
          query: GET_TRANSACTION,
          variables: { transactionUUID: transactionUUID },
        },
      ],
      onCompleted: () => setProcessLoader(false),
    }
  );

  const [
    addCertificateFunc,
    { loading: addCertificateLoader, error: addCertificateErr },
  ] = useMutation(ADD_CERTIFICATE, {
    variables: {
      transactionUUID: transactionUUID,
      price: +certificate.price,
      shares: +certificate.shares,
      symbol: certificate.symbol,
    },
    refetchQueries: [
      {
        query: GET_TRANSACTION,
        variables: { transactionUUID: transactionUUID },
      },
    ],
  });

  const { loading: transactionLoader, data: transactionData } = useQuery(
    GET_TRANSACTION,
    {
      variables: {
        transactionUUID: transactionUUID,
      },
      onCompleted: (data) => {
        setProcessLoader(false);
        setCertificates(data?.getTransaction?.certificates);
      },
    }
  );

  React.useEffect(() => {
    if (removeCertificateErr?.message) {
      openAlert(removeCertificateErr?.message);
    }
    if (addCertificateErr?.message) {
      openAlert(addCertificateErr?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeCertificateErr, addCertificateErr]);

  React.useEffect(() => {
    if (!certificateUUIDToRemove) return;
    try {
      setProcessLoader(true);
      removeCertificateFunc();
    } catch (err) {
      setProcessLoader(false);
    }
  }, [certificateUUIDToRemove, removeCertificateFunc]);

  const onAddCertificate = async () => {
    setErrors(true);
    if (
      +certificate.price === 0 ||
      !certificate.price ||
      +certificate.shares === 0 ||
      !certificate.shares ||
      !certificate.symbol
    )
      return;

    try {
      setProcessLoader(true);
      await addCertificateFunc();
    } catch (err) {
      setProcessLoader(false);
    }
  };

  const onAddingCertificateDetails = (e: any) => {
    const { name, value } = e.target;


    if (name === "symbol") {
      setCertificate({ ...certificate, [name]: value });
      return;
    }

    if (name === "shares") {
      const newValue = value.replace(/[^\d]/g, '');
      setCertificate({ ...certificate, [name]: newValue });
      return;
    }

    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue)) {
      const stringValue = parsedValue.toString();
      const decimalPosition = stringValue.indexOf('.');
      const hasMoreThanThreeDecimals = decimalPosition !== -1 && stringValue.length - decimalPosition > 5;
      const truncatedValue = hasMoreThanThreeDecimals ? Math.floor(parsedValue * 100) / 100 : parsedValue;

      setCertificate({ ...certificate, [name]: Number(truncatedValue) });
    }
    else {
      // Handle the case where the input is not a valid number (e.g., empty string)
      setCertificate({ ...certificate, [name]: "" });
    }
  };

  const onRemoveMedallion = (index: string) => {
    setCertificateUUIDToRemove(index);
  };

  return (
    <div className="form-block pull-left">
      <div style={certificaAddStyle}>
        <div
          style={{
            fontSize: 12,
            textTransform: "uppercase",
            color: "#999",
            fontFamily: "CerebriSans-SemiBold",
            paddingBottom: 16,
          }}
        >
          add new
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-start",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ maxWidth: 150 }}>
            <label style={{ fontWeight: 400 }}>Stock symbol</label>
            <input
              value={certificate.symbol}
              type="text"
              style={{ textTransform: "uppercase", height: 30 }}
              name="symbol"
              className={`form-control ${!certificate.symbol ? "error" : ""}`}
              onChange={onAddingCertificateDetails}
            />
            {errors && !certificate.symbol && (
              <span className="input-error" style={errorsStyle}>
                Field is required!
              </span>
            )}
          </div>
          <div style={{ maxWidth: 150 }}>
            <label>Price per share</label>
            <input
              style={{ height: 30 }}
              type="number"
              className={`form-control ${!certificate.price ? "error" : ""}`}
              value={certificate.price}
              name="price"
              min="0"
              step="0.01"
              onChange={onAddingCertificateDetails}
            />
            {errors && !certificate.price && (
              <span className="input-error" style={errorsStyle}>
                Field is required!
              </span>
            )}
          </div>
          <div style={{ maxWidth: 150 }}>
            <label>Number of shares</label>
            <input
              type="text"
              min="0"
              value={certificate.shares}
              name="shares"
              className={`form-control ${!certificate.shares ? "error" : ""}`}
              style={{ height: 30 }}
              onChange={onAddingCertificateDetails}
            />
            {errors && !certificate.shares && (
              <span className="input-error" style={errorsStyle}>
                Field is required!
              </span>
            )}
          </div>
          {addCertificateLoader ? (
            <div style={{ minWidth: 75, width: "100%" }}>
              <Loader size={20} />
            </div>
          ) : (
            <button
              style={{
                background: "#28dab6",
                color: "#fff",
                borderRadius: 2,
                transition: ".3s ease-in-out",
                padding: "0 20px",
                marginTop: 25,
                minWidth: 75,
                width: "100%",
                fontFamily: "CerebriSans-SemiBold",
                fontSize: 14,
                height: 30,
                border: "none",
                cursor: "pointer",
              }}
              type="button"
              onClick={onAddCertificate}
            >
              Add
            </button>
          )}
        </div>
      </div>

      <div style={{ paddingTop: 45 }}>
        {processLoader || transactionLoader ? (
          <div>
            <Loader />
          </div>
        ) : (
          <table style={{ border: 0, width: "100%" }}>
            <thead style={{ borderBottom: "solid 1px #ddd" }}>
              <tr>
                <th style={tableThStyle}>Stock Symbol</th>
                <th style={tableThStyle}>PRICE PER SHARE</th>
                <th style={tableThStyle}>No. of Shares</th>
                <th style={tableThStyle}>MARKET VALUE</th>
                <th style={tableThStyle}>TOTAL COST</th>
              </tr>
            </thead>
            <tbody>
              {certificates?.map((certificate: any, i: number) => (
                <>
                  <tr
                    key={i}
                    style={{
                      borderBottom:
                        certificate?.marketValueTotal >= 2500000
                          ? "none"
                          : "solid 1px #ddd",
                      padding: "10px 0px",
                    }}
                  >
                    <td
                      style={{
                        textTransform: "uppercase",
                        ...tableTdStyle,
                      }}
                    >
                      {certificate?.symbol}
                    </td>
                    <td style={tableTdStyle}>${certificate?.price}</td>
                    <td style={tableTdStyle}>{certificate?.shares}</td>
                    <td style={tableTdStyle}>${certificate?.marketValueTotal}</td>
                    <td style={tableTdStyle}>
                      $
                      {certificate?.medallionsCount * 149}
                    </td>
                    <td
                      style={{ ...tableTdStyle, cursor: "pointer" }}
                      onClick={() => {
                        onRemoveMedallion(certificate?.uuid);
                      }}
                    >
                      <ClearRoundedIcon className="clear-detail clear" />
                    </td>
                  </tr>
                  {certificate?.marketValueTotal >= 2500000 && (
                    <tr key={1000000 + i}>
                      <td colSpan={5}>
                        <p
                          style={{
                            borderBottom: "solid 1px #ddd",
                            fontSize: 10,
                            color: "red",
                            padding: "10px 0",
                          }}
                        >
                          You are requesting more than 4 medallions for a single
                          line-item/security. Please note that we cannot promise
                          that all these medallions will be completed at the
                          same time, and it may take longer than expected to
                          complete all these medallions in a timely manner
                        </p>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td
                  colSpan={6}
                  align="right"
                  style={{
                    fontSize: 14,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  Total number of medallion stamps required:
                  {transactionData?.getTransaction?.certificates?.reduce(
                    (acc: number, certificate: any) => {
                      return acc + certificate?.medallionsCount;
                    },
                    0
                  ) || 0}
                </td>
              </tr>
            </tfoot>
          </table>
        )}
        {getMedallionsLength(transactionData?.getTransaction) === 0 && (
          <span className="input-error">
            <div></div>
            Transaction must have minimum 1 medallion
          </span>
        )}
      </div>
    </div>
  );
};

export default AddNewCertificate;
