import { useLazyQuery } from "@apollo/client";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Snackbar } from "@mui/material";

import Alert from "../alert/alert.component";
import { ListTransactions } from "../../graphql/interfaces/transaction.interfaces";
import { LIST_TRANSACTIONS } from "../../graphql/queries/transaction/list-transaction.query";
import { isHomeHeader } from "../../redux/actions";
import AllTransactions from "../all-transactions/all-transactions";
import Loader from "../loader/loader.component";
import TableButtonsType from "../table-buttons/table-buttons.component";
import { TableButtonsEnum } from "../table-buttons/table-buttons.enum";

const TransactionsHistory = () => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(1);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openAlert = (error: any) => {
    setError(error);
    setAlertOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  React.useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const [
    fetchTransactions,
    { loading: listTransactionsLoading, data, error: listTransactionsError },
  ] = useLazyQuery<ListTransactions>(LIST_TRANSACTIONS, {
    variables: {
      page,
    },
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    if (listTransactionsError?.message) {
      openAlert(listTransactionsError?.message);
    }
  }, [listTransactionsError]);

  return (
    <div className="account-content">
      <h1 className="sub-title">Transaction history</h1>

      {listTransactionsLoading ? (
        <Loader />
      ) : (
        <>
          <AllTransactions items={data?.listTransactions?.items} />
          <TableButtonsType
            hasMore={data?.listTransactions?.hasMore}
            page={page}
            onButtonClicked={(action: TableButtonsEnum) => {
              if (
                action === TableButtonsEnum.NEXT &&
                data?.listTransactions?.hasMore
              ) {
                setPage((prev) => {
                  return prev + 1;
                });
              }
              if (action === TableButtonsEnum.PREVIOUS && page > 1) {
                setPage((prev) => {
                  return prev - 1;
                });
              }
            }}
          />
        </>
      )}

      <div className="action-button">
        <Link to="/transactions/create/start">
          <h4 className="btn btn-continue">Start new transaction</h4>
        </Link>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TransactionsHistory;
