import { SecurityTypeEnum } from "./interfaces/create-transaction.interfaces";

export enum OwnershipTypes {
  PRIVATE = "private",
  CORPORATE = "corporate",
  DECEASED = "deceased",
}

export enum MedallionTypes {
  PUBLIC = "public",
  PRIVATE = "private",
  IRA = "ira-401-funds-bonds",
}

export type OwnershipType =
  | OwnershipTypes.PRIVATE
  | OwnershipTypes.CORPORATE
  | OwnershipTypes.DECEASED;

export type MedallionType =
  | SecurityTypeEnum.PUBLIC
  | SecurityTypeEnum.PRIVATE
  | SecurityTypeEnum.IRA_401_FUNDS_BONDS;
