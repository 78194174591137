import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { AlertColor, Button, Snackbar, TextField } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { NestedValue, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { GENERATE_RESET_PASSCODE } from "../../graphql/mutations/auth/auth.mutations";
import Loader from "../loader/loader.component";
import { useDispatch } from "react-redux";
import { isHomeHeader } from "../../redux/actions";
import Alert from "../alert/alert.component";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");
  const [severity, setSeverity] = useState<AlertColor>("error");

  const navigate = useNavigate();

  const openAlert = (error: any) => {
    setError(error);
    setAlertOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<{
    email: NestedValue<any>;
  }>({
    defaultValues: {
      email: "",
    },
  });

  const [generateResetPasscode, { loading, error: generateCodeError }] =
    useMutation(GENERATE_RESET_PASSCODE, {
      variables: {
        email: getValues("email"),
      },
      onCompleted: () => {
        setSeverity("success");
        openAlert("Successfully Generated");
        setTimeout(() => {
          navigate("/reset-password");
        }, 2000);
      },
    });

  React.useEffect(() => {
    if (generateCodeError?.message) {
      setSeverity("error");
      openAlert(generateCodeError?.message);
    }
  }, [generateCodeError]);

  React.useEffect(() => {
    register("email", {
      validate: (value) => value.length || "Email is required.",
    });
  }, [register]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await generateResetPasscode();
    } catch (err) {}
  });

  return (
    <>
      <Box
        sx={{
          height: "100%",
          padding: "100px 0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          alignContent: "center",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            height: 300,
            maxWidth: 340,
            width: "100%",
            padding: 5,
            boxShadow: "0 2px 25px -9px #333;",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <form
            onSubmit={onSubmit}
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                alignItems: "center",
                marginBottom: 25,
              }}
            >
              <div>
                <LockIcon />
              </div>
              <label
                style={{ fontWeight: 400, textAlign: "center", fontSize: 24 }}
              >
                Reset your password
              </label>
            </div>
            <TextField
              {...register("email")}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              type={"email"}
              error={!!errors?.email}
              helperText={!!errors?.email ? errors?.email?.message : ""}
              style={{ flex: 1 }}
            />
            {loading ? (
              <Loader />
            ) : (
              <Button
                variant="contained"
                style={{ height: 36, background: "#28dab6" }}
                type={"submit"}
              >
                Send
              </Button>
            )}
          </form>
        </Card>
      </Box>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity={severity} sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ForgotPassword;
