import React from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { isHomeHeader } from "../../../redux/actions";
import RegistrationSteps from "../registration-steps/registration-steps";

const MainRegistrationPage = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div
        className="row"
        style={{
          marginTop: 20,
          marginBottom: 45,
        }}
      >
        <RegistrationSteps />
      </div>
      <div
        className="row"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <section className="transaction">
          <div className="col pad content">
            <Outlet />
          </div>
        </section>
      </div>
    </div>
  );
};

export default MainRegistrationPage;
