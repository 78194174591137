import { useMutation, useQuery } from "@apollo/client";
import { Snackbar } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GENERATE_VERIFICATION_CODE_TRANSACTION } from "../../graphql/mutations/payments/generate-verification-code-for-transaction.mutation";
import { PAY_TRANSACTION } from "../../graphql/mutations/payments/pay-transaction.mutation";
import { GET_TRANSACTION } from "../../graphql/queries/transaction/get-transaction.query";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import { isHomeHeader } from "../../redux/actions";
import { RootState } from "../../redux/reducers";
import Alert from "../alert/alert.component";
import CheckoutSideBox from "../checkout-side-box/checkout-side-box.component";
import Loader from "../loader/loader.component";
import {
  getMonthsArr,
  getYearsArr,
  verifyCardInputs,
} from "./payments.helpers";

const lineStyle = {
  backgroundColor: "#ddd",
  height: 1,
  width: "100%",
  marginTop: 5,
  marginBottom: 15,
};

const labelStyle = {
  color: "#555",
  fontWeight: 400,
  fontSize: 16,
};

const titleStyle = {
  color: "#0c234c",
  fontSize: 18,
  fontFamily: "CerebriSans-SemiBold",
  paddingBottom: 15,
  paddingTop: 15,
};

const btnStyle = {
  color: "#fff",
  borderRadius: 2,
  transition: ".3s ease-in-out",
  padding: "0px 25px",
  width: "100%",
  fontSize: 17,
  height: 45,
  fontFamily: "CerebriSans-SemiBold",
  background: "#28dab6",
};

const errorsStyle = { color: "#d43f00", fontSize: 12, marginTop: 5 };

const Payments = () => {
  const navigate = useNavigate();
  // useEffect(() => { 
  //   return () => {
  //     // && history.location.pathname === "any specific path")
  //     if (history.action === "POP") {
  //       history.replace(history.location.pathname /* the new state */);
  //     }
  //   };
  // }, [history]);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");

  const openAlert = (error: any) => {
    setError(error);
    setAlertOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transactionUUID = useSelector<
    RootState,
    RootState["transaction"]["transactionUUID"]
  >((state) => {
    return state.transaction.transactionUUID;
  });

  const [verificationCode, setVerificationCode] = useState<string>("");
  const [cardHolderName, setCardHolderName] = useState<string>("");
  const [cardNumber, setCardNumber] = useState<string>("");
  const [cvv, setCvv] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [expMonth, setExpMonth] = useState<string>("");
  const [expYear, setExpYear] = useState<string>("");
  const [errors, setErrors] = useState<boolean>(false);

  const guardPage = () => {
    if (!transactionUUID) {
      navigate("/transactions/create/start", { replace: true });
      return false;
    }
    return true;
  };

  // make sure to redirect the user in case no active transaction
  React.useEffect(() => {
    guardPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionUUID, navigate]);

  const { data: transactionData, error: transactionError } = useQuery(
    GET_TRANSACTION,
    {
      variables: {
        transactionUUID: transactionUUID,
      },
    }
  );

  const { data: userData, loading: userLoader, error: userError } = useQuery(GET_USER, {
    fetchPolicy: "no-cache"
  });

  const paymentMethod = userData?.getUser?.paymentMethod;

  const [
    generateCodeFunc,
    { loading: generateCodeLoader, error: generateCodeError },
  ] = useMutation(GENERATE_VERIFICATION_CODE_TRANSACTION, {
    variables: {
      transactionUUID: transactionUUID,
    },
  });

  const [
    payTransactionFunc,
    { loading: payTransactionLoader, error: payTransactionError },
  ] = useMutation(PAY_TRANSACTION, {
    refetchQueries: [
      {
        query: GET_TRANSACTION,
        variables: { transactionUUID: transactionUUID },
      },
    ],
  });

  React.useEffect(() => {
    if (payTransactionError?.message) {
      openAlert(payTransactionError?.message);
    }
    if (generateCodeError?.message) {
      openAlert(generateCodeError?.message);
    }
    if (transactionError?.message) {
      openAlert(transactionError?.message);
    }
  }, [payTransactionError, generateCodeError, transactionError]);

  React.useEffect(() => {
    if (payTransactionError?.message === "not authorized") {
      navigate("/home", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payTransactionError, navigate]);

  React.useEffect(() => {
    if (!guardPage()) return;
    if (transactionData?.getTransaction?.status === "VERIFIED") return;
    try {
      generateCodeFunc();
    } catch (err) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVerificationCode = (e: any) => {
    const { value } = e.target;
    setVerificationCode(value);
  };

  const onCompletePayment = async () => {
    setErrors(true);
    // TODO - move it under payTransaction
    if (!verificationCode) return;

    if (
      !verifyCardInputs(
        cardHolderName,
        cardNumber,
        expMonth,
        expYear,
        cvv,
        zip
      ) && paymentMethod === "ON_DEMAND"
    )
      return;

    let payload: any = {
      digits: verificationCode,
      transactionUUID: transactionUUID,
    };

    if (paymentMethod === "ON_DEMAND") {
      payload = {
        ...payload,
        creditCard: {
          cardName: cardHolderName,
          cardNumber: cardNumber,
          cvv: cvv,
          exp: `${expMonth}/${expYear.substring(2)}`,
          zipCode: zip
        }
      };
    }
    try {
      await payTransactionFunc({
        variables: payload
      });
      navigate("/completed/" + transactionUUID, { replace: true });
    } catch (err) {
      console.error(err);
      guardPage();
    }
  };

  return (
    <div className="container">
      <div
        className="payments"
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          padding: "50px 0px",
        }}
      >
        <div style={{ maxWidth: 600 }}>
          <h1 style={{ paddingBottom: 20 }}>Checkout: Payment info</h1>
          <h4 style={titleStyle}>Confirmation code</h4>
          <div
            style={{
              fontSize: 15,
              color: "#555",
              fontFamily: "CerebriSans-Book!important",
            }}
          >
            You should have received a confirmation code to your mobile device.
            Please enter it here.
          </div>

          {transactionData?.getTransaction?.status !== "VERIFIED" && (
            <div
              style={{
                display: "flex",
                marginTop: 20,
                paddingBottom: 10,
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <input
                  type={"text"}
                  placeholder="XXXXXX"
                  tabIndex={0}
                  className="form-control"
                  value={verificationCode}
                  style={{ maxWidth: 220, marginRight: 10 }}
                  onChange={onVerificationCode}
                />
                {payTransactionError && (
                  <span className="input-error">
                    <p style={errorsStyle}>{payTransactionError?.message}</p>
                  </span>
                )}
              </div>
              <span
                onClick={() => generateCodeFunc()}
                style={{
                  color: "#337ab7",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {generateCodeLoader ? (
                  <Loader size={20} />
                ) : (
                  "Click here to regenerate codes"
                )}
              </span>
            </div>
          )}

          {errors && !verificationCode && (
            <span className="input-error" style={errorsStyle}>
              Field is required!
            </span>
          )}

          <div style={lineStyle}></div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            {paymentMethod === "ON_DEMAND" ? <>
              <h4 style={titleStyle}>Payment info</h4>

              <div style={{ marginBottom: 15 }}>
                <label style={labelStyle}>Name on card</label>
                <input
                  type="text"
                  className="form-control"
                  value={cardHolderName}
                  onChange={(e) => setCardHolderName(e.target.value)}
                />
                {errors && !cardHolderName && (
                  <span className="input-error" style={errorsStyle}>
                    Name is required!
                  </span>
                )}
              </div>

              <div style={{ marginBottom: 15 }}>
                <label style={labelStyle}>Credit card number</label>
                <input
                  type="text"
                  className="form-control"
                  pattern="[0-9]*"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                />
                {errors && !cardNumber && (
                  <span className="input-error" style={errorsStyle}>
                    Card Number is required!
                  </span>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 15,
                  flexWrap: "wrap",
                  marginBottom: 35,
                }}
              >
                <div>
                  <label style={labelStyle}>Expiration date</label>

                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <select
                        className="form-control"
                        style={{ marginRight: 15, width: "auto" }}
                        onChange={(e) => setExpMonth(e.target.value)}
                      >
                        <option>- Select month</option>
                        {getMonthsArr.map((m: string) => (
                          <option key={m}>{m}</option>
                        ))}
                      </select>

                      {(errors && !expMonth) ||
                        (errors && expMonth === "- Select month" && (
                          <span className="input-error" style={errorsStyle}>
                            Month is required!
                          </span>
                        ))}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <select
                        className="form-control"
                        onChange={(e) => setExpYear(e.target.value)}
                      >
                        <option>- Select year</option>
                        {getYearsArr().map((y: number) => (
                          <option key={y}>{y}</option>
                        ))}
                      </select>
                      {(errors && !expYear) ||
                        (errors && expYear === "- Select year" && (
                          <span className="input-error" style={errorsStyle}>
                            Year is required!
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 15,
                    flexWrap: "wrap",
                  }}
                >
                  <div>
                    <label style={labelStyle}>CVV2 / CVC2</label>
                    <input
                      type="text"
                      className="form-control"
                      value={cvv}
                      onChange={(e) => setCvv(e.target.value)}
                    />
                    {errors && !cvv && (
                      <span className="input-error" style={errorsStyle}>
                        CVV is required!
                      </span>
                    )}
                  </div>

                  <div>
                    <label style={labelStyle}>Zip Code</label>
                    <input
                      type="text"
                      className="form-control"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </> : null}

            {paymentMethod ? <button
              className="btn"
              style={btnStyle}
              onClick={onCompletePayment}
            >
              {payTransactionLoader ?
                <Loader size={30} /> :
                `${paymentMethod === "ON_DEMAND" ? "Complete payment" : "Complete"}`}
            </button> : null}
          </div>
        </div>
        <div>
          <CheckoutSideBox />
        </div>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Payments;
