import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useRef, useState } from "react";
import axios from "axios";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

import { helloSignClient } from "../../../App";
import { GENERATE_HELLOSIGN_URL } from "../../../graphql/mutations/documents/generate-hellosign-url.mutation";
import { GET_DOC_UPLOAD_URL } from "../../../graphql/mutations/documents/get-document-upload-url-.query";
import { GET_DOCUMENT_URL } from "../../../graphql/mutations/documents/get-document-url.query";
import { NOTIFY_DOC_UPLOADED } from "../../../graphql/mutations/documents/notify-file-uploaded.mutation";
import { GET_TRANSACTION } from "../../../graphql/queries/transaction/get-transaction.query";
import { CHECK_SIGN_DOCUMENT } from "../../../graphql/queries/documents/check-sign-doc.query";
import { AlertColor, Snackbar, Tooltip } from "@mui/material";
import Alert from "../../alert/alert.component";
import {
  isFileUploaded,
  normelizeStatus,
} from "../post-transaction-details.helper";
import FilesLoader from "../../files-loader/files-loader.component";
import DocumentsFooterTableButtons, {
  ObjRef,
} from "../documents-footer-table-buttons/documents-footer-table-buttons";

const thStyle = {
  color: "#999",
  fontFamily: "CerebriSans-SemiBold",
  paddingTop: 10,
  paddingBottom: 13,
  padding: 15,
};

type DocumentManagerType = {
  transactionData: any;
};

const DocumentManager: React.FC<DocumentManagerType> = ({
  transactionData,
}) => {
  const [documentUUID, setDocumentUUID] = useState<{ [key: string]: any }>({
    id: "",
    isSignAndCreate: false,
    file: "",
    isSignAndCreateBtnActive: false,
  });
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isPDFFile, setIsPDFFile] = useState(true);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [error, setError] = useState<string | undefined>("");
  const [severityType, setSeverityType] = useState<AlertColor>("error");
  const [transaction, setTransaction] = useState<any>(null);

  React.useEffect(() => {
    setTransaction(transactionData);
  }, [transactionData]);

  const openAlert = (error: any) => {
    setError(error);
    setAlertOpen(true);
  };

  const interval = useRef<any>();

  React.useEffect(() => {
    if (!isFileUploading) {
      clearInterval(interval.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileUploading]);

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const [
    getTransactionFunc,
    { loading: getTransactionLoader, error: getTransactionError },
  ] = useLazyQuery(GET_TRANSACTION, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      setTransaction(data);
      setIsFileUploading(false);
    },
  });

  const [
    chechSignDocFunc,
    { loading: chechSignDocLoader, error: chechSignDocError },
  ] = useLazyQuery(CHECK_SIGN_DOCUMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (d) => {
      if (d?.checkSignedDocument?.status !== "SIGNED") {
        try {
          chechSignDocFunc();
        } catch (err) {
          console.error(err);
        }
      }

      if (d?.checkSignedDocument?.status === "SIGNED") {
        try {
          setSeverityType("success");
          openAlert("Signed successful! Please refresh the page!");
          setTimeout(
            () => window.location.reload(),
            // getTransactionFunc({
            //   variables: {
            //     transactionUUID: transactionData?.getTransaction?.uuid,
            //   },
            // }),
            0
          );
        } catch (err) {
          console.error(err);
        }
      }
    },
  });

  const [
    generateHelloSignFunc,
    { loading: generateHelloSignLoader, error: generateError },
  ] = useMutation(GENERATE_HELLOSIGN_URL, {
    variables: {
      documentUUID: documentUUID.id,
      transactionUUID: transactionData?.getTransaction?.uuid,
    },
    onCompleted: (data: any) => {
      helloSignClient.open(data?.generateHellosignURL);
      helloSignClient.on("sign", (d) => {
        interval.current = setInterval(() => {
          setIsFileUploading(true);
          chechSignDocFunc({
            variables: {
              transactionUUID: transactionData?.getTransaction?.uuid,
              documentUUID: documentUUID.id,
            },
          });
        }, 2000);
      });
    },
  });

  const [
    notifyDocUploadedFunc,
    { loading: notifyDocUploadedLoader, error: notifyError },
  ] = useMutation(NOTIFY_DOC_UPLOADED, {
    variables: {
      documentUUID: documentUUID.id,
      transactionUUID: transactionData?.getTransaction?.uuid,
    },
    refetchQueries: [
      {
        query: GET_TRANSACTION,
        variables: { transactionUUID: transactionData?.getTransaction?.uuid },
      },
    ],
    onCompleted: (d) => {
      if (d?.notifyDocumentUploaded?.status === "UPLOADED") {
        if (!documentUUID?.isSignAndCreateBtnActive) {
          setSeverityType("success");
          openAlert("File Uploaded! Please refresh the page!");
          setTimeout(
            () =>
              getTransactionFunc({
                variables: {
                  transactionUUID: transactionData?.getTransaction?.uuid,
                },
              }),
            0
          );
        } else {
          generateURL();
        }
      } else {
        notifyDocUploadedFunc();
      }
    },
  });

  const [
    getDocUploadUrlFunc,
    { loading: getDocUploadUrlLoader, error: getDocError },
  ] = useMutation(GET_DOC_UPLOAD_URL, {
    variables: {
      documentUUID: documentUUID.id,
      transactionUUID: transactionData?.getTransaction?.uuid,
      documentFileType: isPDFFile ? "PDF" : "PNG",
    },
    onCompleted: (data: any) =>
      setFileForUpload(documentUUID.file, data?.generateDocumentUploadURL),
  });

  const [getDocURLFunc, { loading: getDocURLLoader, error: getDocURLError }] =
    useLazyQuery(GET_DOCUMENT_URL);

  React.useEffect(() => {
    if (!documentUUID.id) return;
    if (documentUUID?.isSignAndCreateBtnActive) {
      getDocUploadUrlFunc();
      return;
    }
    if (documentUUID.isSignAndCreate) {
      generateURL();
    } else {
      getDocUploadUrlFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentUUID]);

  const onCreateAndSign = (d: string) => {
    const newObjRef = {
      id: d,
      isSignAndCreate: true,
      isSignAndCreateBtnActive: false,
    };
    setDocumentUUID(newObjRef);
  };

  const generateURL = async () => {
    try {
      await generateHelloSignFunc();
    } catch (err) {
      console.error(err);
    }
  };

  const onUploadFile = async (d: string) => {
    document.getElementById(d)?.click();
  };

  const onFileChange = (event: any, fileUUID: string) => {
    const fileSize = event.target.files[0].size / 1024;
    if (fileSize / 1000 <= 5) {
      setIsFileUploading(true);
      // Update the state
      const newObjRef = {
        id: fileUUID,
        isSignAndCreate: false,
        file: event.target.files[0],
        isSignAndCreateBtnActive: false,
      };
      setDocumentUUID(newObjRef);
    } else {
      openAlert("It is not possible to upload files larger than 5MB");
    }
    event.target.value = null;
  };

  const setFileForUpload = async (file: File, url: string) => {
    if (!file) return;
    const formData = new FormData();

    // Update the formData object
    formData!.append(file?.name, file, file?.name);
    setIsPDFFile(!file.type.includes("image"));

    axios({
      method: "put",
      url,
      data: file,
      headers: { "Content-Type": file.type ?? `application/pdf` },
    })
      .then(async (d) => {
        if (d?.status === 200) {
          try {
            await notifyDocUploadedFunc();
          } catch (err) {
            console.error(err);
          }
        }
      })
      .catch((error: any) => {
        setSeverityType("error");
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(error.response.data);
          openAlert(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error(error.request);
          openAlert(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
          openAlert(error.message);
        }
      });
  };

  const onReadUploadedFile = async (fileUUID: string) => {
    await getDocURLFunc({
      variables: {
        documentUUID: fileUUID,
        transactionUUID: transactionData?.getTransaction?.uuid,
      },
      onCompleted: (d: any) => window.open(d.getDocumentURL),
    });
  };

  React.useEffect(() => {
    setSeverityType("error");
    if (getTransactionError?.message) {
      openAlert(getTransactionError?.message);
    }
    if (chechSignDocError?.message) {
      // openAlert(chechSignDocError?.message);
    }
    if (getDocURLError?.message) {
      openAlert(getDocURLError?.message);
    }
    if (generateError?.message) {
      openAlert(generateError?.message);
    }
    if (notifyError?.message) {
      openAlert(notifyError?.message);
    }
    if (getDocError?.message) {
      openAlert(getDocError?.message);
    }
  }, [
    getTransactionError,
    chechSignDocError,
    getDocURLError,
    generateError,
    notifyError,
    getDocError,
  ]);

  if (
    getDocURLLoader ||
    notifyDocUploadedLoader ||
    getDocUploadUrlLoader ||
    generateHelloSignLoader ||
    getTransactionLoader ||
    chechSignDocLoader ||
    isFileUploading
  ) {
    return (
      <>
        <FilesLoader />
      </>
    );
  }

  const updateDocumentUUID = (documentUUID: ObjRef) =>
    setDocumentUUID({ ...documentUUID, isSignAndCreateBtnActive: false });

  const updateSignedDocUUID = (documentUUID: ObjRef) => {
    setDocumentUUID({ ...documentUUID, isSignAndCreateBtnActive: true });
  };

  return (
    <div style={{ marginTop: 30 }}>
      <h1
        style={{
          fontFamily: "CerebriSans-Bold",
          fontSize: 28,
        }}
      >
        Supporting documents
      </h1>
      <div>
        <table style={{ width: "100%", tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th style={thStyle}>Status</th>
              <th style={thStyle}>Document name</th>
              <th style={thStyle}>Details</th>
              <th style={thStyle}>Options</th>
            </tr>
          </thead>
          <tbody>
            {transaction?.getTransaction?.documents?.map((d: any, i: number) => (
              <tr style={{ borderBottom: "solid 1px #ddd" }} key={i}>
                <td style={{ padding: 15 }}>
                  <Tooltip placement="top" title={normelizeStatus(d?.status)}>
                    {isFileUploaded(d.status) ? (
                      <CheckCircleOutlinedIcon color="success" />
                    ) : (
                      <ErrorOutlineOutlinedIcon color="error" />
                    )}
                  </Tooltip>
                </td>
                <td style={{ padding: 15 }}>{d.name}</td>
                <td style={{ padding: 15 }}>
                  {d.status === "PENDING" && "Not provided"}
                  {d.status !== "PENDING" &&
                    d.status !== "SIGNED" &&
                    "Uploaded: " + d.name}
                  {d.status !== "PENDING" &&
                    d.status === "SIGNED" &&
                    "Signed: " + d.name}
                </td>
                <td style={{ padding: 15 }}>
                  {!d?.documentType?.signRequired && d.status === "PENDING" && (
                    <>
                      <input
                        type="file"
                        accept="image/*,.pdf"
                        id={d?.uuid}
                        style={{ display: "none" }}
                        onChange={(e) => onFileChange(e, d?.uuid)}
                      />
                      <button
                        id="button"
                        name="button"
                        value="Upload"
                        className="btn"
                        onClick={() => onUploadFile(d?.uuid)}
                      >
                        Upload
                      </button>
                    </>
                  )}
                  {!d?.documentType?.signRequired && d.status === "UPLOADED" && (
                    <div>
                      <FileOpenIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => onReadUploadedFile(d?.uuid)}
                      />
                    </div>
                  )}
                  {d?.documentType?.signRequired && d.status === "PENDING" && (
                    <button
                      className="btn"
                      onClick={() => {
                        onCreateAndSign(d?.uuid);
                      }}
                      disabled={isFileUploading}
                    >
                      Create & Sign
                    </button>
                  )}
                  {d?.documentType?.signRequired && d.status === "SIGNED" && (
                    <FileOpenIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => onReadUploadedFile(d?.uuid)}
                    />
                  )}
                </td>
              </tr>
            ))}
            <DocumentsFooterTableButtons
              transactionUUID={transactionData?.getTransaction?.uuid}
              openAlert={openAlert}
              setDocumentUUID={updateDocumentUUID}
              updateSignedDocUUID={updateSignedDocUUID}
              setIsFileUploading={(isLoader: boolean) =>
                setIsFileUploading(isLoader)
              }
              transaction={transactionData?.getTransaction}
            />
          </tbody>
        </table>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={closeAlert}
          severity={severityType}
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DocumentManager;
