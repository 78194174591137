import moment from "moment";
import React from "react";

type NotesType = {
  notes: any;
};

//#d3d3d352

const Notes: React.FC<NotesType> = ({ notes }) => {
  const notesFetched = notes?.map((n: any) => (
    <div style={{ background: "#d3d3d352", padding: 15, marginBottom: 10 }}>
      <b>
        {n?.author?.firstName} {n?.author?.lastName}
      </b>
      <p>{n?.content}</p>
      <span
        style={{ fontSize: "small", fontWeight: "bold", color: "#808080a8" }}
      >
        {moment(n?.createdAt).format("DD/MM/YYYY HH:mm")}
      </span>
    </div>
  ));
  return <>{notesFetched}</>;
};

export default Notes;
