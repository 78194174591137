export const faqDesc = {
  title: "Medallion Signature Guarantee FAQ",
  desc: "eSignatureGuarantee is a unique, patent pending online platform, which allows stockholders to obtain a Signature Guarantee for the transfer of securities.",
};

export const sideBoxContent = [
  "About Signature Guarantees",
  "Stocks & Securities",
  "Medallion stamp info",
  "Payment & Shipping",
  "Mobile users?",
];

export const contentData = [
  [
    {
      title: "What is a Signature Guarantee?",
      desc: "A signature guarantee verifies the signature of a person making a request to transfer physical certificates of financial instruments such as securities like stocks. It is a form of authentication provided by financial institutions to prevent fraud, speed the legal transfer of securities, and protect transfer agents from the legal consequences of a fraudulent signature. Signature guarantees can only be provided by members of official securities guarantee programs like Medallion. Learn more.",
    },
    {
      title: "Why You Need a Signature Guarantee?",
      desc: "If you own, or come into ownership of, physical securities certificates such as stock certificates, and want to buy, sell or transfer them to another individual or change the ownership name, you will need a signature guarantee. Learn more.",
    },
    {
      title: "What does a Signature Guarantee protect?",
      desc: "A signature guarantee with medallion stamp, called a Medallion Signature Guarantee, protects the shareholder, the recipient of the shares and the transfer agent responsible for processing the transaction. It is a form of authentication required to prevent identify theft and protect all parties involved in the transfer from the financial and legal consequences of fraud. Learn more.",
    },
    {
      title: "Where can I get a Medallion Signature Guarantee?",
      desc: "Fewer and fewer banks offer this service--and in most cases--only for account holders. This list here provides an overview of major banks and whether they offer a medallion signature guarantee at their local branches, as well as an alternative for getting your medallion stamp online through eSignature Guarantee. Learn more.",
    },
  ],
  [
    {
      title:
        "Do I need to send my stock certificate and, if so, how should I send it to you?",
      desc: "As part of our process, after your identification has been verified, you will be given the option to work with us via “digital signatures” in which case you can upload documents to us OR you can send us “pen to paper” signatures in which case we will provide you a link to print a prepaid Fedex label to send us a package with your originally signed documents and your stock certificate. After we stamp your document with our signature guarantee, we will Fedex the certificate back to you or your transfer agent, as instructed by you.",
    },
    {
      title:
        "Can I get a medallion signature guarantee on a 401K document, annuities transfer form or other items other than a stock certificate?",
      desc: "Yes! We can work with most documents related to securities such as funds transfer, 401k paperwork, annuities, brokerage account forms, etc. We can also work with you regardless if your stock is publicly traded or is privately held.",
    },
    {
      title:
        "I understand that I can get a medallion signature guarantee on a physical certificate, but can you help me if I own my shares in electronic format held at the transfer agent?",
      desc: "Yes – great question! We can provide you with a signature guarantee for either process. We can provide a signature guarantee on a physical certificate, transfer agent form or stock power to indicate ownership held in electronic format at the stock transfer agent.",
    },
    {
      title: "What if I own my stock as a corporate entity?",
      desc: "No problem. Once you log in, you will see an option to proceed as an entity, corporation, trustee or executor. Just follow the directions.",
    },
    {
      title:
        "A family member is deceased, can you still process a medallion stamp?",
      desc: "We are sorry to hear of your loss. Yes, we can assist. If the shareholder is deceased, we would need to verify the signature of the Executor or Trustee. We would need documents such as Letters Testamentary, certified copy of the death certificate and copy of executor's driver's license.Read more about – What is a Testamentary Transfer – on our blog.",
    },
  ],
  [
    {
      title: "Which Transfer Agents accept your medallion signature guarantee?",
      desc: "Great question! All of them would (and do) accept our medallion signature guarantee since our guarantee is the same type as provided by other banks and financial institutions.",
    },
    {
      title:
        "What is the value limit of the medallion guarantee that you provide?",
      desc: "$500,000.",
    },
    {
      title:
        "What do you mean when you say I can provide you with a “digital” or “electronic” signature?",
      desc: "According to the U.S. Federal ESIGN Act passed in 2000, an electronic signature is an electronic sound, symbol, or process, attached to or logically associated with a contract or other record and executed or adopted by a person with the intent to sign the record.",
    },
    {
      title: "How does your electronic signature solution work?",
      desc: "We work with a leader in the industry, HelloSign, to provide you with the highest level of comfort, technology, security and audit trail. We cover all of the HelloSign fees. There are a few ways to create an electronic signature on our platform: Option 1. Draw your signature using your computer mouse or touchpad. Option 2. Take a photo of your signature using your smartphone and upload it to HelloSign. Option 3. Type your name and select any one of our fonts to give your typed signature an authentic touch. Once created, an electronic signature can be added to any document along with other annotations such as check marks or freeform text.",
    },
    {
      title:
        "Can I provide eSignature Guarantee with an electronic signature on my stock power?",
      desc: "You should check with your financial institution if they will accept a digital signature. We have added a Stock Power template to our platform that will allow you to download a generic stock power, complete it on your screen, sign it electronically and then submit it to us for a physical medallion signature guarantee which we will Fedex as you instruct us to. The Securities Transfer Association in their guidelines have stated that an electronic signature on the transfer instructions are acceptable if the medallion signature guarantee on such instruction is an original; provided, however, if the transaction is valued at over $14 million, the STA recommends requiring the transfer instruction and signature to be an original. Some annuities, insurance companies or other financial institutions may have internal policies that require “pen to paper” signatures so please consult with them as such.",
    },
  ],
  [
    {
      title: "How much does this service cost?",
      desc: "Our fee is $149 per medallion stamp. View our pricing page for more details on the Medallion Signature Guarantee Cost.",
    },
    {
      title: "What type of payments do you accept?",
      desc: "We accept all major credit cards.",
    },
    {
      title:
        "How long will it take to get my stock certificate back with a Signature Guarantee?",
      desc: "At eSignatureGuarantee.com, we pride ourselves on responsiveness, a thorough compliance review and quick turnaround time. Your certificate will be sent to us via Fedex. We aim to review and process your transaction with same day service and certificates will then be sent back to you via Fedex overnight delivery. You have the ability to instruct us where you would like your certificates delivered to i.e. your home, office, broker, etc.",
    },
    {
      title: "Where will you send the document with the Signature Guarantee?",
      desc: "We will send it via courier wherever you instruct us to. Most clients either have us return it to the original sender or, to save time and money, will instruct us to send the document (with the completed signature guarantee) directly to their stock transfer agent for further processing.",
    },
  ],
  [
    {
      title: "Can I use my mobile device on your site?",
      desc: "You can use your mobile device to read about our services, create an account, and validate your identity. However, at this time you will need to switch to a laptop/desktop or large-screen tablet to complete the medallion process. We are currently developing a mobile site to accommodate all screen sizes.",
    },
  ],
];
