export const getMonthsArr: string[] = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const getYearsArr = () => {
  const minYear = new Date().getFullYear();
  const maxYear = minYear + 10;

  let yearsArr = [];

  for (let i = minYear; i < maxYear; i++) {
    yearsArr.push(i);
  }

  return yearsArr;
};

const expirationInvalidInputs = ["- Select month", "- Select year"];

export const verifyCardInputs = (
  cardHolder: string,
  cardNumber: string,
  expMonth: string,
  expYear: string,
  cvv: string,
  zip: string
) => {
  if (
    expirationInvalidInputs.includes(expMonth) ||
    expirationInvalidInputs.includes(expYear)
  )
    return false;
  return (
    !!cardHolder && !!cardNumber && !!expMonth && !!expYear && !!cvv && !!zip
  );
};
