/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const GET_FEDEX_RATE = gql`
  query getFedexRate($fedexServiceUUID: String!, $addressUUID: String!) {
    getFedexRate(
      addressUUID: $addressUUID
      fedexServiceUUID: $fedexServiceUUID
    ) {
      serviceType
      packagingType
      totalDiscounts
      totalBaseCharge
      totalNetCharge
      totalNetFedExCharge
    }
  }
`;
