import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { NestedValue, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import "./create-transaction.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  isHomeHeader,
  readyForSecondStepCheckout,
  setMedailionType,
  setOwnerShipType,
  setTransactionMode,
  setTransactionsTypes,
} from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { options } from "../transactions-steps/transactions-steps";
import { CREATE_TRANSACTION } from "../../graphql/mutations/transaction/create-transaction.mutation";
import {
  FormatEnum,
  SecurityTypeEnum,
  StockholderTypeEnum,
} from "../../types/interfaces/create-transaction.interfaces";
import Loader from "../loader/loader.component";
import { TransactionCreated } from "../../graphql/interfaces/transaction.interfaces";
import PageOneSideBox from "../checkout-side-box/page-one-sidebox.component";
import { RootState } from "../../redux/reducers";
import { EDIT_TRANSACTION } from "../../graphql/mutations/transaction/edit-transaction.mutation";
import { Snackbar } from "@mui/material";
import Alert from "../alert/alert.component";

const CreateTransaction = () => {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");

  const openAlert = (error: any) => {
    setError(error);
    setAlertOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transaction = useSelector<RootState, RootState["transaction"]>(
    (state) => {
      return state.transaction;
    }
  );

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<{
    "radio-buttons-first-group": NestedValue<any>;
    "radio-buttons-second-group": NestedValue<any>;
  }>({
    defaultValues: {
      "radio-buttons-first-group":
        transaction.transactionUUID && transaction.stockholderType
          ? StockholderTypeEnum[transaction.stockholderType]
          : StockholderTypeEnum.PRIVATE,
      "radio-buttons-second-group":
        transaction.transactionUUID && transaction.securityType
          ? SecurityTypeEnum[transaction.securityType]
          : SecurityTypeEnum.PUBLIC,
    },
  });

  const [createTransactionFunc, { loading, error: createTransactionError }] =
    useMutation(CREATE_TRANSACTION, {
      variables: {
        stockholderType: getValues("radio-buttons-first-group"),
        securityType: getValues("radio-buttons-second-group"),
        format: FormatEnum.ELECTRONIC,
      },
      onCompleted: (data: TransactionCreated) => {
        dispatch(setTransactionMode(data.createTransaction?.uuid));
        dispatch(
          setTransactionsTypes({
            stockholderType: data.createTransaction?.stockholderType,
            securityType: data.createTransaction?.securityType,
          })
        );
      },
    });

  const [editTransactionFunc, { loading: editLoader, error: editError }] =
    useMutation(EDIT_TRANSACTION, {
      variables: {
        transactionUUID: transaction.transactionUUID,
        stockholderType: getValues("radio-buttons-first-group"),
        securityType: getValues("radio-buttons-second-group"),
      },
      onCompleted: (data: any) => {
        dispatch(setTransactionMode(data.editTransaction?.uuid));
        dispatch(
          setTransactionsTypes({
            stockholderType: data.editTransaction?.stockholderType,
            securityType: data.editTransaction?.securityType,
          })
        );
      },
    });

  React.useEffect(() => {
    if (createTransactionError?.message) {
      openAlert(createTransactionError?.message);
    }
    if (editError?.message) {
      openAlert(editError?.message);
    }
  }, [createTransactionError, editError]);

  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(readyForSecondStepCheckout(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    register("radio-buttons-first-group", {
      validate: (value) => value.length || "Please select one option.",
    });
    register("radio-buttons-second-group", {
      validate: (value) => value.length || "Please select one option.",
    });
  }, [register]);

  const StockholderType: StockholderTypeEnum[] = [
    StockholderTypeEnum.PRIVATE,
    StockholderTypeEnum.CORPORATE,
    StockholderTypeEnum.DECEASED,
  ];

  const securityTypes: SecurityTypeEnum[] = [
    SecurityTypeEnum.PUBLIC,
    SecurityTypeEnum.PRIVATE,
    SecurityTypeEnum.IRA_401_FUNDS_BONDS,
  ];

  const onSubmit = handleSubmit(async (data) => {
    try {
      await createTransactionReq();
      dispatch(setMedailionType(data["radio-buttons-second-group"]));
      dispatch(setOwnerShipType(data["radio-buttons-first-group"]));
      dispatch(readyForSecondStepCheckout(true));
      navigate(options["MEDALLION DETAILS"]);
    } catch (err) {}
  });

  const createTransactionReq = async () => {
    if (!transaction.transactionUUID) {
      await createTransactionFunc();
      return;
    }
    await editTransactionFunc();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        paddingBottom: 50
      }}
    >
      <div style={{ maxWidth: 660 }}>
        <div>
          <h1 className="sub-title">Ownership type</h1>
          <p style={{ maxWidth: 400 }}>
            Different ownership types and different securities have different
            requirements. Click on selections that match your securities to
            ensure you have a streamlined process. <br />
            <br />
            If you are looking for medallions for multiple types of securities,
            you will need to do them one at a time.
            <br />
            <br />
          </p>
          <div className="line-center"></div>
        </div>
        <div className="form-block pull-left">
          <form className="ownership_form" onSubmit={onSubmit}>
            <div className="form-row radio-row">
              <div className="form-group col-md-12 m20">
                <label>Ownership type</label>
                <RadioGroup
                  defaultValue={
                    transaction.transactionUUID && transaction.stockholderType
                      ? StockholderTypeEnum[transaction.stockholderType]
                      : StockholderTypeEnum.PRIVATE
                  }
                >
                  {[
                    "Individual / Joint",
                    "Corporate / LLC",
                    "Executor / Trustee",
                  ].map((v: string, i: number) => (
                    <FormControlLabel
                      {...register("radio-buttons-first-group")}
                      name="radio-buttons-first-group"
                      key={i}
                      value={StockholderType[i]}
                      control={<Radio size="small" />}
                      label={v}
                    />
                  ))}
                </RadioGroup>
                {errors?.["radio-buttons-first-group"] && (
                  <span className="input-error">
                    <div className="fa-icon fa fa-exclamation-circle"></div>
                    {errors?.["radio-buttons-first-group"] && (
                      <p>{errors?.["radio-buttons-first-group"].message}</p>
                    )}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row radio-row">
              <div className="form-group col-md-12 m15">
                <label>Type of security you need medallion for</label>
                <RadioGroup
                  defaultValue={
                    transaction.transactionUUID && transaction.securityType
                      ? SecurityTypeEnum[transaction.securityType]
                      : SecurityTypeEnum.PUBLIC
                  }
                >
                  {[
                    "Public listed stock",
                    "Private listed stock",
                    "IRA, 401K, funds, bonds",
                  ].map((v, i) => (
                    <FormControlLabel
                      {...register("radio-buttons-second-group")}
                      name="radio-buttons-second-group"
                      value={securityTypes[i]}
                      control={<Radio size="small" />}
                      label={v}
                      key={i}
                    />
                  ))}
                </RadioGroup>
                {errors?.["radio-buttons-second-group"] && (
                  <span className="input-error">
                    <div className="fa-icon fa fa-exclamation-circle"></div>
                    {errors?.["radio-buttons-second-group"] && (
                      <p>{errors?.["radio-buttons-second-group"].message}</p>
                    )}
                  </span>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12 mb80">
                <button
                  className="btn btn-continue"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading || editLoader ? <Loader size={30} /> : "Continue"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PageOneSideBox />
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateTransaction;
