import React from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { isHomeHeader } from "../../redux/actions";
import TransactionsSteps from "../transactions-steps/transactions-steps";

const Transactions = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div
        className="row"
        style={{
          marginTop: 20,
          marginBottom: 45,
        }}
      >
        <TransactionsSteps />
      </div>
      <div className="container">
        <div className="row">
          <section className="transaction">
            <div className="col pad content">
              <Outlet />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
