import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { ADD_USER_INFO } from "../../graphql/mutations/user/user.mutations";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import { userAdressTypes } from "../../graphql/types/user.types";
import { getStates } from "../../helpers/account-details";
import Loader from "../loader/loader.component";

type UpdateAccountDetailsType = {
  oldUserDetails: any;
  openAlert: (error: any) => void;
  handleCloseInfoModal: () => void;
  onUpdateUser: () => Promise<void>;
};

const UpdateAccountDetails: React.FC<UpdateAccountDetailsType> = ({
  oldUserDetails,
  openAlert,
  handleCloseInfoModal,
  onUpdateUser,
}) => {
  const [userDetails, setUserDetails] = useState<any>({
    firstName: oldUserDetails?.getUser?.firstName,
    lastName: oldUserDetails?.getUser?.lastName,
    addressLine1: oldUserDetails?.getUser?.home?.addressLine1,
    addressLine2: oldUserDetails?.getUser?.home?.addressLine2,
    city: oldUserDetails?.getUser?.home?.city,
    state: oldUserDetails?.getUser?.home?.state,
    zip: oldUserDetails?.getUser?.home?.zip,
  });

  React.useEffect(() => {
    setUserDetails(oldUserDetails);
  }, [oldUserDetails]);

  const onInputChanged = (e: any) => {
    const { value, name } = e?.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const isBtnDisabled =
    userDetails?.firstName === "" ||
    userDetails?.lastName === "" ||
    userDetails?.addressLine1 === "" ||
    userDetails?.city === "" ||
    userDetails?.state === "" ||
    userDetails?.state === "- Select state -" ||
    userDetails?.zip === "";

  const [addUserInfo, { loading: addUserInfoLoader, error: addUserError }] =
    useMutation(ADD_USER_INFO, {
      variables: { ...userDetails, type: userAdressTypes.HOME },
      refetchQueries: [
        {
          query: GET_USER,
        },
      ],
      onCompleted: (d: any) => {
        onUpdateUser();
        handleCloseInfoModal();
      },
    });

  const onUpdateInfo = async () => {
    try {
      await addUserInfo();
    } catch (err: any) {
      openAlert(err?.message);
    }
  };

  React.useEffect(() => {
    if (addUserError?.message) {
      openAlert(addUserError?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserError]);

  return (
    <form style={{ padding: 45 }}>
      <div className="form-row pad10">
        <div className="form-group col-md-6 m10">
          <label>Legal First name</label>
          <input
            value={userDetails.firstName}
            type="text"
            className="form-control"
            name="firstName"
            onChange={onInputChanged}
          />
        </div>

        <div className="form-group col-md-6 m10">
          <label>Legal Last name</label>
          <input
            value={userDetails.lastName}
            type="text"
            className="form-control"
            name="lastName"
            onChange={onInputChanged}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6 m10">
          <label>Address line 1</label>
          <input
            value={userDetails.addressLine1}
            type="text"
            className="form-control"
            autoComplete="false"
            placeholder="Address Line 1"
            name="addressLine1"
            onChange={onInputChanged}
          />
        </div>

        <div className="form-group col-md-6 m10">
          <label>
            Address line 2 <span className="optional">(optional)</span>
          </label>
          <input
            value={userDetails.addressLine2}
            type="text"
            className="form-control"
            autoComplete="false"
            name="addressLine2"
            onChange={onInputChanged}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6 m10">
          <label>City</label>
          <input
            type="text"
            className="form-control"
            value={userDetails.city}
            autoComplete="false"
            placeholder="City"
            minLength={2}
            name="city"
            onChange={onInputChanged}
          />
        </div>

        <div className="form-group col-md-6 m10">
          <label>State </label>
          <select
            value={userDetails.state}
            className="form-control"
            name="state"
            onChange={onInputChanged}
          >
            <option value="">- Select state -</option>
            {getStates().map((state: string, i: number) => (
              <option key={i} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6 m10">
          <label>Zip code</label>
          <input
            value={userDetails.zip}
            type="text"
            className="form-control"
            minLength={2}
            name="zip"
            onChange={onInputChanged}
          />
        </div>
        <div className="form-group col-md-6 m10">
          <label>Country </label>
          <input
            value={userDetails.country}
            type="text"
            className="form-control"
            readOnly
          />
        </div>
      </div>
      <div className="action-button">
        {addUserInfoLoader ? (
          <Loader size={15} />
        ) : (
          <button
            className="btn btn-update"
            type="button"
            disabled={isBtnDisabled}
            onClick={() => onUpdateInfo()}
          >
            Update information
          </button>
        )}
      </div>
    </form>
  );
};

export default UpdateAccountDetails;
