/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const CREATE_SHIPPING = gql`
  mutation createShipping(
    $transactionUUID: String!
    $addressUUID: String!
    $fedexServiceUUID: String
    $shippingProvider: ShippingProvider!
  ) {
    createShipping(
      transactionUUID: $transactionUUID
      addressUUID: $addressUUID
      fedexServiceUUID: $fedexServiceUUID
      shippingProvider: $shippingProvider
    ) {
      uuid
      from {
        addressLine1
        addressLine2
        city
        state
        zip
        country
        type
      }
      to {
        addressLine1
        addressLine2
        city
        state
        zip
        country
        type
      }
      type
      provider
      costInCents
    }
  }
`;
