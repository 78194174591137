import React from "react";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

import "./home-footer.scss";

const HomeFooter = () => {
  const { data: userData } = useQuery(GET_USER);

  return (
    <footer style={{ paddingTop: 25 }}>
      <div className="container">
        <div className="row">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ flex: 1 }}>
              <img
                alt="esignature guarantee gray logo"
                src="/assets/images/esignature-logo-icon.svg"
                className="img-responsive"
                style={{ width: 30 }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <ul>
                <li>
                  <a rel="canonical" href="/how-it-works">
                    How it works
                  </a>
                </li>
                <li>
                  <a rel="canonical" href="/pricing">
                    Pricing
                  </a>
                </li>
                <li>
                  <a rel="canonical" href="/about">
                    About
                  </a>
                </li>
                <li>
                  <a rel="canonical" href="/faq">
                    FAQs
                  </a>
                </li>
                <li>
                  <a rel="canonical" href="/glossary">
                    Glossary of terms
                  </a>
                </li>
                <li>
                  <a
                    href="https://blog.esignatureguarantee.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </div>
            <div style={{ flex: 1 }}>
              <ul>
                <li>
                  <a rel="canonical" href="/home/signature-guarantee">
                    What is an eSignature?
                  </a>
                </li>
                <li>
                  <a rel="canonical" href="/home/medallion-stamp-guarantee">
                    Medallion signature guarantee vs. Medallion guarantee stamp
                  </a>
                </li>
                <li>
                  <a rel="canonical" href="/home/medallion-stamp">
                    What is a Transfer Agent and why did they just ask me for a
                    medallion?
                  </a>
                </li>
                <li>
                  <a rel="canonical" href="/home/medallion-signature-guarantee">
                    Getting a medallion signature guarantee after the death of a
                    parent or spouse
                  </a>
                </li>
              </ul>
            </div>
            <div style={{ flex: 1 }}>
              <ul>
                <li>
                  <a rel="canonical" href="/contact">
                    Customer service
                  </a>
                </li>
                <li style={{ display: "none" }}>
                  <a href="tel:1-844-782-6763">1-844-782-6763</a>
                </li>
              </ul>
            </div>
            <div style={{ flex: 1 }}>
              <ul>
                {userData?.getUser?.roles[0] ? (
                  <>
                    <li>
                      <a href="/accounts/me">
                        <button className="btn-create-account">
                          My account
                        </button>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/join"
                        className="btn-login"
                        onClick={() => {
                          localStorage.removeItem("token");
                        }}
                      >
                        Log out
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <a href="/join">
                        <button className="btn-create-account">
                          Create account
                        </button>
                      </a>
                    </li>
                    <li>
                      <a href="/login" className="btn btn-login">
                        Log in
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div
            className="footer-bottom"
            style={{ width: "100%", display: "flex" }}
          >
            <div className="col-md-6 pull-left footer-txt">
              © Signature Guarantee Group. All rights reserved.{" "}
              <a rel="canonical" href="/privacy">
                <u>Privacy policy</u>
              </a>{" "}
              |{" "}
              <a rel="canonical" href="/terms">
                <u>Terms of use</u>
              </a>
            </div>
            <div className="col-md-6 social">
              <ul>
                <a href="https://www.facebook.com/people/ESignature-Guarantee/100013027321538">
                  <FacebookIcon color="action" />
                </a>
                <a href="https://twitter.com/eSignatureGroup">
                  <TwitterIcon color="action" />
                </a>
                <a href="https://www.linkedin.com/in/esignatureguarantee/">
                  <LinkedInIcon color="action" />
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default HomeFooter;
