import React from 'react';
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const errorHint = {
    fontFamily: "CerebriSans-Bold",
    padding: 7
}

const paragraphStyle = {
    fontSize: 16,
    color: "#555555",
    fontFamily: "CerebriSans-Book"
};

const btn = {
    background: "#28dab6",
    color: "#fff",
    borderRadius: 2,
    transition: "300ms ease-in-out",
    padding: "0 25px",
    marginTop: 50,
    width: 360,
    fontFamily: "CerebriSans-SemiBold",
    fontSize: 17,
    height: 45,
    border: "none",
}

const LockedPage = () => {
    return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "70vh", }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 8, ...errorHint }}>
                <ErrorOutlineOutlinedIcon color="error" />
                <span>Unable to verify identity</span>
            </div>
            <h1 style={{ fontFamily: "CerebriSans-Bold", color: "#d43f00", fontSize: 28 }}>Incomplete answers</h1>
            <p style={{ ...paragraphStyle, marginTop: 20 }}>
                We're sorry, but you were unable to answer the questions correctly, and we cannot use our online system to verify your identity.
            </p>
            <span style={paragraphStyle}>Click on the button below to contact us directly, so we can verify your identity and allow you to get a medallion stamp.</span>
        </div>
        <button style={btn}><a href="/contact" style={{ textDecoration: "none" }}>Contact us</a></button>
    </div>

};

export default LockedPage;