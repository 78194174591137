/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const LIST_TRANSACTIONS = gql`
  query listTransactions($page: Float) {
    listTransactions(pagination: { limit: 10, page: $page }) {
      items {
        uuid
        code
        stockholderType
        format
        securityType
        createdAt
        status
        certificates {
          uuid
          symbol
          medallionsCount
          pendingMedallionsCount
          approvedMedallionsCount
          deniedMedallionsCount
        }
        notes {
          uuid
          content
          createdAt
        }
        transferAgent {
          uuid
          name
        }
        shippings {
          uuid
          from {
            addressLine1
            addressLine2
            state
            zip
          }
          to {
            addressLine1
            addressLine2
            state
            zip
          }
          type
          provider
          createdAt
        }
        discounts {
          code
          valueType
          value
        }
        charge {
          status
          type
          provider
          totalDiscountsInCents
          totalMedallionCostInCents
          totalShippingCostInCents
        }
      }
      total
      hasMore
    }
  }
`;
