import React from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Snackbar } from "@mui/material";

import { GET_TRANSACTION } from "../../graphql/queries/transaction/get-transaction.query";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import { RootState } from "../../redux/reducers";
import CheckoutSideBox from "../checkout-side-box/checkout-side-box.component";
import { isHomeHeader } from "../../redux/actions";
import { GE_COVER_LETTER_URL } from "../../graphql/queries/documents/get-cover-letter.query";
import Alert from "../alert/alert.component";
import Loader from "../loader/loader.component";
import Survey from "../survey/survey.component";
import Modal from "../modal/modal.component";
import { GET_FEDEX_LABEL } from "../../graphql/queries/shipping/get-defex-label.query";

const fontStyle = {
  fontFamily: "CerebriSans-SemiBold",
};

const btnStyle = {
  background: "#28dab6",
  color: "#fefefe",
  borderRadius: 2,
  padding: "0 20px 4px",
  width: "100%",
  fontFamily: "CerebriSans-SemiBold",
  fontSize: 15,
  height: 38,
  display: "inline-block",
  marginTop: 20,
};

const TransactionComplete = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");
  const [modalOpen, setModalOpen] = React.useState(false);

  const openAlert = (error: any) => {
    setError(error);
    setOpen(true);
  };

  // make sure to redirect the user in case no active transaction

  const { data: transactionData, loading, error: transactionError } = useQuery(GET_TRANSACTION, {
    variables: {
      transactionUUID: id,
    },
    onCompleted: data => {
      if (!data?.getTransaction) {
        navigate("/home", { replace: true });
      }
    }
  });
  const { data: userData } = useQuery(GET_USER, {
    onCompleted: (data: any) => {
      const isSurveyAnswered = data?.getUser?.isSurveyAnswered;
      if (data?.getUser && !isSurveyAnswered) {
        setModalOpen(true);
      }
    },
  });

  const [
    getCovverLetterFunc,
    { loading: getCoverLetterUrlLoader, error: gclError },
  ] = useLazyQuery(GE_COVER_LETTER_URL, {
    variables: {
      transactionUUID: id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      if (data?.getCoverLetterUrl) {
        window.open(data?.getCoverLetterUrl, "_blank");
      }
    },
  });

  React.useEffect(() => {
    if (gclError?.message) {
      openAlert(gclError?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gclError]);

  const onPrintCoverLetter = async () => {
    await getCovverLetterFunc();
  };

  const onPrintFedexLabel = async () => {
    await getFedexLabel();
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [getFedexLabel, { loading: getFedexLabelLoader, error: gflError }] =
    useLazyQuery(GET_FEDEX_LABEL, {
      variables: {
        transactionUUID: id,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        if (data?.getFedexLabelUrl) {
          window.open(data?.getFedexLabelUrl, '_blank');
        }
      },
    });

  React.useEffect(() => {
    if (transactionError?.message) {
      openAlert(transactionError?.message);
      navigate("/home", {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionError]);

  return (
    <>
      <div className="container">
        {loading ?
          <div style={{ height: "80vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Loader />
          </div>
          :
          <div
            className="transaction-complete"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginTop: 50,
            }}
          >
            <div style={{ maxWidth: 600 }}>
              <h1 style={fontStyle}>Transaction Complete!</h1>

              <h4 style={{ paddingTop: 12, ...fontStyle }}>
                Transaction number: {transactionData?.getTransaction?.code}
              </h4>

              <p
                style={{
                  paddingTop: 12,
                  color: "#262626",
                  fontSize: 16,
                  margin: "0 0 10px",
                }}
              >
                Thank you for your order. We have sent a confirmation to your
                email,
                {userData?.getUser?.email}. To complete the process and receive
                your medallion signature guarantee stamp, follow the FedEx
                shipping directions below.
              </p>

              <button
                className="btn"
                style={{ ...btnStyle, width: "50%" }}
                onClick={() => {
                  navigate(`/details/${id}`, {
                    replace: true,
                  });
                }}
              >
                Add Supporting Documents
              </button>

              <h4 style={{ paddingTop: 40, ...fontStyle }}>Your next steps:</h4>
              <ol style={{ paddingTop: 22, ...fontStyle }}>
                <li style={{ ...fontStyle, fontSize: 20 }}>
                  {transactionData?.getTransaction?.deliveryMethod === "FEDEX_2_DAY" || transactionData?.getTransaction?.deliveryMethod === "STANDARD_OVERNIGHT"
                    ? "Print FedEx label and eSignature Guarantee cover letter"
                    : "ONLY if you will be mailing us the required documents - Please print the Cover Letter included in the confirmation email"}
                  <div
                    style={{ display: "block", fontSize: 14, fontWeight: 400 }}
                  >
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {transactionData?.getTransaction?.deliveryMethod === "FEDEX_2_DAY" && (
                        <button
                          className="btn"
                          style={{ ...btnStyle, maxWidth: 160, marginRight: 15 }}
                          onClick={onPrintFedexLabel}
                        >
                          {getFedexLabelLoader ? <Loader size={20} /> : 'Print FedEx Label'}
                        </button>
                      )}
                      {transactionData?.getTransaction?.deliveryMethod === "STANDARD_OVERNIGHT" && (
                        <button
                          className="btn"
                          style={{ ...btnStyle, maxWidth: 160, marginRight: 15 }}
                          onClick={onPrintFedexLabel}
                        >
                          {getFedexLabelLoader ? <Loader size={20} /> : 'Print FedEx Label'}
                        </button>
                      )}
                    </div>
                  </div>
                </li>

                <li style={{ ...fontStyle, fontSize: 20, paddingTop: 22 }}>
                  Package support documents
                  <div style={{ display: "block", fontSize: 14 }}>
                    In order to process your medallion signature guarantee stamp,
                    we need you to ship us the following documents.
                  </div>
                  <ul style={{ marginTop: 10 }}>
                    <li>
                      Original stock certicate (or statement of ownership from the
                      transfer agent)
                    </li>
                    <li>Drivers license (scan or photocopy)</li>
                    <li>Signed Stock Power agreement (or transfer forms)</li>
                  </ul>
                </li>

                {transactionData?.getTransaction?.format === "ELECTRONIC" ? (
                  <li style={{ ...fontStyle, fontSize: 20 }}>
                    Ship package to us
                    <div
                      style={{ display: "block", fontSize: 14, fontWeight: 400 }}
                    >
                      Using the mail courier of your choice, send the supporting
                      documents to:
                    </div>
                    <div
                      style={{ display: "block", fontSize: 14, fontWeight: 400 }}
                    >
                      eSignature Guarantee
                    </div>
                    <div
                      style={{ display: "block", fontSize: 14, fontWeight: 400 }}
                    >
                      18 Lafayette Place
                    </div>
                    <div
                      style={{ display: "block", fontSize: 14, fontWeight: 400 }}
                    >
                      Woodmere, NY 11598
                    </div>
                  </li>
                ) : (
                  <li style={{ ...fontStyle, fontSize: 20, paddingTop: 22 }}>
                    Drop off FedEx package or schedule pickup
                  </li>
                )}
              </ol>
              <button
                className="btn"
                style={{ ...btnStyle, width: "50%", marginBottom: 25 }}
                onClick={() => {
                  navigate(`/details/${id}`, {
                    replace: true,
                  });
                }}
              >
                Add Supporting Documents
              </button>
            </div>
            <div>
              <CheckoutSideBox />
            </div>
          </div>
        }
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <Modal open={modalOpen} onClose={handleCloseModal} maxWidth="md">
        <Survey handleClose={handleCloseModal} openAlert={openAlert} />
      </Modal>
    </>
  );
};

export default TransactionComplete;
