/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const GET_USER = gql`
  query {
    getUser {
      firstName
      lastName
      email
      roles
      paymentMethod
      status
      isSurveyAnswered
      isEmailVerified
      dayOfBirth
      mobile {
        uuid
        countryCode
        localNumber
        isVerified
      }
      home {
        uuid
        addressLine1
        addressLine2
        zip
        country
        state
        city
      }
      allowReceiveMarketingEmails
      allowReceiveSms
    }
  }
`;
