import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/reducers";

export const options: { [key: string]: string } = {
  "OWNERSHIP TYPE": "/transactions/create/start",
  "MEDALLION DETAILS": "/transactions/create/certificate",
  CHECKOUT: "/transactions/create/checkout",
};

const TransactionsSteps = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    switch (location.pathname) {
      case options["OWNERSHIP TYPE"]:
        setValue(0);
        break;
      case options["MEDALLION DETAILS"]:
        setValue(1);
        break;
      case options["CHECKOUT"]:
        setValue(2);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const readyForSecondStepCheckout = useSelector<
    RootState,
    RootState["checkout"]["readyForSecondStepCheckout"]
  >((state) => {
    return state.checkout.readyForSecondStepCheckout;
  });

  const readyForThirdStepCheckout = useSelector<
    RootState,
    RootState["checkout"]["readyForThirdStepCheckout"]
  >((state) => {
    return state.checkout.readyForThirdStepCheckout;
  });

  const handleClick = (value: string) => {
    navigate(options[value], { replace: true });
  };

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    handleClick(event.target.innerText);
  };

  const style = {
    width: 256,
    fontSize: 12,
    fontFamily: "CerebriSans-SemiBold",
    color: "#28dab6",
    outline: "none",
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <Tabs
        onChange={handleChange}
        value={value}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab style={style} label="Ownership Type" />
        <Tab
          style={style}
          label="Medallion Details"
          disabled={!readyForSecondStepCheckout}
        />
        <Tab
          style={style}
          label="Checkout"
          disableFocusRipple
          disabled={!readyForThirdStepCheckout}
        />
      </Tabs>
    </Box>
  );
};

export default TransactionsSteps;
