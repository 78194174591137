/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const GENERATE_HELLOSIGN_URL = gql`
  mutation generateHellosignURL(
    $documentUUID: String!
    $transactionUUID: String!
  ) {
    generateHellosignURL(
      transactionUUID: $transactionUUID
      documentUUID: $documentUUID
    )
  }
`;
