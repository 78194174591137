/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const PAY_TRANSACTION = gql`
  mutation payTransaction(
    $transactionUUID: String!
    $digits: String!
    $creditCard: CreditCardInput
  ) {
    payTransaction(
      transactionUUID: $transactionUUID
      digits: $digits
      creditCard: $creditCard
    ) {
      uuid
      code
      stockholderType
      format
      securityType
      createdAt
      status
      certificates {
        uuid
        symbol
        medallionsCount
      }
      notes {
        uuid
        content
        createdAt
      }
      transferAgent {
        uuid
        name
      }
      shippings {
        uuid
        from {
          addressLine1
          addressLine2
          state
          zip
        }
        to {
          addressLine1
          addressLine2
          state
          zip
        }
        type
        provider
        createdAt
      }
      discounts {
        code
        valueType
        value
      }
      charge {
        status
        type
        provider
        totalDiscountsInCents
        totalMedallionCostInCents
        totalShippingCostInCents
      }
      documents {
        uuid
        name
        status
        documentType {
          type
          signRequired
        }
      }
    }
  }
`;
