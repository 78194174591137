import React from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { ListTransactions } from "../../graphql/interfaces/transaction.interfaces";
import { LIST_TRANSACTIONS } from "../../graphql/queries/transaction/list-transaction.query";
import AllTransactions from "../all-transactions/all-transactions";
import Loader from "../loader/loader.component";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import { useDispatch } from "react-redux";
import { isHomeHeader } from "../../redux/actions";
import { Snackbar } from "@mui/material";
import Alert from "../alert/alert.component";
import TableButtonsType from "../table-buttons/table-buttons.component";
import { TableButtonsEnum } from "../table-buttons/table-buttons.enum";

const Overview = () => {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");
  const [page, setPage] = React.useState<number>(1);
  const [userStatus, setUserStatus] = React.useState<string | null>(null)
  const navigate = useNavigate();

  const openAlert = (error: any) => {
    setError(error);
    setAlertOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!userStatus) return;
    if (userStatus === "Active") {
      fetchTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, userStatus]);

  const [
    fetchTransactions,
    { loading: listTransactionsLoading, data, error: listTransactionsError },
  ] = useLazyQuery<ListTransactions>(LIST_TRANSACTIONS, {
    variables: {
      page,
    },
    fetchPolicy: "no-cache",
  });

  const {
    loading: userLoading,
    data: userData,
    error: userError,
  } = useQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.getUser?.status === "LOCKED") {
        openAlert("User is Locked");
        navigate("/locked-user")
        return;
      }
      if (data?.getUser?.status === "ACTIVE") {
        fetchTransactions();
        setUserStatus(data.getUser.status)
        return;
      }
      if (data?.getUser?.status === "PENDING" || data?.getUser?.status === "VERIFYING" || data?.getUser?.status === "ACTIVE_NO_PHONE") {
        navigate("/register/user-details");
        return;
      }
      openAlert("User is " + data?.getUser?.status);
      navigate("/login")
      return;
    },
  });

  React.useEffect(() => {
    if (listTransactionsError?.message) {
      openAlert(listTransactionsError?.message);
    }
    if (userError?.message) {
      openAlert(userError?.message);
    }
  }, [listTransactionsError, userError]);

  const loaderState = listTransactionsLoading || userLoading;

  const componentReady = (
    <div className="account-content">
      <h1 className="sub-title">Welcome, {userData?.getUser.firstName}</h1>
      {userData?.getUser.status !== "ACTIVE" && (
        <div className="warn-hint">
          <span className="fa-icon fa fa-exclamation-circle"></span>&nbsp; Final
          verification pending. You are able to start transaction but unable to
          complete payment until verification is complete.
        </div>
      )}

      <div className="no-transactions">
        {data?.listTransactions && data?.listTransactions.items?.length > 0
          ? "See the status of all your transactions in the table below."
          : "You have no active transactions."}
      </div>
      <div className="line-center"></div>

      {data?.listTransactions && data?.listTransactions.items?.length > 0 && (
        <div>
          <AllTransactions items={data?.listTransactions.items} />
          <TableButtonsType
            hasMore={data?.listTransactions?.hasMore}
            page={page}
            onButtonClicked={(action: TableButtonsEnum) => {
              if (
                action === TableButtonsEnum.NEXT &&
                data?.listTransactions?.hasMore
              ) {
                setPage((prev) => {
                  return prev + 1;
                });
              }
              if (action === TableButtonsEnum.PREVIOUS && page > 1) {
                setPage((prev) => {
                  return prev - 1;
                });
              }
            }}
          />
        </div>
      )}

      {data?.listTransactions?.items?.length === 0 && (
        <div className="ready">
          Ready to get a medallion signature guarantee stamp? Read our How it
          works page to make sure you have everything you need to get started.
        </div>
      )}
      <div className="action-button">
        <Link
          className="btn btn-continue"
          to="/transactions/create/start"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Start new transaction
        </Link>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );

  return <>{loaderState ? <Loader /> : componentReady}</>;
};

export default Overview;
