/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const LIST_ADDRESSES = gql`
  query {
    listAddresses {
      uuid
      addressLine1
      addressLine2
      city
      state
      zip
      country
      type
    }
  }
`;
