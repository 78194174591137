import React from "react";

const MedallionStamp = () => {
  return (
    <div className="col-md-12 pad content">
      <h1 className="text-center txt">
        What is a Transfer Agent and why did they just ask me for a medallion?
      </h1>
      <div className="line"></div>
      <p>
        A stock transfer agent is a company, hired by the issuer, to manage the
        change in ownership on shares and maintains a register of ownership.
        This includes cancelling the name and certificate of the shareholder who
        sold the shares of stock, and substituting the new owner's name on the
        official master shareholder listing.
      </p>
      <p>
        To provide some more details, the transfer agent has three major roles:
      </p>
      <ol>
        <li>
          <strong>
            To issue certificates to show changes in ownership of securities.
          </strong>{" "}
          When an individual or entity buys a stock or bond, a certificate must
          be issued to record the change of ownership. If a corporation does a
          stock split or dividend, the transfer agent is responsible for the
          issuing and recording of the new shares. Most of the time, ownership
          is recorded through book-entry securities, electronically recorded
          ownership certificates.
        </li>
        <li>
          <strong>
            To act on a corporation’s behalf in communicating with stock and/or
            bond holders.
          </strong>{" "}
          The transfer agent often pays out the dividends, interest or other
          distributions for a corporation. It may also mail quarterly and annual
          reports, serve as a tender agent during a tender offer, exchange
          stocks and bonds during a merger and send out proxy materials as
          required.
        </li>
        <li>
          <strong>
            To provide service to shareholders whose stock or bond certificates
            have been lost, stolen or destroyed.
          </strong>{" "}
          Some individuals and legal entities choose to hold physical stock
          certificates, rather than have them held in book-entry form. As is the
          case with any physical item, it can be lost, stolen or destroyed. In a
          case such as this, the transfer agent would help the investor replace
          the certificate.
        </li>
      </ol>
      <p>
        The transfer agent requires a shareholder to obtain a medallion
        signature guarantee to prevent fraud. By doing so, the transfer agent
        reduces its potential liability.
      </p>
      <p style={{ marginBottom: 50 }}>
        It is important to note that the medallion signature guarantee should
        not be confused with a notary public. The big difference is in the
        liability. The organization that guarantees the authenticity of the
        signature is liable for the financial value of the transaction.
      </p>
    </div>
  );
};

export default MedallionStamp;
