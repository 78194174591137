export enum SecurityTypeEnum {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  IRA_401_FUNDS_BONDS = "IRA_401_FUNDS_BONDS",
}

export enum FormatEnum {
  PHYSICAL = "PHYSICAL",
  ELECTRONIC = "ELECTRONIC",
}

export enum StockholderTypeEnum {
  CORPORATE = "CORPORATE", //Corporate / LLC
  PRIVATE = "PRIVATE", //Individual / Joint
  DECEASED = "DECEASED", //Executor / Trustee
  REDEMPTION = "REDEMPTION", // TBD.
}

export interface CreateTransactionInterface {
  securityType: SecurityTypeEnum;
  format: FormatEnum;
  stockholderType: StockholderTypeEnum;
  transferAgentUUID: string;
}
