export enum userAdressTypes {
  HOME = "HOME",
  SHIPPING = "SHIPPING",
  AGENT = "AGENT",
}

export enum userPhoneTypes {
  MOBILE = "MOBILE",
  HOME = "HOME",
  WORK = "WORK",
}
