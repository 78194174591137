const defaultNumber = 0;

export const selfShippingOptions = [
  "I'll use my own shipping method",
  "I don't need shipping, I'm using the digital delivery method",
];

export const deliveryMethods = ["OWN", "ELECTRONIC"];

export const isFedexServiceSelected = (shipping: string) => {
  return deliveryMethods.includes(shipping);
};

export const sumTotal = (
  transactionTotal: number,
  shippingCost: number
): number => {
  return transactionTotal + shippingCost;
};

export const sumMedallionsTotalLength = (transactionData: any) => {
  if (!transactionData) return 0;
  let extractedTransaction = transactionData?.getTransaction || transactionData;
  return extractedTransaction?.certificates?.reduce((acc: number, transaction: any) => {
    return acc + transaction?.medallionsCount;
  }, 0);
};

export const sumTotalWithoutDiscount = (t: any, shippingCost: number = 0) => {
  if (!t) return defaultNumber.toFixed(2);

  const total = sumTotal(sumMedallionsTotalLength(t) * 149, shippingCost);

  return total >= 0 ? total.toFixed(2) : defaultNumber.toFixed(2);
};

export const sumTransactionTotalCost = (t: any, shippingCost: number = 0) => {
  if (!t) return defaultNumber.toFixed(2);

  const total = sumTotal(sumMedallionsTotalLength(t) * 149, shippingCost);
  const discount = t?.getTransaction?.charge?.totalDiscountsInCents;

  if (!isNaN(parseFloat(discount))) {
    return (total + discount / 100).toFixed(2);
  }

  return total >= 0 ? total.toFixed(2) : defaultNumber.toFixed(2);
};

export const shippingMapper: { [key: string]: any } = {
  FEDEX_2_DAY: "Fedex 2 Day",
  STANDARD_OVERNIGHT: "Standart Overnight",
  OWN: "OWN",
  ELECTRONIC: "No Shipping",
};

export const defaultShippingMapper: { [key: string]: any } = {
  ELECTRONIC: "I don't need shipping, I'm using the digital delivery method",
  OWN: "I'll use my own shipping method",
  STANDARD_OVERNIGHT: "FedEx Standard Overnight®",
  FEDEX_2_DAY: "FedEx 2Day®",
};

export const getDefaultShippingMapper = (
  deliveryMethod: string,
  fedexServices: any[]
) => {
  if (!deliveryMethod || deliveryMethod === undefined)
    return defaultShippingMapper["OWN"];
  const fedexservice = fedexServices?.find(
    (f) => f?.fedexEnumCode === deliveryMethod
  );
  if (fedexservice) {
    return fedexservice?.uuid;
  }
  return defaultShippingMapper[deliveryMethod];
};
export const getShippingMethodName = (deliveryMethod: string) => {
  if (!deliveryMethod || deliveryMethod === undefined) return "";
  return shippingMapper[deliveryMethod];
};