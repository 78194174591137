/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const LIST_TRANSFER_ANGENTS = gql`
  query {
    listTransferAgents {
      uuid
      name
    }
  }
`;
