import { gql } from "@apollo/client";

export const GENERATE_UPDATE_PHONE_CODE = gql`
  mutation generateUpdatePhoneCode($phoneNumber: String!) {
    generateUpdatePhoneCode(phoneNumber: $phoneNumber) {
      expireOn
      media
      uuid
    }
  }
`;
