/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const ADD_DOCUMENT = gql`
  mutation addDocument(
    $name: String!
    $transactionUUID: String!
    $type: AvailableDocumentType!
  ) {
    addDocument(transactionUUID: $transactionUUID, name: $name, type: $type) {
      uuid
      name
      status
      documentType {
        type
        signRequired
      }
    }
  }
`;
