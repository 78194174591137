import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, Link, useLocation } from "react-router-dom";
import { isHomeHeader } from "../../redux/actions";


import "./menu.scss";

const Menu = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeLink, setActiveLink] = useState(0);

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    switch (location?.pathname) {
      case '/accounts/me':
        setActiveLink(0)
        break;
      case '/accounts/details':
        setActiveLink(1)
        break;
      case '/accounts/history':
        setActiveLink(2)
        break;

      default:
        setActiveLink(0)
        break;
    }
  }, [location]);

  return (
    <section className="account">
      <div className="container">
        <div className="row">
          <div className="col-md-4 overview-box">
            <div className="col-md-12 box">
              <Link to="/accounts/me" onClick={() => setActiveLink(0)}>
                <h4 className={`${activeLink === 0 && 'active'}`}>Overview</h4>
              </Link>
              <Link to="/accounts/details" onClick={() => setActiveLink(1)}>
                <h4 className={`${activeLink === 1 && 'active'}`}>Account details</h4>
              </Link>
              <Link to="/accounts/history" onClick={() => setActiveLink(2)}>
                <h4 className={`${activeLink === 2 && 'active'}`}>Transaction history</h4>
              </Link>
              <Link to="/transactions/create/start">
                <h4>Start new transaction</h4>
              </Link>

              <div className="line-center"></div>
              <div className="info-block">
                <div className="title">Customer support</div>
                <div className="info">
                  Our team is happy to answer any
                  <br /> questions. For assistance, please
                  <br /> visit our FAQs page, or e-mail us
                  <br /> <span style={{ display: "none" }}>or call us at 1-844-782-6763.</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Menu;
