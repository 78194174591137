/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const GENERATE_VERIFICATION_CODE_TRANSACTION = gql`
  mutation generateVerificationCodeForTransaction($transactionUUID: String!) {
    generateVerificationCodeForTransaction(transactionUUID: $transactionUUID) {
      expireOn
      media
    }
  }
`;
