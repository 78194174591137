/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const GET_DOCUMENT_URL = gql`
  query getDocumentURL($documentUUID: String!, $transactionUUID: String!) {
    getDocumentURL(
      transactionUUID: $transactionUUID
      documentUUID: $documentUUID
    )
  }
`;
