export const formContent: { [key: string]: string }[] = [
  {
    label: "Your name",
    placeholder: "Name",
    name: "name",
    value: "name",
    type: "text",
  },
  {
    label: "Email address",
    placeholder: "Email",
    name: "email",
    value: "email",
    type: "email",
  },
  {
    label: "Phone number",
    placeholder: "Phone number",
    name: "phone",
    value: "phone",
    type: "tel",
  },
  {
    label: "Message",
    placeholder: "Your message",
    name: "message",
    value: "message",
    type: "textarea",
  },
];
