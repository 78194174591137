/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const ADD_NOTE = gql`
  mutation addNote($transactionUUID: String!, $content: String!) {
    addNote(transactionUUID: $transactionUUID, content: $content) {
      uuid
      content
      createdAt
    }
  }
`;
