import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ApolloProvider } from "@apollo/client/react";
import HelloSign from "hellosign-embedded";

import "./App.css";
import Footer from "./components/footer/footer";
import Menu from "./components/menu/menu";
import Overview from "./components/overview/overview";
import AccountDetails from "./components/account-details/account-details";
import TransactionsHistory from "./components/transcations-history/transactions-history";
import Transactions from "./components/transactions/transactions";
import Checkout from "./components/checkout/checkout";
import CertificateType from "./components/certificate-type/certificate-type";
import Login from "./components/login/login";
import Register from "./components/register/register";
import MainRegistrationPage from "./components/resitration-steps/main/main-registration-page";
import UserInfo from "./components/resitration-steps/user-info/user-info";
import PersonalDetails from "./components/resitration-steps/personal-details/personal-details";
import Confirmation from "./components/resitration-steps/confirmation/confirmation";
import { apolloURL } from "./constants/apollo.constants";
import ForgotPassword from "./components/forgot-password/forgot-password.component";
import ResetPassword from "./components/reset-password/reset-password.component";
import CreateTransaction from "./components/create-transaction/create-transaction";
import Payments from "./components/payments/payments.component";
import TransactionComplete from "./components/transaction-complete/transaction-complete.component";
import PostTransactionDetails from "./components/post-transaction-details/post-transaction-details";
import Idology from "./components/resitration-steps/idology/idology.component";
import Home from "./components/home/home.component";
import HomeAbout from "./components/home-about/home-about.component";
import HomeHowItWorks from "./components/home-how-it-works/home-how-it-works.component";
import HomePricing from "./components/home-pricing/home-pricing.component";
import Header from "./components/header/header.component";
import WhatIs from "./components/home/what-is/what-is.component";
import StampGuarantee from "./components/home/stamp-guarantee/stamp-guarantee.component";
import MedallionStamp from "./components/home/maedallion-stamp/medallion-stamp.component";
import MedallionSignature from "./components/home/medallion-signature/medallion-signature.component";
import Contact from "./components/contact/contact.component";
import Terms from "./components/terms/terms.component";
import Privacy from "./components/privacy/privacy.component";
import FAQ from "./components/faq/faq.component";
import Glossary from "./components/glossary/glossary.component";
import LockedPage from "./components/locked-page/locked-page.component";
import Suspicious from './components/suspicious/suspicious.component';

const httpLink = new HttpLink({
  uri: apolloURL,
  // credentials: "include",
});

// Setup the header for the request
const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token");

  const authorizationTokenHeader = token ? `${token}` : null;
  operation.setContext({
    headers: {
      authorization: authorizationTokenHeader,
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
      // 'Access-Control-Allow-Headers': 'Content-Type',
    },
  });
  return forward(operation);
});

//After the backend responds, we take the refreshToken from headers if it exists, and save it in the cookie.
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const refreshToken = headers.get("x-esig-access-token");
      if (refreshToken) {
        localStorage.setItem("token", refreshToken);
      }
    }

    return response;
  });
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (
        message === "Access denied! You don't have permission for this action!"
      ) {
        window.location.replace("/");
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, extensions ${extensions}`
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: from([errorLink, middlewareAuthLink, afterwareLink, httpLink]),
  cache: new InMemoryCache(),
  credentials: "include",
});

// Create the HelloSign Embedded instance.
// Only do this once!
export const helloSignClient = new HelloSign({
  clientId: process.env.REACT_APP_HELLO_SIGN as string,
  skipDomainVerification: true,
});

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Header />
        <main>
          {/* <div className="container"> */}
          <Routes>
            <Route path="home" element={<Home />}>
              <Route path="signature-guarantee" element={<WhatIs />} />
              <Route
                path="medallion-stamp-guarantee"
                element={<StampGuarantee />}
              />
              <Route path="medallion-stamp" element={<MedallionStamp />} />
              <Route
                path="medallion-signature-guarantee"
                element={<MedallionSignature />}
              />
            </Route>
            <Route path="about" element={<HomeAbout />} />
            <Route path="terms" element={<Terms />} />
            <Route path="glossary" element={<Glossary />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="contact" element={<Contact />} />
            <Route path="how-it-works" element={<HomeHowItWorks />} />
            <Route path="pricing" element={<HomePricing />} />
            <Route path="accounts" element={<Menu />}>
              <Route path="me" element={<Overview />} />
              <Route path="details" element={<AccountDetails />} />
              <Route path="history" element={<TransactionsHistory />} />
            </Route>
            <Route path="transactions" element={<Transactions />}>
              <Route path="create/start" element={<CreateTransaction />} />
              <Route path="create/certificate" element={<CertificateType />} />
              <Route path="create/checkout" element={<Checkout />} />
            </Route>
            <Route path="details/:id" element={<PostTransactionDetails />} />
            <Route path="completed/:id" element={<TransactionComplete />} />
            <Route path="payment" element={<Payments />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="join" element={<Register />} />
            <Route path="register" element={<MainRegistrationPage />}>
              <Route path="user-details" element={<UserInfo />} />
              <Route path="personal-details" element={<PersonalDetails />} />
              <Route path="confirmation" element={<Confirmation />} />
              <Route path="verify-identity" element={<Idology />} />
            </Route>
            <Route path="locked-user" element={<LockedPage />} />
            <Route path="suspicious/:id" element={<Suspicious />} />
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
          {/* </div> */}
        </main>
        <Footer />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
