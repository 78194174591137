export enum TransactionStatus {
  PENDING = "PENDING", //Transaction is creating
  VERIFIED = "VERIFIED", //Transaction is already verified.
  READY_TO_REVIEW = "READY_TO_REVIEW", //Transaction is payed and ready for review.
  IN_REVIEW = "IN_REVIEW", //Transaction is reviewing by an admin user.
  COMPLETED = "COMPLETED", //Transaction is reviewed and succeed.
  SUSPICIOUS = "SUSPICIOUS", //Transaction is suspicious.
  CANCELLED = "CANCELLED", //Transaction is cancelled.
  DELETED = "DELETED", //Transaction is deleted.
  CREATING = "CREATING", //Transaction is deleted.
  REVIEWING = "REVIEWING", //Transaction is deleted.
}
