/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const CHECK_SIGN_DOCUMENT = gql`
  query checkSignedDocument($documentUUID: String!, $transactionUUID: String!) {
    checkSignedDocument(
      transactionUUID: $transactionUUID
      documentUUID: $documentUUID
    ) {
      uuid
      name
      status
      documentType {
        uuid
        type
      }
    }
  }
`;
