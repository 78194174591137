import { IUser } from "../../types/interfaces/user.interface";
import { MedallionType, OwnershipType } from "../../types/main";

export type Action = { type: string; payload: any };

export const setCurrentRegisterState = (current: number): Action => ({
  type: "NEXT",
  payload: current,
});

export const hideFooter = (hide: boolean): Action => ({
  type: "HIDE_FOOTER",
  payload: hide,
});

export const userLoggedIn = (user: IUser): Action => ({
  type: "LOGGED_IN",
  payload: user,
});

export const userLoggedOut = (): Action => ({
  type: "USER_LOGGED_OUT",
  payload: true,
});

export const isHomeHeader = (showHeader: boolean): Action => ({
  type: "IS_HOME_HEADER",
  payload: showHeader,
});

export const setOwnerShipType = (type: OwnershipType): Action => ({
  type: "SET_OWNER_SHIP_TYPE",
  payload: type,
});

export const setMedailionType = (type: MedallionType): Action => ({
  type: "SET_MEDALLION_TYPE",
  payload: type,
});

export const readyForSecondStepCheckout = (ready: boolean): Action => ({
  type: "READY_FOR_SECOND_STEP_CHECKOT",
  payload: ready,
});

export const readyForSThirdStepCheckout = (ready: boolean): Action => ({
  type: "READY_FOR_THIRD_STEP_CHECKOT",
  payload: ready,
});

export const setStoreMedallions = (medallions: any): Action => ({
  type: "SET_MEDALLIONS",
  payload: medallions,
});

export const setTransactionMode = (transactionUUid: any) => ({
  type: "SET_TRANSACTION_MODE",
  payload: transactionUUid,
});

export const setTransactionsTypes = (details: any) => ({
  type: "SET_TRANSACTION_TYPES",
  payload: details,
});

export const setExpirationCodes = (expiration: any) => ({
  type: "CODES_EXPIRATION",
  payload: expiration,
});
