import { gql } from "@apollo/client";

export const UPDAE_PHONE = gql`
  mutation updatePhone($phoneNumber: String!, $digits: String!) {
    updatePhone(digits: $digits, phoneNumber: $phoneNumber) {
      localNumber
      type
      countryCode
    }
  }
`;
