/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const VERIFY_EMAIL_ADDRESS = gql`
  mutation VerifyEmailAddress($digits: String!) {
    verifyEmailAddress(digits: $digits) {
      firstName
      lastName
      email
      isEmailVerified
      status
      type
      paymentMethod
    }
  }
`;
export const VERIFY_MOBILE = gql`
  mutation verifyMobile($phonedDigits: String!) {
    verifyMobile(digits: $phonedDigits) {
      localNumber
      type
      countryCode
      isVerified
    }
  }
`;

export const GENERATE_EMAIL_VERIFICATION_CODE = gql`
  mutation verification_codes {
    generateVerificationCodeForEmail {
      expireOn
      media
    }
  }
`;

export const GENERATE_PHONE_VERIFICATION_CODE = gql`
  mutation verification_codes {
    generateVerificationCodeForMobile {
      expireOn
      media
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation verifyOTC($phoneDigits: String $emailDigits: String) {
    verifyOTC(
      phoneDigits: $phoneDigits
      emailDigits: $emailDigits
    ) {
      uuid
      status
      isEmailVerified
      mobile {
        uuid
        isVerified
      }
    }
}
`
