import React from "react";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { GET_TRANSACTION } from "../../graphql/queries/transaction/get-transaction.query";
import { RootState } from "../../redux/reducers";
import {
  sumMedallionsTotalLength,
  sumTotalWithoutDiscount,
} from "../checkout/checkout.helpers";
import Loader from "../loader/loader.component";

const containerStyle = {
  width: 400,
  backgroundColor: "#f5f5f5",
  padding: "20px 30px",
  marginTop: 20,
  height: "fit-content",
};

const titleStyle = {
  color: "#0c234c",
  fontSize: 18,
  fontFamily: "CerebriSans-SemiBold",
  paddingBottom: 15,
  paddingTop: 15,
};

const lineStyle = {
  backgroundColor: "#ddd",
  height: 1,
  width: "100%",
  marginTop: 5,
  marginBottom: 15,
};

const PageTwoSideBox = () => {
  const transactionUUID = useSelector<
    RootState,
    RootState["transaction"]["transactionUUID"]
  >((state) => {
    return state.transaction.transactionUUID;
  });

  const { loading: transactionLoader, data: transactionData } = useQuery(
    GET_TRANSACTION,
    {
      variables: {
        transactionUUID: transactionUUID,
      },
    }
  );
  return (
    <div className="side-box" style={containerStyle}>
      <h4 style={titleStyle}>Order Summary</h4>

      <div
        style={{ display: "flex", justifyContent: "space-between", height: 30 }}
      >
        <span>eSignature medallion stamp</span>
        <span>
          {transactionLoader ? (
            <div style={{ flex: 1 }}>
              <Loader size={15} />
            </div>
          ) : (
            <span style={{ flex: 1 }}>
              {sumMedallionsTotalLength(transactionData)} x $149
            </span>
          )}
        </span>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 30,
          }}
        >
          <span>Return shipping</span>
          <span>Free</span>
        </div>
      </div>
      <div style={lineStyle}></div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: 30,
        }}
      >
        <span>Subtotal</span>
        <span>
          {transactionLoader ? (
            <Loader size={20} />
          ) : (
            <span>${sumTotalWithoutDiscount(transactionData)}</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default PageTwoSideBox;
