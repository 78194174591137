import React from "react";
import Dialog from "@mui/material/Dialog";
import { Breakpoint } from "@mui/material";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  maxWidth?: Breakpoint;
}

const Modal: React.FC<ModalProps> = ({
  onClose,
  open,
  maxWidth = "xl",
  children,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={maxWidth}>
      {children}
    </Dialog>
  );
};

export default Modal;
