import React, { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { VERIFY_IDOLOGY_SEND_DATA } from "../../../graphql/mutations/idology/verify-identity.mutation";
import { GET_USER } from "../../../graphql/queries/user/get-user.query";
import { FormControlLabel, Radio, RadioGroup, Snackbar } from "@mui/material";
import { IDOLOGY_ANSWER_QUESTIONS } from "../../../graphql/mutations/idology/idology-answer-questions.mutstion";
import Loader from "../../loader/loader.component";
import { useNavigate } from "react-router-dom";
import { IDOLOGY_ANSWER_QUESTIONS_CHALLENGE } from "../../../graphql/mutations/idology/verify-identity-challenge.mutation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { isHomeHeader, setCurrentRegisterState } from "../../../redux/actions";

import "./idology.scss";
import Alert from "../../alert/alert.component";
import { ERROR_MSG_SECTION_ONE, ERROR_MSG_SECTION_THREE, ERROR_MSG_SECTION_TWO } from "./idology.constants";

type answerType = { order: number; type: string; answer: string };
type questionType = {
  order?: number;
  prompt: string;
  type: string;
  answers: string[];
};

const Idology = () => {
  const [answers, setAnswers] = useState<answerType[]>([]);
  const [qLength, setQLength] = useState<number>(0);
  const [questions, setQuestions] = useState<questionType[]>([]);
  const [isChallenge, setIsChallenge] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");

  const openAlert = (error: any) => {
    setError(error);
    setAlertOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerState = useSelector<
    RootState,
    RootState["register"]["current"]
  >((state) => {
    return state.register.current;
  });

  React.useEffect(() => {
    if (registerState < 4) {
      navigate("/register/user-details");
    }
  }, [navigate, registerState]);

  const [getInitialQuestions, { loading, error: getQuestionsError }] =
    useMutation(VERIFY_IDOLOGY_SEND_DATA, {
      onCompleted: (d) => {
        const questions = d?.verifyIdentity?.idologyResponse?.questions;
        if (!questions) {
          return;
        }
        setQuestions(questions);
        setQLength(questions?.length || 0);
      },
    });

  const [verifyUser, { loading: verifyUserLoader }] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      if (data?.getUser?.status === "LOCKED") {
        localStorage.removeItem("token");
        navigate("/locked-user")
        return;
      }
      if (data?.getUser && data?.getUser?.status === "ACTIVE") {
        navigate("/accounts/me", { replace: true });
        return;
      }
      if (data?.getUser?.status !== "VERIFYING") {
        // openAlert('User must verify phone number or email');
        openAlert('User must verify email');
        returnToTheFirstStep();
        return;
      }
      getInitialQuestions();
    },
  });

  React.useEffect(() => {
    try {
      verifyUser();
    } catch (err: any) {
      openAlert('Oops! Something went wrong! ' + err)
      returnToTheFirstStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    answerQuestionsFunc,
    { loading: answersLoading, error: answerQestionsError },
  ] = useMutation(IDOLOGY_ANSWER_QUESTIONS, {
    variables: {
      answers: answers,
    },
    refetchQueries: [
      {
        query: GET_USER,
      },
    ],
    onCompleted: (d) => {
      const data = d?.verifyIdentity;
      verifyRes(data);
    },
  });

  const [
    answerQuestionsChallengeFunc,
    { loading: challengthLoading, error: answerChallengeError },
  ] = useMutation(IDOLOGY_ANSWER_QUESTIONS_CHALLENGE, {
    variables: {
      answers: answers,
    },
    refetchQueries: [
      {
        query: GET_USER,
      },
    ],
    onCompleted: (d) => {
      setIsChallenge(false);
      const data = d?.verifyIdentity;
      verifyRes(data);
    },
  });

  const verifyRes = (data: any) => {
    if (
      (data && data?.idologyResponse?.status === "FAILED") ||
      (data && data?.idologyResponse?.status === "CANCELLED")
    ) {
      openAlert("Failed! Please try again!");
      returnToTheFirstStep();
      return;
    }
    if (data && data?.idologyResponse?.status === "SUCCEED") {
      openAlert(data?.idologyResponse?.status);
      navigate("/accounts/me", { replace: true });
      dispatch(setCurrentRegisterState(1));
      return;
    }
    if (data && data?.idologyResponse?.status === "IN_PROGRESS") {
      setIsChallenge(true);
      setQuestions(data?.idologyResponse?.questions);
      setQLength(data?.idologyResponse?.questions?.length);
      return;
    }
    if (data && data?.idologyResponse?.status === "LOCKED") {
      openAlert("User is Locked");
      navigate("/locked-user", { replace: true });
      dispatch(setCurrentRegisterState(1));
      return;
    }
  };

  const onAnswer = (e: any) => {
    if (!e.order) {
      setAnswers([{ ...e, order: 1 }]);
      return;
    }

    let isAnswer = answers.filter((a) => a.order !== e.order);

    if (isAnswer) {
      setAnswers(
        [...isAnswer, e].sort(
          (a: answerType, b: answerType) => a.order - b.order
        )
      );
    }
  };

  const onsubmit = async () => {
    if (answers.length !== qLength) return;
    try {
      if (!isChallenge) {
        await answerQuestionsFunc();
      } else {
        await answerQuestionsChallengeFunc();
      }
    } catch (err) {
      openAlert('Oops! Something went wrong!');
      returnToTheFirstStep();
    }
  };

  React.useEffect(() => {
    if (getQuestionsError?.message) {
      openAlert(getQuestionsError?.message);
      returnToTheFirstStep();
    }
    if (answerQestionsError?.message) {
      openAlert(answerQestionsError?.message);
      returnToTheFirstStep();
    }
    if (answerChallengeError?.message) {
      openAlert(answerChallengeError?.message);
      returnToTheFirstStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuestionsError, answerQestionsError, answerChallengeError]);

  const returnToTheFirstStep = () => {
    setTimeout(() => {
      navigate("/register/user-details");
      dispatch(setCurrentRegisterState(1));
    }, 2000);
  }

  if (loading || verifyUserLoader) {
    return <Loader />;
  }

  const errorEmptyArrays = <div style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }}>
    <div>{ERROR_MSG_SECTION_ONE}</div>
    <div style={{ marginTop: 15 }}>{ERROR_MSG_SECTION_TWO} <a href={ERROR_MSG_SECTION_THREE}>{ERROR_MSG_SECTION_THREE}</a></div>
  </div>;

  const emptyQuestionStatus = <div style={{ textAlign: 'center', fontSize: 18 }}>{errorEmptyArrays}</div>;


  return (
    <div className="pull-left">
      {questions?.length && questions[0].type !== null ? <><h1 className="sub-title">Verify identity</h1>
        {!questions || !answers ? (
          <div>
            Something when wrong. It's possible that the API connectivity was
            disrupted.
          </div>
        ) : (
          <>
            <p>
              Our advanced intelligence system has used the information provided
              to create these questions from publicly available data sources. For
              our system to confirm your identity and protect you from fraudulent
              activity, you must answer all five of the following questions
              correctly. For security reasons, you will have three opportunities
              to answer the questions correctly.
            </p>

            <div className="verify-hint">
              <span className="fa-icon fa fa-exclamation-circle"></span>
              {/* You have <span className="tries-left">{{ 3 - $root.user.info.attempts }} tries left</span>. If you’re unable to answer the questions correctly after 3 tries, you’ll have to contact us for further action. */}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "50px 0",
              }}
            >
              {questions?.map((q, i: number) => (
                <div key={q?.order} style={{ marginTop: 25 }}>
                  <label>{q?.prompt}</label>
                  <RadioGroup
                    onChange={(e) =>
                      onAnswer({
                        order: q?.order,
                        type: q?.type,
                        answer: e?.target?.value,
                      })
                    }
                    style={{ fontSize: 14 }}
                  >
                    {q?.answers?.map((v: string) => {
                      return (
                        <FormControlLabel
                          value={v}
                          control={<Radio size="small" />}
                          label={v}
                          key={v}
                        />
                      );
                    })}
                  </RadioGroup>
                </div>
              ))}
              <button className="btn btn-login" type="button" onClick={onsubmit}>
                {answersLoading || challengthLoading ? <Loader /> : "Continue"}
              </button>
            </div>
          </>
        )}</> : emptyQuestionStatus}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Idology;
