import React from "react";
import ErrorIcon from "@mui/icons-material/Error";

const containerStyle = {
  width: 380,
  backgroundColor: "#f5f5f5",
  padding: "20px 30px",
  marginTop: 20,
  height: "fit-content",
};

const titleStyle = {
  fontFamily: "CerebriSans-SemiBold",
  fontSize: 16,
  color: "#555",
};

const PageOneSideBox = () => {
  return (
    <div style={containerStyle}>
      <h4 style={titleStyle}>Ownership type info</h4>
      <p>
        Individual/Joint — Security is owned by an individual or several
        individual partners
      </p>
      <p>Corporate/LLC — Security is owned by either a corporation or an LLC</p>
      <p>
        Executor/Trustee — Security is controlled by the executor or trustee of
        an estate
      </p>
      <h4 style={titleStyle}>Security types info</h4>
      <p>Publicly listed stock — Security with a publicly listed share price</p>
      <p>
        Privately listed stock — Security that doesn’t have a publicly listed
        share price.
      </p>
      <p>
        For IRA, 401K, funds, bonds — Securities that don’t have a publicly
        listed share price.
        <br />
      </p>
      <br />
      <div style={{ display: "flex", alignItems: "center" }}>
        <ErrorIcon style={{ color: "red" }} />
        <span>Information</span>
      </div>
      <br />
      <ul>
        <li style={{ marginBottom: 10 }}>
          We are not able to provide a medallion for a Power of Attorney.
        </li>
        <li style={{ marginBottom: 10 }}>
          We are not able to provide a medallion for US Treasury Bond
          Transactions.
        </li>
        <li style={{ marginBottom: 10 }}>
          We are not able to provide a medallion for Cash Withdraw Requests.
        </li>
        <li style={{ marginBottom: 10 }}>
          Please do NOT send us these types of transactions. We will not be able
          to process them for you.
        </li>
      </ul>
    </div>
  );
};

export default PageOneSideBox;
