import { TransactionStatus } from "../../graphql/enums/transaction.enums";

export const AllTransactionsTranslate: { [key: string]: string } = {
  CREATING: "Transaction is being created.",
  PENDING: "Transaction payed and ready for review.",
  REVIEWING: "Transaction currently under review.",
  COMPLETED: "Transaction has been approved",
  SUSPICIOUS: "Transaction flagged as suspicious.",
  CANCELLED: "Transaction has been cancelled.",
  DELETED: "Transaction has been deleted.",
};

export const viewDetailsValidStatuses = [
  TransactionStatus.VERIFIED,
  TransactionStatus.PENDING,
  TransactionStatus.REVIEWING,
  TransactionStatus.COMPLETED,
];
