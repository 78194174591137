import { useMutation } from "@apollo/client";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  TextareaAutosize,
} from "@mui/material";
import React from "react";
import { ANSWER_SURVEY } from "../../graphql/mutations/surveys/answer-survey.mutation";
import { SKIP_SURVEY } from "../../graphql/mutations/surveys/skip-survey.mutation";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import Loader from "../loader/loader.component";

type SurveyType = {
  handleClose: () => void;
  openAlert: (err: string) => void;
};

const Survey: React.FC<SurveyType> = ({ handleClose, openAlert }) => {
  const [rate, setRate] = React.useState<number | null>(0);
  const [about, setAbout] = React.useState<string>("");
  const [reasons, setReasons] = React.useState<string>("");
  const [comments, setComments] = React.useState<string>("");

  const [
    skipSurveyFunc,
    { loading: skipSurveyLoader, error: skipSurveyError },
  ] = useMutation(SKIP_SURVEY, {
    refetchQueries: [
      {
        query: GET_USER,
      },
    ],
    onCompleted: () => handleClose(),
  });

  const [
    answerSurveyFunc,
    { loading: answerSurveyLoader, error: answerSurveyError },
  ] = useMutation(ANSWER_SURVEY, {
    variables: {
      about: about,
      rate: rate,
      reasons: reasons,
      comments: comments,
    },
    refetchQueries: [
      {
        query: GET_USER,
      },
    ],
    onCompleted: () => handleClose(),
  });

  React.useEffect(() => {
    if (skipSurveyError?.message) {
      openAlert(skipSurveyError?.message);
    }
    if (answerSurveyError?.message) {
      openAlert(answerSurveyError?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipSurveyError, answerSurveyError]);

  const disableButton = () => {
    return rate === 0 || about === "" || reasons === "";
  };

  const handleSubmit = async () => {
    if (disableButton()) return;
    await answerSurveyFunc();
  };

  const handleSkip = async () => {
    await skipSurveyFunc();
  };

  return (
    <div>
      <DialogTitle>Survey</DialogTitle>
      <DialogContent
        style={{ display: "flex", flexDirection: "column", gap: 25 }}
      >
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          maxRows={5}
          placeholder="How did you hear about us?"
          style={{ width: 500 }}
          value={about}
          onChange={(e) => setAbout(e?.target?.value)}
        />
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          maxRows={5}
          placeholder="Reasons for needing a signature guarantee"
          style={{ width: 500 }}
          value={reasons}
          onChange={(e) => setReasons(e?.target?.value)}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>How would you rate us?</label>
          <Rating
            name="simple-controlled"
            value={rate}
            onChange={(event: any, newValue: any) => {
              setRate(newValue);
            }}
          />
        </div>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          maxRows={5}
          placeholder="Additional comments"
          style={{ width: 500 }}
          value={comments}
          onChange={(e) => setComments(e?.target?.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} disabled={disableButton()}>
          {answerSurveyLoader ? <Loader size={30} /> : "Submit"}
        </Button>
        <Button onClick={handleSkip}>
          {skipSurveyLoader ? <Loader size={30} /> : "Skip"}
        </Button>
      </DialogActions>
    </div>
  );
};

export default Survey;
