import { useMutation, useQuery } from "@apollo/client";
import { Snackbar, Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ADD_USER_INFO,
  ADD_PHONE,
} from "../../../graphql/mutations/user/user.mutations";
import { SET_ALLOW_RECEIVE_SMS } from "../../../graphql/mutations/verification/setAllowReceiveSms.mutation";
import { GET_USER } from "../../../graphql/queries/user/get-user.query";
import {
  userAdressTypes,
  userPhoneTypes,
} from "../../../graphql/types/user.types";
import { getStates } from "../../../helpers/account-details";
import { validatePhoneNumber } from "../../../helpers/validate-us-number";
import { isHomeHeader, setCurrentRegisterState } from "../../../redux/actions";
import { RootState } from "../../../redux/reducers";
import Alert from "../../alert/alert.component";
import FormControlError from "../../form-control-error/form-control-error.component";
import Loader from "../../loader/loader.component";
import { generalErrorMessage, isFormInputValid, isFormValid } from "./user-info.helper";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import './user-info.scss';

const errorsStyle = { color: "#d43f00", fontSize: 12 };

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 560,
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UserInfo = () => {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");
  const [isPhoneAuthorised, setIsPhoneAuthorised] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isUserActiveNoPhone, setIsUserActiveNoPhone] = useState(false);

  const handleClose = () => setIsUserActiveNoPhone(false);

  const openAlert = (error: any) => {
    setError(error);
    setAlertOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setCurrentRegisterState(1));
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerState = useSelector<
    RootState,
    RootState["register"]["current"]
  >((state) => {
    return state.register.current;
  });

  const {
    loading: userLoading,
    data: userData,
    error: userError,
  } = useQuery(GET_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getUser?.status === "LOCKED") {
        localStorage.removeItem("token");
        navigate("/locked-user")
        return;
      }
      setIsUserActiveNoPhone(data?.getUser?.status === "ACTIVE_NO_PHONE")
      setIsPhoneAuthorised(data?.getUser?.mobile?.isVerified);
      setIsChecked(data?.getUser?.allowReceiveSms);
    }
  });

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    otherPhone: "",
    phone: "",
    homeAddress: "",
    homeAddress2: "",
    city: "",
    state: "",
    zip: "",
    country: "United States",
  });

  React.useEffect(() => {
    if (userData?.getUser && userData?.getUser?.status === "ACTIVE") {
      navigate("/accounts/me", { replace: true });
    }
    setUserDetails({
      firstName: userData?.getUser?.firstName,
      lastName: userData?.getUser?.lastName,
      otherPhone: "",
      phone: `${userData?.getUser?.mobile?.countryCode}${userData?.getUser?.mobile?.localNumber}`,
      homeAddress: userData?.getUser?.home?.addressLine1,
      homeAddress2: userData?.getUser?.home?.addressLine2,
      city: userData?.getUser?.home?.city,
      state: userData?.getUser?.home?.state,
      zip: userData?.getUser?.home?.zip,
      country: "United States",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  React.useEffect(() => {
    if (registerState === 2) {
      navigate("/register/personal-details", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerState]);

  const onInputChanged = (e: any) => {
    e.preventDefault();
    const { value, name } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const userDetailsToAdd = {
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    addressLine1: userDetails.homeAddress,
    addressLine2: userDetails.homeAddress2,
    city: userDetails.city,
    country: userDetails.country,
    state: userDetails.state,
    zip: userDetails.zip,
    type: userAdressTypes.HOME,
  };

  const phoneDetails = {
    phoneNumber: "+" + userDetails.phone,
    phoneType: userPhoneTypes.MOBILE,
  };

  const [addUserInfo, { loading: addUserInfoLoader, error: addUserError }] =
    useMutation(ADD_USER_INFO, {
      variables: userDetailsToAdd,
      refetchQueries: [
        {
          query: GET_USER,
        },
      ],
      onCompleted: (d: any) => {
        dispatch(setCurrentRegisterState(2));
      },
    });

  const [addPhone, { loading: addPhoneLoader, error: addPhoneError }] =
    useMutation(ADD_PHONE, {
      variables: phoneDetails,
      refetchQueries: [
        {
          query: GET_USER,
        },
      ],
      onCompleted: (d: any) => {
        dispatch(setCurrentRegisterState(2));
      },
    });

  const [allowReceiveSMSMutation, { loading: allowReceiveSMSLoader, error: allowReceiveSMSError }] =
    useMutation(SET_ALLOW_RECEIVE_SMS, {
      refetchQueries: [
        {
          query: GET_USER,
        },
      ],
      onCompleted: (d: any) => {
        dispatch(setCurrentRegisterState(2));
      },
    });

  const onSubmit = async () => {
    if (!isFormValid(userDetails, isChecked) && process.env.NODE_ENV === 'production') return;
    try {
      if (isPhoneAuthorised) {
        await addUserInfo();
      } else {
        const isResOk = await addPhone();
        if (isResOk?.data) {
          await addUserInfo();
        }
      }
      await allowReceiveSMSMutation();
    } catch (err) { }
  };

  const isValidPhoneNumber = (value: string) => {
    const regex = new RegExp(/(\+\d{1,2}\s)?\(?\d{3}\)?\d{3}\d{4}$/);
    return regex.test(value);
  };

  const onPhoneChange = (data: any) => {
    if (process.env.NODE_ENV === 'production') {
      if (!isValidPhoneNumber(data)) return;
    }
    setUserDetails({ ...userDetails, phone: data });
  };

  React.useEffect(() => {
    if (userError?.message) {
      openAlert(userError?.message);
      navigate("/login", { replace: true });
    }
    if (addUserError?.message) {
      openAlert(addUserError?.message);
    }
    if (addPhoneError?.message) {
      openAlert(addPhoneError?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userError, addUserError, addPhoneError]);

  React.useEffect(() => {
    if (allowReceiveSMSError?.message) {
      openAlert(allowReceiveSMSError?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowReceiveSMSError]);

  if (userLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <div className="pull-left">
        <h1 className="sub-title">User info</h1>
        <p>
          Enter your details in the fields below, as we begin the process of
          verifying your identity
        </p>
      </div>
      <div className="form-block pull-left">
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="line-center"></div>

          <div className="form-row pad10">
            <div className="form-group col-md-6 m10">
              <label>Legal First name</label>
              <input
                value={userDetails.firstName}
                name="firstName"
                tabIndex={1}
                type="text"
                autoComplete="off"
                className={`form-control ${!isFormInputValid(userDetails?.firstName) ? 'error' : ''}`}
                onChange={onInputChanged}
              />
              {!userDetails.firstName && <FormControlError errorMessage={generalErrorMessage('First name')} />}

            </div>
            <div className="form-group col-md-6 m10">
              <label>Legal Last name</label>
              <input
                value={userDetails.lastName}
                name="lastName"
                tabIndex={2}
                type="text"
                autoComplete="off"
                className={`form-control ${!isFormInputValid(userDetails.lastName) ? 'error' : ''}`}
                minLength={2}
                onChange={onInputChanged}
              />
              {!userDetails.lastName && <FormControlError errorMessage={generalErrorMessage('Last name')} />}
            </div>
          </div>

          <div className="form-row" style={{}}>
            <div className="form-group col-md-6 m10">
              <label>Mobile phone number</label>
              <PhoneInput
                isValid={(value) => {
                  return process.env.NODE_ENV === 'development' ? true : validatePhoneNumber(value)
                }}
                value={userDetails.phone}
                onChange={onPhoneChange}
                country={process.env.NODE_ENV === 'development' ? "" : "us"}
                disabled={isPhoneAuthorised}
                onlyCountries={process.env.NODE_ENV === 'development' ? ['us', 'il'] : ['us']}
                inputClass={`${!validatePhoneNumber(userDetails.phone) && process.env.NODE_ENV === 'production' ? 'error' : ''}`}
              />
              {!validatePhoneNumber(userDetails.phone) && process.env.NODE_ENV === 'production' ? (
                <span className="input-error">
                  <div className="fa-icon fa fa-exclamation-circle"></div>
                  <p style={errorsStyle}>Please add valid phone number!</p>
                </span>
              ) : ''}
              <FormControlLabel
                value="end"
                style={{
                  maxWidth: 300,
                  marginTop: 25
                }}
                control={<Checkbox
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />}
                label="I agree to receive transactional text messages from eSignatureGuarantee. Carrier rates may apply."
                labelPlacement="end"
                classes={{ label: 'font-size-75' }}
              />
            </div>

            <div className="form-group col-md-6 m10">
              <label>
                Other phone number <span className="optional">(optional)</span>
              </label>
              <input
                value={userDetails.otherPhone}
                name="otherPhone"
                type="text"
                className="form-control"
                autoComplete="off"
                onChange={onInputChanged}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6 m10">
              <label>Address line 1</label>
              <input
                value={userDetails.homeAddress}
                name="homeAddress"
                tabIndex={5}
                type="text"
                autoComplete="off"
                className={`form-control ${!isFormInputValid(userDetails?.homeAddress) ? 'error' : ''}`}
                minLength={2}
                onChange={onInputChanged}
              />
              {!userDetails.homeAddress && <FormControlError errorMessage={generalErrorMessage('Address')} />}
            </div>

            <div className="form-group col-md-6 m10">
              <label>
                Address line 2 <span className="optional">(optional)</span>
              </label>
              <input
                value={userDetails.homeAddress2}
                name="homeAddress2"
                tabIndex={6}
                type="text"
                className="form-control"
                autoComplete="off"
                onChange={onInputChanged}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6 m10">
              <label>City</label>
              <input
                value={userDetails.city}
                name="city"
                tabIndex={7}
                type="text"
                autoComplete="off"
                className={`form-control ${!isFormInputValid(userDetails?.city) ? 'error' : ''}`}
                minLength={2}
                onChange={onInputChanged}
              />
              {!userDetails.city && <FormControlError errorMessage={generalErrorMessage('City')} />}
            </div>

            <div className="form-group col-md-6 m10">
              <label>State </label>
              <select
                value={userDetails.state}
                name="state"
                tabIndex={8}
                className={`form-control ${!isFormInputValid(userDetails?.state) ? 'error' : ''}`}
                onChange={onInputChanged}
              >
                <option value="">- Select state -</option>
                {getStates().map((state: string, i: number) => (
                  <option key={i} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              {!userDetails.state && <FormControlError errorMessage={generalErrorMessage('State')} />}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6 m10">
              <label>Zip code</label>
              <input
                value={userDetails.zip}
                name="zip"
                tabIndex={9}
                type="text"
                className={`form-control ${!isFormInputValid(userDetails?.zip) ? 'error' : ''}`}
                minLength={2}
                onChange={onInputChanged}
              />
              {!userDetails.zip && <FormControlError errorMessage={generalErrorMessage('Zip code')} />}
            </div>

            <div className="form-group col-md-6 m10">
              <label>Country </label>
              <input
                value={userDetails.country}
                name="country"
                tabIndex={10}
                type="text"
                className={`form-control ${!isFormInputValid(userDetails?.country) ? 'error' : ''}`}
                readOnly={true}
                onChange={onInputChanged}
              />
              {!userDetails.country && <FormControlError errorMessage={generalErrorMessage('Country')} />}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12 text-center pad-btn">
              <button
                name="user-data"
                tabIndex={11}
                className="btn btn-continue"
                disabled={process.env.NODE_ENV === 'development' ? false : !isFormValid(userDetails, isChecked)}
                type="button"
                onClick={onSubmit}
              >
                {addUserInfoLoader || addPhoneLoader || allowReceiveSMSLoader ? (
                  <Loader size={30} />
                ) : (
                  "Continue"
                )}
              </button>
              <div style={{ marginTop: 25, color: "#999" }}>
                After clicking Continue, you will receive a confirmation code to
                your mobile device and a second confirmation to your email
                account.
              </div>
              <p style={{ marginTop: 25, color: "#999", fontSize: 10 }}>
                The following terms and conditions apply when you use our SMS services.
                a. When you opt-in to the service, we will send you an SMS message to confirm your signup.
                b. You can cancel the SMS service at any time. Just text "STOP" to 814-752-4591. After you send the SMS message "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us.
                c. If at any time you forget what keywords are supported, just text "HELP" to 814-752-4591 . After you send the SMS message "HELP" to us, we will respond with instructions on how to use our service as well as how to unsubscribe.
                d. As always, message and data rates may apply for any messages sent to you from us and to us from you. You will receive (message frequency). If you have any questions about your text plan or data plan, it is best to contact your wireless provider. For all questions about the services provided by this short code, you can send an email to <a href="mailto: info@esignatureguarantee.com"> info@esignatureguarantee.com</a>
                e. If you have any questions regarding privacy, please read our privacy policy: <a href="https://esignatureguarantee.com/privacy">https://esignatureguarantee.com/privacy</a>
              </p>
            </div>
          </div>
        </form>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <Modal
        open={isUserActiveNoPhone}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              We have recently included mobile phone 2FA to our login and transaction security.
              Please add your mobile phone number for validation purposes, which will update your profile.
            </div>
          </Typography>
        </Box>
      </Modal>

      {/* <React.Fragment>
                
      <script>
    window.__lc = window.__lc || {};
    window.__lc.license = 9046960;
    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
  </script>
  <noscript><a href="https://www.livechatinc.com/chat-with/9046960/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a></noscript>
      </React.Fragment> */}
    </React.Fragment>
  );
};

export default UserInfo;
