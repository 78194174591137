/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const ADD_ADDRESS_TO_LIST = gql`
  mutation addAddress(
    $addressLine1: String!
    $city: String!
    $state: String!
    $zip: String!
  ) {
    addAddress(
      input: {
        addressLine1: $addressLine1
        state: $state
        city: $city
        zip: $zip
        type: SHIPPING
      }
    ) {
      addressLine1
      city
      state
      zip
      country
      type
    }
  }
`;
