import {
  SecurityTypeEnum,
  StockholderTypeEnum,
} from "../../types/interfaces/create-transaction.interfaces";
import { Action } from "../actions";

export interface TransactionState {
  transactionUUID: null | string;
  stockholderType: null | StockholderTypeEnum;
  securityType: null | SecurityTypeEnum;
}

const initialState = {
  transactionUUID: null,
  stockholderType: null,
  securityType: null,
};

export const TransactionReducer = (
  state: TransactionState = initialState,
  action: Action
) => {
  switch (action.type) {
    case "SET_TRANSACTION_MODE": {
      return {
        ...state,
        transactionUUID: action.payload,
      };
    }
    case "SET_TRANSACTION_TYPES": {
      return {
        ...state,
        stockholderType: action.payload.stockholderType,
        securityType: action.payload.securityType,
      };
    }
    default:
      return state;
  }
};
