import React from "react";

const WhatIs = () => {
  return (
    <div className="col-md-12 pad content">
      <h1 className="text-center txt">What is an eSignature?</h1>
      <div className="line"></div>

      <h4 style={{ paddingTop: 50 }}>What’s an e-signature?</h4>
      <p>
        An e-signature or electronic signature is a legal way to get consent or
        approval on electronic documents or forms. It can replace a handwritten
        signature in virtually any process.
      </p>
      <p>
        An eSignature, or electronic signature, allows users to sign documents
        online with no pen or paper needed.
      </p>
      <p>
        According to the U.S. Federal ESIGN Act passed in 2000, an electronic
        signature is an "electronic sound, symbol, or process, attached to or
        logically associated with a contract or other record and executed or
        adopted by a person with the intent to sign the record.”
      </p>
      <p>
        We have integrated the API of HelloSign. They are a leader in the
        digital industry, providing an easy-to-use solution for companies to
        send documents for signature online, track the progress of request, and
        get documents signed directly from their device in a safer and faster
        way than a traditional paper-based process.
      </p>
      <p>
        eSignatures are accepted by numerous financial institutions and the
        Securities Transfer Association for transactions valued under $14
        million. However, some annuities, insurance companies or other financial
        institutions may have internal policies that require “pen to paper”
        signatures so please consult with them as such.
      </p>
      <p style={{ paddingBottom: 50 }}>
        Given the increase in virtual working environments due to COVID-19, many
        lenders are exploring electronic notarization, remote online
        notarization (RON), and even electronic signatures. While the pandemic
        may have spurred industries towards electronic notarizations, electronic
        processes for notarization, signature verification, etc. we are proud to
        have been accepting esignatures and offering our online medallion
        signature guarantee solution for the past several years.
      </p>
    </div>
  );
};

export default WhatIs;
