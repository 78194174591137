export const transactionStatus: { [key: string]: any } = {
  PENDING: "Pending", //Transaction is creating.
  VERIFIED: "Verified", //Transaction is already verified.
  READY_TO_REVIEW: "Ready to review", //Transaction is payed and ready for review.
  IN_REVIEW: "In review", //Transaction is reviewing by an admin user.
  COMPLETED: "Completed", //Transaction is reviewed and succeed.
  SUSPICIOUS: "Suspicious", //Transaction is suspicious.
  CANCELLED: "Cancelled", //Transaction is cancelled.
  DELETED: "Deleted", //Transaction is deleted.
};

export const transactionStockholder: { [key: string]: any } = {
  CORPORATE: "Corporate / LLC",
  PRIVATE: "Individual / Joint",
  DECEASED: "Executor / Trustee",
  REDEMPTION: "TBD",
};

export const transactionSecurityType: { [key: string]: any } = {
  PUBLIC: "Public Transaction",
  PRIVATE: "Private Transaction",
  IRA_401_FUNDS_BONDS: "TBD",
};
