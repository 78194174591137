export const aboutPeopleDescription = [
  {
    img: "assets/images/Seth-Farbman.png",
    name: "Seth Farbman",
    desc: "Mr. Farbman was the Co-Founder and President of Vintage Filings which he sold to PR Newswire in 2007. In this role Mr. Farbman serviced over 3,000 publicly traded companies to provide SEC EDGAR and financial print services related to IPOs, Proxy Statements, Annual Reports, Shareholder Meetings and all aspects of compliance filings. Mr. Farbman was a founding partner and a Co-chairman of Vcorp Services which he sold in October 2016 to Wolters Kluwer. Mr. Farbman currently serves as the chairman and President of Vstock transfer, with a focus on pre IPO, Nasdaq and NYSE MKT listed issuers. Mr. Farbman is also the chairman of Vcheck Global, a background and due diligence services company and eSignatureGuarantee.com, an online resource for medallion signature guarantees. Prior to starting Vintage Filings. Mr. Farbman served as a securites attorney at a New York Law Firm with a concentration on securities regulation.",
  },
  {
    img: "assets/images/Yoel-Goldfeder.png",
    name: "Yoel Goldfeder",
    desc: "Yoel Goldfeder, Esq. is a licensed attorney with over fifteen years of corporate and securities law experience. Yoel has represented both small and large companies and investors throughout his legal career dealing with mergers and acquisitions, joint ventures and corporate financing, including both public and private securities offerings, specializing in PIPE transactions and reverse mergers. Yoel obtained his Juris Doctorate from Georgetown University Law Center, after receiving a BA. in Accounting and a B.S. in Political Science from Brooklyn College. He is admitted to practice law in New York and is also an Adjunct Assistant Professor at Baruch College, teaching courses in business law and business organizations. Yoel is also currently a member of the Legal Committee of the Securities Transfer Associate. In addition, he was the co-author of “Regulation of Small Business Issuers — An Executive Handbook,” a book geared to the executives of small public companies and co-authored an article on Equity Crowdfunding published in the Bingham Young University International Law & Management Review.",
  },
  {
    img: "assets/images/Moshe-Joshua.jpg",
    name: "Moshe Joshua",
    desc: "Moshe Joshua is an accomplished software developer and systems architect, with over 20 years of experience programming, managing and supporting trading, banking and corporate operations with a focus on foreign exchange platforms that support decentralized-ledgers, crypto-and-virtual currencies. Moshe was the Head of Technology for MF Global for over 5 years, a publicly traded and international brokerage firm with over 5,000 employees. He has developed high frequency arbitrage systems to trade cross-border securities markets in both Brazil and India. Most recently he also consulted for Ripple and created Ripple Stream, their underlying crypto-streaming market-data and FIX-trading-engine product.",
  },
  {
    img: "assets/images/Adir-Farbman_1.jpg",
    name: "Adir Farbman",
    desc: "Adir Farbman joined eSignatureGuarantee in 2016 as Vice President of Medallion Services. He has built a reputation of constantly looking out for shareholder interests while dealing with the utmost integrity. With a combined focus on marketing, customer service and operations, Adir guides stockholders on ID verification, asset transfer questions and technical matters related to our platform. On a personal note, Adir is a devoted husband, certified EMT, volunteers for his local emergency ambulance service, and is an avid percussionist and enthusiast.",
  },
];
