export const getMedallionsSummary = (transaction: any) => {
  if (!transaction) return;
  return transaction.certificates.map((c: any) => {
    return c.medallions.map((m: any) => {
      return `<tr>
                <td>${c.symbol}</td>
                <td>$ ${c.price}</td>
                <td>${c.shares}</td>
                <td>$ ${c.marketValueTotal}</td>
                <td>${m.status}</td>
                <td>${c.medallions.length}</td>
                <td>$ ${m.costInCents / 100}</td>
            </tr>`;
    });
  });
};

export const isFileUploaded = (status: string) => {
  return !!(status === "SIGNED" || status === "UPLOADED");
};

export const normelizeStatus = (status: string) => {
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
};
