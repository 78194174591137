import React from "react";
import HomeSectionDescription from "../home-section-description/home-section-description.component";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import "./home-about.scss";
import { useDispatch } from "react-redux";
import { isHomeHeader } from "../../redux/actions";
import Modal from "../modal/modal.component";
import { aboutPeopleDescription } from "./home-about.helper";
import AboutTemplate from "../about-template/about-template.component";

const aboutDesc = {
  title: "About",
  desc: "eSignature Guarantee was founded by Seth Farbman, Yoel Goldfeder and Moshe Joshua to provide a better way for shareholders to receive medallion signature guarantee stamps.",
};

const HomeAbout = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setOpen] = React.useState(false);
  const [template, setTemplate] = React.useState({});

  const handleClickOpen = (value: number) => {
    setOpen(true);
    setTemplate(aboutPeopleDescription[value]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="container">
        <div className="about">
          <HomeSectionDescription
            title={aboutDesc.title}
            description={aboutDesc.desc}
          />
          <div
            className="col-md-12 team"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <div className="col-md-3 col-sm-3 pad team-item">
              <img
                alt="seth farbman at esignature guarantee"
                src="assets/images/Seth-Farbman.png"
                className="img-responsive center-block"
                style={{ width: 150, height: 150, objectFit: "cover" }}
              />
              <h3 className="text-center space20">Seth Farbman</h3>
              <p className="text-center">
                Seth likes to build companies that solve problems he faces in
                the marketplace, which is why he started eSignature Guarantee.{" "}
                <span className="bio" onClick={() => handleClickOpen(0)}>
                  Read full bio »
                </span>
              </p>
              <ul className="text-center">
                <li className="fa fa-twitter"></li>
                <a
                  href="https://www.linkedin.com/in/sethfarbman/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon />
                </a>
              </ul>
            </div>
            <div className="col-md-3 col-sm-3 pad team-item">
              <img
                alt="yoel goldfeder at esignature guarantee"
                src="assets/images/Yoel-Goldfeder.png"
                className="img-responsive center-block"
                style={{ width: 150, height: 150, objectFit: "cover" }}
              />
              <h3 className="text-center space20">Yoel Goldfeder</h3>
              <p className="text-center">
                With over 15 years practicing corporate and securities law, Yoel
                is perfect for ensuring that eSignature Guarantee finds the
                right way to serve it's customers.{" "}
                <span className="bio" onClick={() => handleClickOpen(1)}>
                  Read full bio »
                </span>
              </p>
              <ul className="text-center">
                <li className="fa fa-twitter"></li>
                <a
                  href="https://www.linkedin.com/in/yoel-goldfeder-5237007/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon />
                </a>
              </ul>
            </div>
            <div className="col-md-3 col-sm-3 pad team-item">
              <img
                alt="moshe joshua at esignature guarantee"
                src="assets/images/Moshe-Joshua.jpg"
                className="img-responsive center-block"
                style={{ width: 150, height: 150, objectFit: "cover" }}
              />
              <h3 className="text-center space20">Moshe Joshua</h3>
              <p className="text-center">
                Moshe writes elegant code that ensures eSignature Guarantee
                visitors have an exceptional user experience.{" "}
                <span className="bio" onClick={() => handleClickOpen(2)}>
                  Read full bio »
                </span>
              </p>
              <ul className="text-center">
                <li className="fa fa-twitter"></li>
                <a
                  href="https://www.linkedin.com/in/moshe-joshua-5590955/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon />
                </a>
              </ul>
            </div>
            <div className="col-md-3 col-sm-3 pad team-item">
              <img
                alt="adir farbman at esignature guarantee"
                src="assets/images/Adir-Farbman_1.jpg"
                className="img-responsive center-block"
                style={{ width: 150, height: 150, objectFit: "cover" }}
              />
              <h3 className="text-center space20">Adir Farbman</h3>
              <p className="text-center">
                As VP, Adir keeps an eye out for eSignature Guarantee's
                customers...and its shareholders.{" "}
                <span className="bio" onClick={() => handleClickOpen(3)}>
                  Read full bio »
                </span>
              </p>
              <ul className="text-center">
                <li className="fa fa-twitter"></li>
                <a
                  href="https://www.linkedin.com/in/adirfarbman/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon />
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <section className="guarantee">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center txt">About eSignature Guarantee</h2>
            </div>
            <div className="col-md-12">
              <p className="text-center">
                eSignature Guarantee is a unique, patent-pending online platform
                which allows stockholders to obtain a signature guarantee for
                the transfer of securities. It also offers you the ability to
                sign your documents via digital signature and upload them to us.
              </p>
            </div>
            <div className="col-md-12 pad" style={{ display: "flex" }}>
              <div className="col-md-4 col-sm-4"></div>
              <div
                className="col-md-4 col-sm-4"
                style={{ textAlign: "center" }}
              >
                <a rel="canonical" href="/how-it-works">
                  <button className="btn btn-how">How it works »</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal open={open} onClose={handleClose} maxWidth="md">
        <AboutTemplate template={template} />
      </Modal>
    </>
  );
};

export default HomeAbout;
