import { useMutation, useQuery } from "@apollo/client";
import { Snackbar } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { REGISTER } from "../../graphql/mutations/auth/auth.mutations";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import { isHomeHeader } from "../../redux/actions";
import Alert from "../alert/alert.component";
import CarouselComponent from "../carusel/carusel.component";
import Loader from "../loader/loader.component";
import Modal from "../modal/modal.component";
import PartnerWithUs from "../partner-with-us/partner-with-us.component";
import { verifyEmailRegistration } from "../register/verify-register.helper";
import GoogleLogin from "react-google-login";

import { GOOGLE_LOGIN } from "../../graphql/mutations/auth/google-login.mutation";

import "./home.scss";

const Home = () => {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");
  const [errors, setErros] = useState(false);
  const [googleRes, setGoogleRes] = React.useState({
    id_token: "",
    access_token: "",
  });

  const clientId = process.env.REACT_APP_GOOGLE_LOGIN as string;
  const openAlert = (error: any) => {
    setError(error);
    setAlertOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(isHomeHeader(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    repassword: "",
  });
  const [emailPatternError, setEmailPatternError] = useState<boolean>(false);

  const onFormValueChanged = (e: any) => {
    e.preventDefault();

    const { value, name } = e.target;

    setFormValues({ ...formValues, [name]: value });

    if (name === "email") {
      setEmailPatternError(!verifyEmailRegistration(value));
      return;
    }
  };

  const { data: userData, loading: userLoader } = useQuery(GET_USER, {});

  const [join, { loading, error: registerError }] = useMutation(REGISTER, {
    variables: {
      email: formValues.email,
      password: formValues.password,
    },
  });

  React.useEffect(() => {
    if (registerError?.message) {
      openAlert(registerError?.message);
    }
  }, [registerError]);

  const onSubmit = async () => {
    setErros(true);
    if (!verifyEmailRegistration(formValues.email)) {
      setEmailPatternError(true);
      return;
    }
    if (
      !formValues.password ||
      !formValues.repassword ||
      !formValues.repassword ||
      formValues.repassword !== formValues.password
    ) {
      return;
    }
    setEmailPatternError(false);

    try {
      await join();
      navigate("/register/user-details", { replace: true });
    } catch (err) { }
  };

  const [googleLogin, { loading: googleLoginLoader }] = useMutation(
    GOOGLE_LOGIN,
    {
      variables: {
        access_token: googleRes.access_token,
        id_token: googleRes.id_token,
      },
      onCompleted: () => {
        navigate("/accounts/me", { replace: true });
        return;
      },
    }
  );

  const onSuccess = (res: any) => {
    const { tokenId: id_token, accessToken: access_token } = res;
    setGoogleRes({ id_token, access_token });
  };
  const onFailure = (err: any) => {
    console.log(err);
  };

  React.useEffect(() => {
    if (googleRes.access_token === "" || googleRes.id_token === "") return;
    googleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleRes]);

  return (
    <>
      <section className="registraion-form">
        <div className="container">
          <div
            className="row"
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <div className="col-md-6">
              <div className="left-content CerebriSans-Bold">
                <h1>
                  Transferring Securities
                  <br />
                  Just Got Easier
                </h1>
                <div className="line"></div>
                <div className="down">
                  Whether you're trying to cash out or transfer your securities
                  to someone else, we help you get the medallion stamp you need
                  to complete the transaction
                </div>
                <div className="video-image" onClick={() => setOpen(true)}>
                  <img
                    alt="video of how medallion stamp works"
                    src="/assets/images/video-thumb.jpg"
                    className="img-responsive"
                    data-toggle="modal"
                    data-target="#videoModal"
                  />
                </div>
              </div>
            </div>

            {userLoader ? (
              <div
                className="col-md-6"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Loader size={30} />
              </div>
            ) : (
              <>
                {!userData ? (
                  <div className="col-md-6 form">
                    <form
                      className="col-md-12"
                      style={{
                        inlineSize: "auto",
                        width: "100%",
                        maxWidth: 400,
                      }}
                    >
                      <div className="title">
                        <label className="text-center">
                          Get a medallion stamp
                        </label>
                      </div>

                      <div className="col-md-12 pad">
                        <input
                          name="email"
                          type="email"
                          className={`form-control`}
                          placeholder="Email"
                          autoComplete="off"
                          minLength={3}
                          onChange={(e) => onFormValueChanged(e)}
                        />
                        {errors &&
                          formValues?.email.trim() === "" &&
                          !emailPatternError && (
                            <span className="input-error">
                              Field is required!
                            </span>
                          )}
                        {errors && emailPatternError && (
                          <span className="input-error">
                            Field must be email!
                          </span>
                        )}
                      </div>

                      <div className="col-md-12 pad">
                        <input
                          name={"password"}
                          type="password"
                          className={`form-control`}
                          placeholder="Password"
                          autoComplete="off"
                          minLength={3}
                          onChange={(e) => onFormValueChanged(e)}
                        />
                        {errors && !formValues?.password && (
                          <span className="input-error">
                            Field is required!
                          </span>
                        )}
                      </div>
                      <div className="col-md-12 pad">
                        <input
                          name={"repassword"}
                          type="password"
                          className={`form-control`}
                          placeholder="Repeat Password"
                          autoComplete="off"
                          minLength={3}
                          onChange={(e) => onFormValueChanged(e)}
                        />
                        {errors && !formValues?.repassword && (
                          <span className="input-error">
                            Field is required!
                          </span>
                        )}
                        {errors &&
                          formValues?.repassword !== formValues?.password &&
                          formValues?.repassword && (
                            <span className="input-error">
                              Passwords are not matching!
                            </span>
                          )}
                      </div>

                      <div className="col-md-12 pad">
                        {loading ? (
                          <Loader />
                        ) : (
                          <button
                            name="create-account"
                            type="button"
                            className="btn-register g-recaptcha"
                            onClick={onSubmit}
                          >
                            Create account
                          </button>
                        )}
                      </div>

                      <div className="form-group">
                        <div className="col-md-12 pad or-line">
                          <h2>
                            <span>OR</span>
                          </h2>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-md-12 pad" style={{
                          display: "flex",
                          justifyContent: "center"
                        }}>
                          {googleLoginLoader ? (
                            <Loader />
                          ) :
                            <GoogleLogin
                              clientId={clientId}
                              buttonText="Sign up with Google"
                              onSuccess={onSuccess}
                              onFailure={onFailure}
                              cookiePolicy={"single_host_origin"}
                            />}
                        </div>
                      </div>
                      <div className="form-group" style={{ display: "flex" }}>
                        <div className="col-md-4 pad text-center">
                          <img
                            width="50"
                            src="/assets/images/money-back-guarantee.png"
                            alt="money-back-guarantee.png"
                          />
                        </div>
                        <div className="col-md-4 pad text-center">
                          <img
                            width="60"
                            src="/assets/images/premium-quality-guarantee.png"
                            alt="premium-quality-guarantee.png"
                          />
                        </div>
                        <div className="col-md-4 pad text-center">
                          <img
                            width="50"
                            src="/assets/images/secure-checkout-guarantee.png"
                            alt="secure-checkout-guarantee.png"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </section>

      <section className="steps">
        <div className="container">
          <div className="row">
            <div
              className="col-md-12 pad"
              style={{ paddingTop: 50, paddingBottom: 20 }}
            >
              <h2 className="text-center txt">
                3 steps to your medallion stamp
              </h2>
            </div>
            <div
              className="col-md-12"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div
                className="col-md-4 col-sm-4 pad-sml"
                style={{
                  display: "flex",
                  textAlign: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  alt="fingerprint logo for esignature"
                  src="/assets/images/how-it-works-icon-1.png"
                  className="img-responsive center-block"
                  width="100"
                />
                <h3 className="text-center space20 ">Confirm your identity</h3>
                <p className="text-center">
                  After you create your account.{" "}
                  <a href="/about">Our expert staff will</a> walk you through
                  the identification process on our secure platform, and verify
                  your identity.
                </p>
              </div>
              <div
                className="col-md-4 col-sm-4 pad-sml middle"
                style={{
                  display: "flex",
                  textAlign: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  alt="medallion certification icon at esignature guarantee"
                  src="/assets/images/how-it-works-icon-2.png"
                  className="img-responsive center-block"
                  width="100"
                />
                <h3 className="text-center space20">
                  Provide securities details
                </h3>
                <p className="text-center">
                  Enter the information for all your securities that require{" "}
                  <a href="/faq" rel="canonical">
                    medallion stamps
                  </a>
                  . You can choose to upload or mail supporting documentation.
                </p>
              </div>
              <div
                className="col-md-4 col-sm-4"
                style={{
                  display: "flex",
                  textAlign: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  alt="paper icon for digital signatures at esignature guarantee"
                  src="/assets/images/how-it-works-icon-3.png"
                  className="img-responsive center-block"
                  width="100"
                />
                <h3 className="text-center space20">
                  Receive signature guarantee
                </h3>
                <p className="text-center">
                  Our team of experts will review your file. Once it’s{" "}
                  <a href="/pricing" rel="canonical">
                    been approved
                  </a>
                  , we will courier your document with a Medallion Signature
                  Guarantee stamp on it.
                </p>
              </div>
            </div>
            <div
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div className="col-md-4 col-sm-4 text-center">
                <a rel="canonical" href="/how-it-works">
                  <button className="btn btn-how">How it works »</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <Outlet />
        </div>
      </section>

      <section className="companies">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pad">
              <h2 className="text-center">
                Our medallion stamps have facilitated share transfer of the
                world's leading companies
              </h2>
            </div>
            <div className="col-md-12 pad2">
              <div className="seven-cols">
                <div className="col-md-1" style={{ maxWidth: 150 }}>
                  <img
                    alt="medallion signature guarantee for ATT"
                    src="/assets/images/logo-1.png"
                    className="img-responsive center-block"
                  />
                </div>
                <div className="col-md-1" style={{ maxWidth: 150 }}>
                  <img
                    alt="medallion signature guarantee for apple"
                    src="/assets/images/logo-2.png"
                    className="img-responsive center-block"
                  />
                </div>
                <div className="col-md-1" style={{ maxWidth: 150 }}>
                  <img
                    alt="medallion signature guarantee for boeing"
                    src="/assets/images/logo-3.png"
                    className="img-responsive center-block"
                  />
                </div>
                <div className="col-md-1" style={{ maxWidth: 150 }}>
                  <img
                    alt="medallion signature guarantee for IBM"
                    src="/assets/images/logo-4.png"
                    className="img-responsive center-block"
                  />
                </div>
                <div className="col-md-1" style={{ maxWidth: 150 }}>
                  <img
                    alt="medallion signature guarantee for Exxon"
                    src="/assets/images/logo-5.png"
                    className="img-responsive center-block"
                  />
                </div>
                <div className="col-md-1" style={{ maxWidth: 150 }}>
                  <img
                    alt="medallion signature guarantee for Starbucks"
                    src="/assets/images/logo-6.png"
                    className="img-responsive center-block"
                  />
                </div>
                <div className="col-md-1" style={{ maxWidth: 150 }}>
                  <img
                    alt="medallion signature guarantee for du pont"
                    src="/assets/images/logo-7.png"
                    className="img-responsive center-block"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="customers">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center txt">What our customers are saying</h2>
            </div>
            <div className="col-md-12 quote">
              <CarouselComponent />
            </div>
          </div>
        </div>
      </section>

      <PartnerWithUs />

      <Modal open={open} onClose={handleClose} maxWidth="xl">
        <div className="video-responsive">
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/FeXyGzaFIi4`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      </Modal>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Home;
