import { SecurityTypeEnum } from "../../types/interfaces/create-transaction.interfaces";
import { MedallionType, OwnershipType, OwnershipTypes } from "../../types/main";
import { Action } from "../actions";

export interface CheckoutState {
  ownershipType: OwnershipType;
  medallionType: MedallionType;
  readyForSecondStepCheckout: boolean;
  readyForThirdStepCheckout: boolean;
  medallions: any;
}

const initialState = {
  ownershipType: OwnershipTypes.PRIVATE,
  medallionType: SecurityTypeEnum.PUBLIC,
  readyForSecondStepCheckout: false,
  readyForThirdStepCheckout: false,
  medallions: [],
};

export const CheckoutReducer = (
  state: CheckoutState = initialState,
  action: Action
) => {
  switch (action.type) {
    case "SET_OWNER_SHIP_TYPE": {
      return { ...state, ownershipType: action.payload };
    }
    case "SET_MEDALLION_TYPE": {
      return { ...state, medallionType: action.payload };
    }
    case "READY_FOR_SECOND_STEP_CHECKOT": {
      return { ...state, readyForSecondStepCheckout: action.payload };
    }
    case "READY_FOR_THIRD_STEP_CHECKOT": {
      return { ...state, readyForThirdStepCheckout: action.payload };
    }
    case "SET_MEDALLIONS": {
      return { ...state, medallions: action.payload };
    }
    default:
      return state;
  }
};
