import { Action } from "../actions";

export interface RegisterState {
  current: number;
  codesExpiration: any;
}

const initialState = {
  current: 1,
  codesExpiration: ''
};

export const registerReducer = (
  state: RegisterState = initialState,
  action: Action
) => {
  switch (action.type) {
    case "NEXT": {
      return { ...state, current: action.payload };
    }
    case "CODES_EXPIRATION": {
      return { ...state, codesExpiration: action.payload }
    }
    default:
      return state;
  }
};
