import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";

export const options: { [key: string]: string } = {
  "USER INFO": "/register/user-details",
  "PERSONAL DETAILS": "/register/personal-details",
  CONFIRMATION: "/register/confirmation",
  "VERIFY IDENTITY": "/register/verify-identity",
};

const RegistrationSteps = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    switch (location.pathname) {
      case options["USER INFO"]:
        setValue(0);
        break;
      case options["PERSONAL DETAILS"]:
        setValue(1);
        break;
      case options["CONFIRMATION"]:
        setValue(2);
        break;
      case options["VERIFY IDENTITY"]:
        setValue(3);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // const readyForSecondStepCheckout = useSelector<
  //   RootState,
  //   RootState["checkout"]["readyForSecondStepCheckout"]
  // >((state) => {
  //   return state.checkout.readyForSecondStepCheckout;
  // });

  //   const readyForThirdStepCheckout = useSelector<
  //     RootState,
  //     RootState["checkout"]["readyForThirdStepCheckout"]
  //   >((state) => {
  //     return state.checkout.readyForThirdStepCheckout;
  //   });

  const handleClick = (value: string) => {
    navigate(options[value], { replace: true });
  };

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    handleClick(event.target.innerText);
  };

  const style = {
    width: 256,
    fontSize: 12,
    fontFamily: "CerebriSans-SemiBold",
    color: "#28dab6",
    outline: "none",
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs onChange={handleChange} value={value}>
        <Tab style={style} label="User Info" />
        <Tab style={style} label="Personal Details" disabled={false} />
        <Tab style={style} label="Confirmation" disabled={false} />
        <Tab style={style} label="Verify Identity" disabled={false} />
      </Tabs>
    </Box>
  );
};

export default RegistrationSteps;
