/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const IDOLOGY_ANSWER_QUESTIONS = gql`
  mutation verifyIdentity($answers: [IdologyAnswer!]!) {
    verifyIdentity(
      action: SEND_IDENTITY_ANSWERS
      input: { type: IQ, answers: $answers }
    ) {
      firstName
      lastName
      email
      isEmailVerified
      status
      type
      paymentMethod
      idologyResponse {
        answerType
        questions {
          prompt
          type
          answers
        }
        status
      }
    }
  }
`;
