import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { setStoreMedallions } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { LIST_TRANSFER_ANGENTS } from "../../graphql/queries/transaction/agetns-list.query";
import { RootState } from "../../redux/reducers";
import { EDIT_TRANSACTION } from "../../graphql/mutations/transaction/edit-transaction.mutation";
import { GET_TRANSACTION } from "../../graphql/queries/transaction/get-transaction.query";
import {
  certificateRegularValidation,
  extractMedallionsLength,
  getMedallionsLength,
  TransferAgent,
} from "./certificate-regular.helpers";
import PageTwoSideBox from "../checkout-side-box/page-two-sidebox.component";
import Loader from "../loader/loader.component";
import { Snackbar } from "@mui/material";
import Alert from "../alert/alert.component";

import "./certificate-regular.scss";
import AddNewCertificate from "../add-new-certificate/add-new-certificate.component";

const errorsStyle = { color: "#d43f00", fontSize: 12 };

export const btnStyle = {
  background: "#28dab6",
  color: "#fff",
  borderRadius: 2,
  transition: ".3s ease-in-out",
  padding: "0 25px",
  marginTop: 15,
  width: "100%",
  fontFamily: "CerebriSans-SemiBold",
  fontSize: 17,
  height: 45,
  cursor: "pointer",
  border: "none",
};

const CertificateRegular = () => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");

  const openAlert = (error: any) => {
    setError(error);
    setOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [saveAndContinueLoader, setSaveAndContinueLoader] =
    useState<boolean>(false);
  const [continueLoader, setContinueLoader] = useState<boolean>(false);

  const transactionUUID = useSelector<
    RootState,
    RootState["transaction"]["transactionUUID"]
  >((state) => {
    return state.transaction.transactionUUID;
  });

  // TODO - extract to shared util
  const guardPage = () => {
    if (!transactionUUID) {
      navigate("/home", { replace: true });
      return false;
    }
    return true;
  };

  // make sure to redirect the user in case no active transaction
  React.useEffect(() => {
    guardPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionUUID, navigate]);

  const { data: transactionData } = useQuery(GET_TRANSACTION, {
    variables: {
      transactionUUID: transactionUUID,
    },
  });

  const { loading, data } = useQuery(LIST_TRANSFER_ANGENTS);

  const [agentUUID, setAgentUUID] = useState<string>("");

  React.useEffect(() => {
    setAgentUUID(transactionData?.getTransaction?.transferAgent?.uuid);
  }, [transactionData, data]);

  React.useEffect(() => {
    if (!agentUUID || agentUUID === "") return;
    if (agentUUID !== "- Select Agent -") {
      editTransactionFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentUUID]);

  const [editTransactionFunc, { loading: editLoader, error: editLoaderErr }] =
    useMutation(EDIT_TRANSACTION, {
      variables: {
        transactionUUID: transactionUUID,
        transferAgentUUID: agentUUID,
      },
      onCompleted: () => {
        setContinueLoader(false);
        setSaveAndContinueLoader(false);
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_TRANSACTION,
          variables: { transactionUUID: transactionUUID },
        },
      ],
    });

  React.useEffect(() => {
    if (editLoaderErr?.message) {
      openAlert(editLoaderErr?.message);
    }
  }, [editLoaderErr]);

  const onContinue = () => {
    if (
      certificateRegularValidation(
        extractMedallionsLength(transactionData?.getTransaction),
        agentUUID
      ) === false
    )
      return;

    dispatch(
      setStoreMedallions(extractMedallionsLength(transactionData?.getTransaction))
    );
  };

  const redirectTo = (isNextPage: string) => {
    if (isNextPage === "yes") {
      onContinue();
      navigate("/transactions/create/checkout");
    } else if (isNextPage === "no") {
      navigate("/accounts/me");
    }
  };

  const onSelectAgent = (e: any) => {
    setAgentUUID(e.target.value);
  };

  let disableButton =
    certificateRegularValidation(
      transactionData?.getTransaction,
      agentUUID
    ) === false;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        paddingBottom: 50,
      }}
    >
      <div style={{ maxWidth: 600 }}>
        <div>
          <h1 className="sub-title">Medallion details</h1>
          <p>
            List all stocks that require a medallion on their Stock
            Power/Transfer Form in the table below. Please note that any stock
            worth more than $500,000 requires a medallion stamp for every
            $500,000 in value.
          </p>
        </div>
        <form name="medallionForm">
          <div className="pull-left">
            <label>Transfer Agent</label>
            {loading ? (
              <div style={{ width: 20, height: 20 }}>
                <Loader size={20} />
              </div>
            ) : (
              <select
                className={`form-control`}
                style={{ maxWidth: 350 }}
                onChange={onSelectAgent}
              // defaultValue={agentUUID}
              >
                <option value={agentUUID}>
                  {data?.listTransferAgents.filter(
                    (agent: TransferAgent, i: number) =>
                      agent.uuid === agentUUID
                  )[0]?.name || "- Select Agent -"}
                </option>
                {data?.listTransferAgents.slice()
                  .sort((a: TransferAgent, b: TransferAgent) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((agent: TransferAgent, i: number) => {
                    return (
                      <option key={i} value={agent.uuid}>
                        {agent.name}
                      </option>
                    );
                  })
                  .filter((a: any) => {
                    return a.props.value !== agentUUID;
                  })}
              </select>
            )}
            {!agentUUID && (
              <span className="input-error">
                <div className="fa-icon fa fa-exclamation-circle"></div>
                <p style={errorsStyle}>This field is required!</p>
              </span>
            )}
          </div>
          <AddNewCertificate openAlert={openAlert} />
          <div
            style={{
              paddingTop: 50,
              display: "flex",
              alignItems: "space-between",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <button
              className="btn btn-save"
              type="button"
              style={{ ...btnStyle, maxWidth: 280 }}
              onClick={() => {
                redirectTo("no");
              }}
            >
              {editLoader && saveAndContinueLoader ? (
                <Loader size={30} />
              ) : (
                "Save and go to overview"
              )}
            </button>
            <button
              style={{
                ...btnStyle,
                maxWidth: 280,
                background: disableButton ? "lightgray" : "#28dab6",
              }}
              type="button"
              onClick={() => redirectTo("yes")}
              disabled={
                getMedallionsLength(transactionData?.getTransaction) === 0 ||
                agentUUID === ""
              }
            >
              {editLoader && continueLoader ? <Loader size={30} /> : "Continue"}
            </button>
          </div>
        </form>
      </div>
      <PageTwoSideBox />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CertificateRegular;
