/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const ADD_DISCOUNT_CODE = gql`
  mutation addDiscountCode($discountCode: String!, $transactionUUID: String!) {
    addDiscountCode(
      transactionUUID: $transactionUUID
      discountCode: $discountCode
    ) {
      uuid
      code
      stockholderType
      format
      securityType
      createdAt
      status
      certificates {
        uuid
        symbol
        medallionsCount
      }
      notes {
        uuid
        content
        createdAt
      }
      transferAgent {
        uuid
      }
      shippings {
        uuid
        type
      }
      discounts {
        code
        valueType
        value
      }
    }
  }
`;
