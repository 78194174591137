import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import HomeHeader from "../home-header/home-header.component";
import Navbar from "../navbar/navbar";

const Header = () => {
  const isHomeHeader = useSelector<
    RootState,
    RootState["other"]["isHomeHeader"]
  >((state) => {
    return state.other.isHomeHeader;
  });

  const header = isHomeHeader ? <HomeHeader /> : <Navbar />;

  return <>{header}</>;
};

export default Header;
