import React from "react";

const StampGuarantee = () => {
  return (
    <div className="col-md-12 pad content">
      <h1 className="text-center txt">
        Medallion signature guarantee vs. Medallion guarantee stamp
      </h1>
      <div className="line"></div>

      <h4 style={{ paddingTop: 50 }}>
        What’s the Difference? Trick Question – they are the same thing!
      </h4>
      <p>
        A medallion signature guarantee is referred to by many different names
        but its purpose is the same – you need it to transfer securities. A
        medallion signature guarantee, commonly abbreviated as MSG, is a form of
        authentication provided by financial institutions to prevent fraud.
      </p>
      <p>
        The placement of a medallion signature guarantee allows the transfer or
        sale of securities, change of ownership name on an account, or to
        replace lost or damaged certificates.
      </p>
      <p>
        The financial institution issuing a medallion signature guarantee must
        be authorized to do so through acceptance of an official securities
        guarantee program. These programs are known as medallion programs:
      </p>
      <ul>
        <li>
          The&nbsp;Securities Transfer Agents Medallion Program
          (STAMP)&nbsp;whose participants include financial institutions based
          in the U.S. and Canada.
        </li>
        <li>
          The&nbsp;Stock Exchange Medallion Program (SEMP)&nbsp;which supports
          firms belonging to regional stock exchanges and trust and clearing
          companies.
        </li>
        <li>
          The&nbsp;New York Stock Exchange Medallion Signature Program
          (MSP)&nbsp;for NYSE firms that choose to become members.
        </li>
      </ul>
      <p>When we provide the guarantee, it certifies that:</p>
      <ol>
        <li>You are who you claim to be,</li>
        <li>You have the rights to transfer the assets</li>
      </ol>
      <p style={{ paddingBottom: 50 }}>
        Once the issuing financial institution confirms these factors, the
        medallion stamp would then allow the stock transfer agent to review the
        paper work for various types of transactions such as the sale of
        physical securities certificates, transfer of stocks to a beneficiary
        upon death of the owner, change in ownership status or name, gifting of
        stocks, etc.
      </p>
    </div>
  );
};

export default StampGuarantee;
