/* eslintdisable nousebeforedefine */
import { gql } from "@apollo/client";

export const EDIT_EMAIL_MARKETING = gql`
mutation setReceiveMarketingEmails($allowReceiveMarketingEmails: Boolean!) {
        setReceiveMarketingEmails(allowReceiveMarketingEmails: $allowReceiveMarketingEmails){
            uuid
            email
            allowReceiveMarketingEmails
        }
	}`;