/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

export const CREATE_TRANSACTION = gql`
  mutation createTransaction(
    $securityType: SecurityType!
    $format: FormatType!
    $stockholderType: StockholderType!
  ) {
    createTransaction(
      securityType: $securityType
      format: $format
      stockholderType: $stockholderType
    ) {
      uuid
      code
      status
      securityType
      format
      stockholderType
      createdAt
    }
  }
`;
