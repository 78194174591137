import React from "react";

import "./step-1.scss";

const StepOne = () => {
  return (
    <section className="how-steps-step1 us-citizen-info">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-12 pad">
            <div className="title">
              <span className="sub">Step 1</span>
              <h2>Confirm your identity</h2>
              <p>
                eSignature Guarantee is the only medallion stamp provider that
                allows you to verify your identity online. To do so, we combine
                state-of-the-art technology with an advanced intelligence
                identification verification system, so you can prove who you are
                while being protected from fraud.
              </p>
            </div>
          </div>
        </div>
        <div className="row step1">
          <div className="col-md-12 pad" style={{ display: "flex" }}>
            <div className="col-md-6">
              <p className="left-p1">
                Once your account has been created, you’ll begin our advanced
                intelligence user verification process. You’ll be asked for your
                name, address, mobile phone number, social security number and
                birthdate. This information is crucial, and without it we will
                be unable to verify your identity and continue with the process.
              </p>
            </div>
            <div className="right-img col-md-6">
              <img
                alt="medallion signature guarantee personal details"
                src="assets/images/step1_1.png"
              />
            </div>
          </div>

          <div className="col-md-12 pad" style={{ display: "flex" }}>
            <div className="right-img col-md-6">
              <img
                alt="medallion signature guarantee confirmation"
                src="assets/images/step1_2.png"
              />
            </div>
            <div className="col-md-6">
              <p className="right-p">
                Next, you’ll receive confirmation codes to both your email
                address and your mobile phone. Each of these codes are
                different, and need to be entered into the correct field on the
                screen.
              </p>
            </div>
          </div>

          <div className="col-md-12 pad" style={{ display: "flex" }}>
            <div className="col-md-6">
              <p className="left-p2">
                After you’ve confirmed both your mobile number and email
                address, our advanced intelligence engine will ask you a series
                of multiple choice questions that only you could know. After
                answering these questions correctly, you’ve passed through our
                dual-verification process, and your identity is confirmed.
                <br />
                <br />
                Due to the amount of information required on screen, the
                securities details section does not support mobile phones.
                Please switch to a laptop/desktop or large-screen tablet to
                continue.
              </p>
            </div>
            <div className="right-img col-md-6">
              <img
                alt="medallion signature guarantee verification"
                src="assets/images/step1_3.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepOne;
