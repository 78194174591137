import { gql } from "@apollo/client";

//deliveryMethod: FEDEX_2_DAY / STANDARD_OVERNIGHT / OWN / ELECTRONIC
export const EDIT_DELIVERY_METHOD = gql`
  mutation ($transactionUUID: String!, $deliveryMethod: DeliveryMethod) {
    editTransaction(
      transactionUUID: $transactionUUID
      deliveryMethod: $deliveryMethod
    ) {
      uuid
      deliveryMethod
    }
  }
`;
