import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import GoogleLogin from "react-google-login";

import { REGISTER } from "../../graphql/mutations/auth/auth.mutations";

import "./register.scss";
import Loader from "../loader/loader.component";
import { verifyEmailRegistration } from "./verify-register.helper";
import { useDispatch } from "react-redux";
import { isHomeHeader, setCurrentRegisterState } from "../../redux/actions";
import { Snackbar } from "@mui/material";
import Alert from "../alert/alert.component";
import { GOOGLE_LOGIN } from "../../graphql/mutations/auth/google-login.mutation";

const Register = () => {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>("");
  const [errors, setErrors] = useState(false);
  const [googleRes, setGoogleRes] = React.useState({
    id_token: "",
    access_token: "",
  });

  const clientId = process.env.REACT_APP_GOOGLE_LOGIN as string;

  const openAlert = (error: any) => {
    setError(error);
    setAlertOpen(true);
  };

  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setCurrentRegisterState(1));
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    repassword: "",
  });
  const [emailPatternError, setEmailPatternError] = useState<boolean>(false);

  const onFormValueChanged = (e: any) => {
    e.preventDefault();

    const { value, name } = e.target;

    setFormValues({ ...formValues, [name]: value });

    if (name === "email") {
      setEmailPatternError(!verifyEmailRegistration(value));
      return;
    }
  };
  const [join, { loading, error: joinError }] = useMutation(REGISTER, {
    variables: {
      email: formValues.email,
      password: formValues.password,
    },
  });

  React.useEffect(() => {
    if (joinError?.message) {
      openAlert(joinError?.message);
    }
  }, [joinError]);

  const onSubmit = async () => {
    setErrors(true);
    if (!verifyEmailRegistration(formValues.email)) {
      setEmailPatternError(true);
      return;
    }
    if (
      !formValues.password ||
      !formValues.repassword ||
      !formValues.repassword ||
      formValues.repassword !== formValues.password
    ) {
      return;
    }
    setEmailPatternError(false);

    try {
      await join();
      navigate("/register/user-details", { replace: true });
    } catch (err) { }
  };

  const [googleLogin, { loading: googleLoginLoader }] = useMutation(
    GOOGLE_LOGIN,
    {
      variables: {
        access_token: googleRes.access_token,
        id_token: googleRes.id_token,
      },
      onCompleted: () => {
        navigate("/accounts/me", { replace: true });
        return;
      },
    }
  );

  const onSuccess = (res: any) => {
    const { tokenId: id_token, accessToken: access_token } = res;
    setGoogleRes({ id_token, access_token });
  };
  const onFailure = (err: any) => {
    console.log(err);
  };

  React.useEffect(() => {
    if (googleRes.access_token === "" || googleRes.id_token === "") return;
    googleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleRes]);

  return (
    <section className="join">
      <div className="container">
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col form">
            <div className="row">
              <form className="col" name="joinForm">
                <div className="form-group title">
                  <span className="fa fa-lock"></span>
                  <label className="text-center call-txt">
                    Create an account
                  </label>
                </div>
                <div className="form-group">
                  <div className="col-md-12 pad">
                    <input
                      name="email"
                      type="email"
                      className={`form-control`}
                      placeholder="Email"
                      autoComplete="off"
                      minLength={3}
                      onChange={(e) => onFormValueChanged(e)}
                    />
                    {errors &&
                      formValues?.email.trim() === "" &&
                      !emailPatternError && (
                        <span className="input-error">Field is required!</span>
                      )}
                    {errors && emailPatternError && (
                      <span className="input-error">Field must be email!</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-12 pad">
                    <input
                      name={"password"}
                      type="password"
                      className={`form-control`}
                      placeholder="Password"
                      autoComplete="off"
                      minLength={3}
                      onChange={(e) => onFormValueChanged(e)}
                    />
                    {errors && !formValues?.password && (
                      <span className="input-error">Field is required!</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-12 pad">
                    <input
                      name={"repassword"}
                      type="password"
                      className={`form-control`}
                      placeholder="Repeat Password"
                      autoComplete="off"
                      minLength={3}
                      onChange={(e) => onFormValueChanged(e)}
                    />
                    {errors && !formValues?.repassword && (
                      <span className="input-error">Field is required!</span>
                    )}
                    {errors &&
                      formValues?.repassword !== formValues?.password &&
                      formValues?.repassword && (
                        <span className="input-error">
                          Passwords are not matching!
                        </span>
                      )}
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-12 pad">
                    {loading ? (
                      <Loader />
                    ) : (
                      <button
                        name="create-account"
                        type="button"
                        className="btn-register g-recaptcha"
                        onClick={onSubmit}
                      >
                        Create account
                      </button>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-12 pad or-line">
                    <h2>
                      <span>OR</span>
                    </h2>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-12 pad" style={{
                    display: "flex",
                    justifyContent: "center"
                  }}>
                    {googleLoginLoader ? (
                      <Loader />
                    ) :
                      <GoogleLogin
                        clientId={clientId}
                        buttonText="Sign up with Google"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={"single_host_origin"}
                      />}
                  </div>
                </div>
                <div className="form-group" style={{ display: "flex" }}>
                  <div className="col-md-4 pad text-center">
                    <img
                      width="50"
                      src="/assets/images/money-back-guarantee.png"
                      alt="money-back-guarantee.png"
                    />
                  </div>
                  <div className="col-md-4 pad text-center">
                    <img
                      width="60"
                      src="/assets/images/premium-quality-guarantee.png"
                      alt="premium-quality-guarantee.png"
                    />
                  </div>
                  <div className="col-md-4 pad text-center">
                    <img
                      width="50"
                      src="/assets/images/secure-checkout-guarantee.png"
                      alt="secure-checkout-guarantee.png"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="row">
              <div className="col-md-12 text-center already">
                Already have an account?
                <br />
                <a href="login">
                  <span>Log in</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </section>
  );
};

export default Register;
