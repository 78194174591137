import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isHomeHeader } from "../../redux/actions";
import { RootState } from "../../redux/reducers";
import { SecurityTypeEnum } from "../../types/interfaces/create-transaction.interfaces";
import CertificateIra from "../certificate-ira/certificate-ira";
import CertificateRegular from "../certificate-regular/certificate-regular";

const CertificateType = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isHomeHeader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transaction = useSelector<RootState, RootState["transaction"]>(
    (state) => {
      return state.transaction;
    }
  );

  const elementToDisplay =
    transaction.securityType === SecurityTypeEnum.IRA_401_FUNDS_BONDS ? (
      <CertificateIra />
    ) : (
      <CertificateRegular />
    );

  return <>{elementToDisplay}</>;
};

export default CertificateType;
