import { useQuery } from "@apollo/client";
import React from "react";
import { GET_USER } from "../../graphql/queries/user/get-user.query";
import Loader from "../loader/loader.component";

import "./home-header.scss";

const anchorStyle = {
  color: "#b8bcc4",
  fontFamily: "CerebriSans-Light",
  fontSize: 18,
  textDecoration: "none",
  padding: "8px 15px",
};

const HomeHeader = () => {
  const { data: userData, loading } = useQuery(GET_USER);

  return (
    <header>
      <div
        className="container-fluid"
        style={{ padding: 0, background: "#334462" }}
      >
        <div className="container">
          <div className="site-contact">
            <div className="contact-text">
              <span className="number" style={{ display: "none" }}>1-844-782-6763 </span>
              {/* <span className="separator">|</span> */}
              <a rel="canonical" href="/contact">
                CONTACT US
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        <div>
          <a href="/home" style={{ flex: 1, height: 75, width: 150 }}>
            <img
              style={{ width: "100%" }}
              alt="esignature guarantee main logo"
              src="/assets/images/esignature-logo.svg"
            />
          </a>
        </div>
        <div className="header-links-box">
          <a
            href="https://blog.esignatureguarantee.com/"
            target="_blank"
            rel="noreferrer"
            style={anchorStyle}
          >
            Blog
          </a>
          <a href="/about" style={anchorStyle}>
            About
          </a>
          <a rel="canonical" href="/how-it-works" style={anchorStyle}>
            How it works
          </a>
          <a rel="canonical" href="/pricing" style={anchorStyle}>
            Pricing
          </a>
          {loading ? (
            <Loader size={30} />
          ) : (
            <>
              {userData?.getUser?.roles[0] ? (
                <>
                  <a
                    href="/join"
                    style={anchorStyle}
                    onClick={() => {
                      localStorage.removeItem("token");
                    }}
                  >
                    Logout
                  </a>
                  <a href="/accounts/me">
                    <button className="btn-register hidden-xs">
                      My account
                    </button>
                  </a>
                </>
              ) : (
                <>
                  <a className="navbar-login" href="/login" style={anchorStyle}>
                    Login
                  </a>
                  <a href="/join" style={{ padding: "8px 15px" }}>
                    <button className="btn-register hidden-xs">
                      Create account
                    </button>
                  </a>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;
