import { validatePhoneNumber } from "../../../helpers/validate-us-number";

export const isFormInputValid = (value: string) => {
    return !!value
}

export const generalErrorMessage = (field: string) => field + ' is required!';

export const isFormValid = (userObj: any, isChecked: boolean) => {
    if (!isChecked) return false;
    if (!userObj) return false;

    return isFormInputValid(userObj?.firstName) &&
        isFormInputValid(userObj?.lastName) &&
        isFormInputValid(userObj?.phone) &&
        isFormInputValid(userObj?.homeAddress) &&
        isFormInputValid(userObj?.city) &&
        isFormInputValid(userObj?.state) &&
        isFormInputValid(userObj?.zip) &&
        isFormInputValid(userObj?.country) &&
        validatePhoneNumber(userObj?.phone);
}