import { IUser } from "../../types/interfaces/user.interface";
import { Action } from "../actions";

export interface UserState {
  isUser: IUser | null;
}

// should be null
const initialState = {
  isUser: {
    info: {
      status: "",
      isAdmin: "",
      tsUserId: null,
      firstName: "Moshe",
      lastName: "Test",
      cellPhone: "7187956874",
      workPhone: "",
      email: "test@test.com",
      address: "President 1414",
      city: "NYC",
      address1: "",
      usCitizen: true,
      state: "NY",
      zip: "11213",
      country: "USA",
    },
    state: "string",
    transactions: [],
  },
};

export const UserReducer = (
  state: UserState = initialState,
  action: Action
) => {
  switch (action.type) {
    case "LOGGED_IN": {
      return { ...state, isUser: action.payload };
    }
    case "USER_LOGGED_OUT": {
      return { ...state, isUser: null };
    }
    default:
      return state;
  }
};
