import React from "react";
import "./partner-with-us.scss";

const PartnerWithUs = () => {
  return (
    <div className="partner-with-us">
      <div className="container">
        <div className="partner-with-us__description">
          <div className="row">
            <div className="col-md-12 text">
              <h3>Partner with us</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text">
              <p>
                Do you have a need for a high volume of medallion guarantee
                stamps?
              </p>
              <p>
                Email us for more info:{" "}
                <a href="mailto:info@esignatureguarantee.com">
                  info@esignatureguarantee.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerWithUs;
